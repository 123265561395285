import React, { Component } from 'react';
class Navbar extends Component {

	render(){
		return (
			<div></div>
		);
	}
};
export default Navbar;
