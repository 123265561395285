import React, {Component} from 'react';

class Agenda extends Component{

	componentDidMount(){
		window.action_agenda();
	}
	
	render(){
		return(
			<div>
				<div style={{
					maxWidth: 1000, 
					margin: '0 auto', 
					marginTop: 150,
					marginBottom: 50
				}} id='calendar'></div>
			    <div 
				    className="modal fade" 
				    id="exampleModal" 
				    tabindex="-1" 
				    role="dialog" 
				    aria-labelledby="exampleModalLabel" 
				    aria-hidden="true"
			    >
			      <div className="modal-dialog" role="document">
			        <div className="modal-content">
			          <div className="modal-header">
			            <h5 className="modal-title" id="titulo"></h5>
			            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
			              <span aria-hidden="true">&times;</span>
			            </button>
			          </div>
			          <div className="modal-body">
			            <h6>Lugar: <span id="lugar"></span></h6>
			              <iframe 
				              id="url" 
				              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fomarfayadmeneses%2Fposts%2F2219656894913293&show_text=true&height=702&appId=838104553219093" 
				              width="100%" 
				              height="740" 
				              style={{border:'none', overflow:'hidden'}} 
				              frameborder="0" 
				              scrolling="auto" 
				              allowTransparency="true" 
				              allow="encrypted-media"
				            ></iframe>
			          </div>
			        </div>
			      </div>
			    </div>
		    </div>
		);
	}
}

export default Agenda;