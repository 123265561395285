export  const InformeIndex ={
	informe:{
		resume:{
			name: "Informes de gobierno",
			description: "Es el documento con el que el gobernador presenta el balance del estado general que guarda la Administración Pública acorde a lo que art 71 fraccion XXV de la constitucion política para el estado de hidalgo. ",
			url: "/Informes"
		},
		actual:{
			gobernador:"Omar Fayad Meneses",
			imgGob:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/1er_informe_f.png",
			periodo:"2016-2022",
			planEstatal:"https://planestataldedesarrollo.hidalgo.gob.mx/pdf/PED/PED/Ped_Completo.pdf",
			informes:[
				{	
					numInforme:"1° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/1er_informe_f.png",
					url:"https://informe.hidalgo.gob.mx/",
					download:"https://cdn.hidalgo.gob.mx/gobierno/pdf/1er-informe-completo.pdf"

				},
				{
					numInforme:"2° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/2do_informe_f.png",
					url:"https://informe.hidalgo.gob.mx/",
					download:"https://cdn.hidalgo.gob.mx/gobierno/pdf/2do-informe-completo.pdf"
				},
				{
					numInforme:"3° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/3er_informe_f.jpg",
					url:"https://informe.hidalgo.gob.mx/",
					download:"https://cdn.hidalgo.gob.mx/gobierno/pdf/3er-informe-completo.pdf"
				}
				,
				{
					numInforme:"4° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/4to_informe_f.png",
					url:"https://informe.hidalgo.gob.mx/",
					download:"https://cdn.hidalgo.gob.mx/gobierno/pdf/4to-informe-completo.pdf"
				}
				,
				{
					numInforme:"5° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/5to_informe_f.png",
					url:"https://informe.hidalgo.gob.mx/",
					download:"https://cdn.hidalgo.gob.mx/gobierno/pdf/5to-informe-completo.pdf"
				}
				,
				{
					numInforme:"6° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/6to_informe_f.png",
					url:"https://informe.hidalgo.gob.mx/",
					download:"https://cdn.hidalgo.gob.mx/gobierno/pdf/6to-informe-completo.pdf"
				}
			],
			ejes:[
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/eje_1.png",
					texto:"Eje 1. Gobierno Honesto, Cercano y Moderno"
				},
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/eje_2.png",
					texto:"Eje 2. Hidalgo Próspero y Dinámico"
				},
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/eje_3.png",
					texto:"Eje 3. Hidalgo Humano e Igualitario"
				},
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/eje_4.png",
					texto:"Eje 4. Hidalgo Seguro, con Justicia y en Paz"
				},
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/OmarFayad/eje_5.png",
					texto:"Eje 5. Hidalgo con Desarrollo Sostenible"
				}				
			]
		},
		anterior: {
			gobernador:"Francisco Olvera Ruiz",
			imgGob:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/francisco.png",
			periodo:"2011 a 2016",
			planEstatal:"https://www.ordenjuridico.gob.mx/Documentos/Estatal/Hidalgo/wo86882.pdf",
			informes:[
				{	
					numInforme:"1° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/1er_informe.png",
					url:"https://informes2011-2016.hidalgo.gob.mx/primerinforme/",
					download:"https://informes2011-2016.hidalgo.gob.mx/primerinforme/libro_informe/1ER_INFORME.pdf"
				},
				{
					numInforme:"2° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/2do_informe.png",
					url:"https://informes2011-2016.hidalgo.gob.mx/segundoinforme/",
					download:"https://informes2011-2016.hidalgo.gob.mx/segundoinforme/download/version_estenografica-2o-informe-JFOR.pdf"
				},
				{
					numInforme:"3° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/3er_informe.png",
					url:"https://informes2011-2016.hidalgo.gob.mx/tercerinforme/",
					download:"https://informes2011-2016.hidalgo.gob.mx/tercerinforme/descargas.html"
				},
				{
					numInforme:"4° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/4to_informe.png",
					url:"https://informes2011-2016.hidalgo.gob.mx/cuartoinforme/",
					download:"https://informes2011-2016.hidalgo.gob.mx/cuartoinforme/descargas.html"
				},
				{
					numInforme:"5° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/5to_informe.png",
					url:"https://informes2011-2016.hidalgo.gob.mx/quintoinforme/",
					download:"https://informes2011-2016.hidalgo.gob.mx/quintoinforme/"
				},
				{
					numInforme:"6° Informe",
					fecha:"",
					text:"",
					imgInformes:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/6to_informe.png",
					url:"https://informes2011-2016.hidalgo.gob.mx/sextoinforme/",
					download:"https://informes2011-2016.hidalgo.gob.mx/sextoinforme/completo.html"
				},
			],
			ejes:[
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/eje_1.png",
					texto:"EJE 1. DESARROLLO SOCIAL PARA EL BIENESTAR DE NUESTRA GENTE "
				},
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/eje_2.png",
					texto:"EJE 2. COMPETITIVIDAD PARA EL DESARROLLO ECONÓMICO SUSTENTABLE"
				},
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/eje_3.png",
					texto:"EJE 3. DESARROLLO ORDENADO Y SUSTENTABLE"
				},
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/eje_4.png",
					texto:"EJE 4. PAZ Y TRANQUILIDAD SOCIAL, CONVIVENCIA CON ARMONÍA"
				},
				{	
					imgEje:"https://cdn.hidalgo.gob.mx/gobierno/images/informes/FranciscoOlvera/eje_5.png",
					texto:"EJE 5. GOBIERNO MODERNO, EFICIENTE Y MUNICIPALISTA"
				}				
			]
		}
	}
}