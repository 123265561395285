import React, {Component} from 'react';
const depfederales={
	"dependenciasfed":{
		"tabs":[{
			"general":{
				"header":"Dependencias Federales",
            	"description":"Conoce las Dependencias Federales con presencia en el Estado, presentamos sus datos, funciones y representantes por si deseas contactarlos."
			},
			"dependencias":[{
				"nombre":"18va. Zona Militar",
				"descripcion":"Tiene como objetivo Auxiliar a la población civil en casos de necesidades públicas; Realizar acciones cívicas y obras sociales que tiendan al progreso del país; y En caso de desastre prestar ayuda para el mantenimiento del orden, auxilio de las personas y sus bienes y la reconstrucción de las zonas afectadas.",
				"direccion":"Av. del Álamo s/n. (Campo Militar),Col. Venta Prieta",
				"cp":"42080",
				"telefono":"7-10-76-95",
				"email":"cmdte_18zm@sedena.gob.mx",
				"url":"https://www.sedena.gob.mx",
				"i":""
			},
			{
				"nombre":"Agencia del Ministerio Público",
				"descripcion":"Integración de averiguaciones previas de delitos federales.",
				"direccion":"Carr. México-Pachuca Km. 84.5, Sector Primario, Pachuca de Soto, Hgo.",
				"cp":"42080",
				"telefono":"7-17-52-08, 7-17-52-32",
				"email":"",
				"url":"https://www.pgr.gob.mx",
				"i":""
			},
			{
				"nombre":"Apoyos y Servicios a la Comercialización Agropecuaria ASERCA - SAGARPA",
				"descripcion":"Agencia de servicios a la comercialización y desarrollo de mercados agropecuarios( procurar que se consoliden, organización de productores, capacitación, logística, activen los programas de apoyo a la comercialización, y se tiene una área que se dedica al fomento de exportaciones, se otorga subsidios para que los productores participen en ferias.",
				"direccion":"Km. 1.5 carr. Pachuca - Tulancingo (Antiguo Edificio CONASUPO) Col Felipe Ángeles, Pachuca de Soto, Hgo.",
				"cp":"42090",
				"telefono":"7-17-05-36",
				"email":"joseluis.aragon@aserca.gob.mx",
				"url":"https://www.aserca.gob.mx/",
				"i":""
			},
			{
				"nombre":"Autotransporte Federal S.C.T.",
				"descripcion":"Regula y supervisa el Autotransporte Federal.",
				"direccion":"Blvd. Luis Donaldo Colosio Km. 12, No. 3702, Centro S.C.T. Hidalgo, Col. Ex-Hacienda Coscotiltán, Pachuca de Soto, Hgo.",
				"cp":"42080",
				"telefono":"01-800-71-5-69-27",
				"email":"gsalomonv@sct.gob.mx",
				"url":"https://www.sct.gob.mx/",
				"i":""
			},
			{
				"nombre":"Comisión Federal de Electricidad C.F.E.",
				"descripcion":"Suministro y control de energía eléctrica.",
				"direccion":"Pino Suárez Esq. Rojo Gómez No. 603, Col. Cubitos, Pachuca, Hgo.",
				"cp":"42090",
				"telefono":"7-17-99-02",
				"email":"rafael.navarro@cfe.gob.mx",
				"url":"https://www.cfe.mx/Pages/Index.aspx",
				"i":""
			},
			{
				"nombre":"Comisión Nacional de las Zonas Áridas CONAZA",
				"descripcion":"Depende de la SAGARPA se encarga de hacer proyectos de pequeñas obras hidráulicas.",
				"direccion":"Carr. México-Pachuca Km. 84.5 (Atrás de la Procuraduría) -CONAZA-Centro Cívico Hidalgo, Pachuca de Soto, Hgo.",
				"cp":"42083",
				"telefono":"7-11-08-66",
				"email":"conazahgo@prodigy.net.mx",
				"url":"https://www.conaza.gob.mx/",
				"i":""
			},
			{
				"nombre":"Comisión Nacional del Agua CONAGUA.",
				"descripcion":"Fortalecer el desarrollo técnico y la autosuficiencia financiera de los organismos operadores del país, a través de la aplicación de programas y acciones que impulsen el incremento en su eficiencia global y la prestación de mejores servicios.",
				"direccion":"Camino Real de la Plata NO. 429 COL. Zona Plateada Pachuca, Hidalgo",
				"cp":"42084",
				"telefono":"7-17-28-51, 71 7-28-55",
				"email":"guadalupe.villeda@conagua.gob.mx",
				"url":"https://www.conagua.gob.mx/",
				"i":""
			},
			{
				"nombre":"Comisión Nacional para el Desarrollo de los Pueblos Indígenas CDI.",
				"descripcion":"Obras de programa de infraestructura básica para la atención de pueblos indígenas, excarcelación de presos indígenas, programas de tercer nivel, pasajes medicamentos, o traslado a hospitales foráneos, albergues escolares indígenas, fondo regionales, programas operativos para mujeres indígenas, programa de turismo alternativo, huasteca, otomí tepehua, y valle del mezquital, cultura de indígenas, bandas tríos, tradiciones de los indígenas.",
				"direccion":"Calle s/n, Lote 3, Manzana 3, Área comercial entre Av. Ayuntamiento y Blvd. Colosio, Fracc. Colosio, Pachuca de Soto, Hgo.",
				"cp":"42088",
				"telefono":"7-16-38-61, 7-16-38-66 y 7-13-94-46",
				"email":"ccarrera@cdi.gob.mx",
				"url":"https://www.cdi.gob.mx/",
				"i":""
			},
			{
				"nombre":"Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros - CONDUSEF",
				"descripcion":"Organismo descentralizado de hacienda, defensor de los usuarios de servicios financieros, defensoría entre usuario y banco, tarjeta de crédito, buro de crédito, sar.",
				"direccion":"Calle 15 de Septiembre No. 108  Col.   Periodistas Pachuca de Soto, Hgo.",
				"cp":"42060",
				"telefono":"7-18-07-74, 7-18-03-65",
				"email":"hgocond@conducef.gob.mx",
				"url":"https://www.condusef.gob.mx/",
				"i":""
			},
			{
				"nombre":"INSUS (Instituto Nacional de Suelo Sustentable)",
				"descripcion":"Correcciones, solicitud de escrituración, regularización de predios siempre en cuando estén dentro de la poligonal",
				"direccion":"Felipe Angeles 203 Col. Venta Prieta",
				"cp":"42080",
				"telefono":"7-18-70-78, 7-14-75-28",
				"email":"hidalgo@corett.gob.mx",
				"url":"https://www.gob.mx/insus",
				"i":""
			},
			{
				"nombre":"Comisión para la Regularización de la Tenencia de la Tierra - CORETT",
				"descripcion":"Correcciones, solicitud de escrituración, regularización de predios siempre en cuando estén dentro de la poligonal.",
				"direccion":"Prol. Av. Juárez no. 1505, Col. Ex-Hda. de Coscotitlán, Pachuca de Soto, Hgo.",
				"cp":"",
				"telefono":"7-18-70-78, 7-14-75-28",
				"email":"hidalgo@corett.gob.mx",
				"url":"https://www.corett.gob.mx/",
				"i":""
			},
			{
				"nombre":"Comité Estatal de Sanidad Vegetal",
				"descripcion":"Servicios de fitos sanitarios, asesoría técnicas a productores que tengan problemas de plagas",
				"direccion":"Av. 2 No. 800 Col. Parque de Poblamiento",
				"cp":"42032",
				"telefono":"7-10-83-70",
				"email":"corporativo_jh@hotmail.com",
				"url":"https://cesaveh.com",
				"i":""
			},
			{
				"nombre":"Consejo Nacional de Ciencia y Tecnología - CONACYT",
				"descripcion":"Programa de fondos mixtos, apoyo para el fortalecimiento al desarrollo científico tecnológico e innovación, con entidad federal.",
				"direccion":"Lateral sur de la vía a Tlixcayotl no. 3106 local m 17 col. Reserva Territorial Atlizcayotl, San Andrés Cholula puebla dentro de la plaza Pabellón del Ángel",
				"cp":"72830",
				"telefono":"01-222-230-25-79, 01-222-230-27-89 ",
				"email":"alazcano@conacyt.mx",
				"url":"https://www.conacyt.gob.mx/",
				"i":""
			},
			{
				"nombre":"Consejo Nacional de Fomento Educativo - CONAFE",
				"descripcion":"Generar equidad educativa, para los niños y jóvenes de sectores vulnerables del país. Combatir el rezago educativo en educación inicial y básica.",
				"direccion":"Libramiento Vial a Tampico Km. 3.5, Col. Santiago Jaltepec , Mineral de la Reforma, Hgo.",
				"cp":"42182",
				"telefono":"",
				"email":"saustria@conafe.gob.mx",
				"url":"https://www.conafe.gob.mx/2",
				"i":""
			},
			{
				"nombre":"Coordinación Estatal de Educación Tecnológica Industrial",
				"descripcion":"Educación media superior CEBETis y CETis bachilleratos abiertos.",
				"direccion":"Av. Colosio Km. 1.9, Col. El Chacón, Mineral de la Reforma, Hgo.",
				"cp":"42182",
				"telefono":"7-18-00-11 Ext. 113",
				"email":"hidalgo@degeti.sep.gob.mx",
				"url":"https://www.sems.gob.mx/en_mx/sems/dgeti",
				"i":""
			},
			{
				"nombre":"Coordinación Estatal de la Policía Federal",
				"descripcion":"Unidad de patrullas mensajes de radios ,emergencias, etc.",
				"direccion":"Blvd. Luis Donaldo Colosio, Col. Las Palomas, Mineral de la Reforma, Hgo.",
				"cp":"",
				"telefono":"7-13-79-31, 7-17-97-01",
				"email":"region13hidalgo@hotmail.com",
				"url":"https://www.ssp.gob.mx",
				"i":""
			},
			{
				"nombre":"Cruz Roja Mexicana",
				"descripcion":"Emergencias , servicios médicos, desastres naturales, ayuda social.",
				"direccion":"Calle cofre de perote s/n col. San cayetano",
				"cp":"42084",
				"telefono":" 7-19-19-15, 7-19-19-16, 7-18-42-89",
				"email":"pepesaade@hotmail.com.mx",
				"url":"https://www.cruzrojamexicana.org.mx/",
				"i":""
			},
			{
				"nombre":"Delegación Federal del Trabajo - S.T.P.S.",
				"descripcion":"Una Secretaría del Trabajo y Previsión Social sustentada en tres ejes rectores: promoción, conciliación y legalidad, en el marco de una legislación laboral moderna que facilite el acceso de más jóvenes, mujeres y adultos mayores al mercado de trabajo de nuestro país con respeto hacia los derechos fundamentales y colectivos de los trabajadores, plasmados en el artículo 123 Constitucional.",
				"direccion":"Blvd. Felipe Angeles No. 801, Edif. SELAFE, 1° Piso, Col. Cuesco, Pachuca de Soto, Hgo.",
				"cp":"42080",
				"telefono":"7-13-35-42, 7-13-35-11",
				"email":"hgo102@stps.gob.mx",
				"url":"https://www.stps.gob.mx",
				"i":""
			},
			{
				"nombre":"DICONSA",
				"descripcion":"Se encarga de distribución de alimentos básicos a las comunidades más apartadas del estado y marginadas del estado de hidalgo a través de las tiendas comunitarias diconsa.",
				"direccion":"Km. 2 Carr. Pachuca - Tulancingo s/n., (A un lado de la Oficina de SAGARPA -ALMACENES DICONSA-), Col. Felipe Angeles, Pachuca de Soto, Hgo.", 
				"cp":"42090",
				"telefono":"71-7-01-50",
				"email":"mtovar@diconsa.gob.mx",
				"url":"https://www.diconsa.gob.mx",
				"i":""
			},
			{
				"nombre":"Dirección General de Centros de Formación para el Trabajo - DGCFT",
				"descripcion":"Coordinación general de formación de trabajo, 4 plantales federales, y escuelas particulares que estén interesadas en impartir clases de capacitación para el trabajo, como ingles, computación, servicios de belleza.",
				"direccion":"Blvd. Luis Donaldo Colosio Km. 1.9, Col. Chacón, Mineral de la Reforma, Hgo.", 
				"cp":"42093",
				"telefono":"7-18-49-90, 2-12-51-85",
				"email":"ruben_ji@hotmail.com",
				"url":"https://www.dgcft.sems.gob.mx/",
				"i":""
			},
			{
				"nombre":"FONACOT",
				"descripcion":"Empresa de gobierno federal y dan crédito a los trabajadores afiliados al instituto fonacot, empresas estatales, municipales y privados, créditos para compra de bienes y servicios y dinero en efectivo.",
				"direccion":"Blvd. Felipe Angeles No. 801, Edificio SELAFE, P.B., Ofna. de FONACOT, Col. Cuesco, Pachuca de Soto, Hgo.", 
				"cp":"42080",
				"telefono":"7-13-48-31 Ext. 25705",
				"email":"laura.garciaconde@fonacot.gob.mx",
				"url":"https://www.fonacot.gob.mx/",
				"i":""
			},
			{
				"nombre":"FINANCIERA RURAL",
				"descripcion":"Propiciar condiciones para la recuperación del nivel de vida en el medio rural mediante la oferta de financiamiento, asesoría y capacitación, en beneficio de los productores rurales, sobre todo a través de entidades intermediarias que aseguren el impacto en ellos.",
				"direccion":"Av. Revolución No. 1307, Plaza Bella local 2, 1º Piso, Col. Periodistas, Pachuca, Hgo.", 
				"cp":"42060",
				"telefono":"7-18-71-74, 7-18-71-68",
				"email":"yferrara@fnd.gob.mx",
				"url":"https://www.financierarural.gob.mx/",
				"i":""
			},
			{
				"nombre":"INFONAVIT",
				"descripcion":"Una institución mexicana tripartita donde participa el sector obrero, el sector empresarial y el gobierno, dedicada a otorgar crédito para la obtención de vivienda a los trabajadores y brindar rendimientos al ahorro que está en el Fondo Nacional de Vivienda para las pensiones de retiro.",
				"direccion":"Camino Real de la Plata No. 117 , Centro Financiero y de Negocios, Fracc. Zona Plateada, Pachuca de Soto, Hgo.", 
				"cp":"42084",
				"telefono":"7-17-89-02",
				"email":"dhidalgo@infonavit.org.mx",
				"url":"portal.infonavit.org.mx",
				"i":""
			},
			{
				"nombre":"Instituto Nacional Electoral- IFE",
				"descripcion":"El Instituto Nacional Electoral es un organismo público, autónomo y permanente encargado de organizar las elecciones federales, es decir, las referentes a la elección del Presidente de la República y de los Diputados y Senadores que integran el Congreso de la Unión.",
				"direccion":"Ayuntamiento No. 203 fracc. Colosio, Pachuca de Soto, Hgo.", 
				"cp":"42088",
				"telefono":"71-7-18-49-24",
				"email":"joseluis.ashane@ife.org.mx",
				"url":"https://www.ife.org.mx",
				"i":""
			},
			{
				"nombre":"Instituto Nacional de Estadística y Geografía - INEGI",
				"descripcion":"Acceso y asesoría sobre la información estadística y geográfica que se generan en el país , estado, los municipios con el objetivo de atender los requerimientos e información que permitan la correcta toma de decisiones en todos los sectores de la sociedad.",
				"direccion":"Blvd. Felipe Angles s/n Plaza Perisur, Edif. GM 4to. piso, Col. Venta Prieta, Pachuca, Hgo. ", 
				"cp":"42080",
				"telefono":"7-17-71-77",
				"email":"mauricio.marquez@inegi.org.mx",
				"url":"https://www.inegi.gob.mx",
				"i":""
			},
			{
				"nombre":"Instituto Mexicano del Seguro Social - IMSS",
				"descripcion":"Oficinas centrales, zona administrativa, finanzas, tesorería servicios médicos.",
				"direccion":"Blvd. Luis Donaldo Colosio No. 4604, Fracc. El Palmar No. II, Pachuca de Soto, Hgo.", 
				"cp":"42088",
				"telefono":"7-11-49-99",
				"email":"louerdes.osorio@imss.gob.mx",
				"url":"https://www.imss.gob.mx",
				"i":""
			},
			{
				"nombre":"Instituto Nacional de las personas Adultas Mayores (INAPAM)",
				"descripcion":"Apoyo a Adultos Mayores",
				"direccion":"Moctezuma No. 112 Col. Centro, Pachuca, Hidalgo.", 
				"cp":"42000",
				"telefono":"7-15-15-08, 7-11-10-84",
				"email":"inapamhgo@yahoo.com.mx",
				"url":"https://www.inapam.gob.mx",
				"i":""
			},
			{
				"nombre":"Instituto Nacional de Migración",
				"descripcion":"Órgano administrativo, dependiente de la Secretaría de Gobernación, el cual aplica la legislación migratoria vigente. Su público usuario lo integra quienes nos visitan de otros países, y aquellos que quieren permanecer en México en forma temporal o permanente, así como los mexicanos que contratan extranjeros y quienes desean establecer vínculos familiares con un extranjero o extranjera.",
				"direccion":"Blvd. Ramón G. Bonfil no. 318 col. Parque de poblamiento, Pachuca de Soto, Hgo.", 
				"cp":"42032",
				"telefono":"7-19-44-36",
				"email":"oficinadfhgo@inami.gob.mx",
				"url":"https://www.inm.gob.mx/",
				"i":""
			},
			{
				"nombre":"Instituto Nacional de Antropología e Historia",
				"descripcion":"Investiga, conserva y difunde el patrimonio arqueológico, antropológico, histórico y paleontológico de la nación con el fin de fortalecer la identidad y memoria de la sociedad que lo detenta.",
				"direccion":"Ex-Convento de San Francisco, Casa Sola s/n, Col. Centro, Pachuca de Soto, Hgo.", 
				"cp":"42000",
				"telefono":"7-13-19-77",
				"email":"sinafo@inah.gob.mx",
				"url":"https://www.inah.gob.mx/",
				"i":""
			},
			{
				"nombre":"Instituto de Seguridad y Servicios SOciales de los Trabajadores del Estado (ISSSTE)",
				"descripcion":"Tiene como objetivo proporcionar seguridad social a todos los trabajadores, ex-trabajadores, familiares y pensionados del gobierno federal y de las entidades federativas.",
				"direccion":"Carr. México-Pachuca Km. 87.8, Interior de Plaza Perisur, Col. Venta Prieta, Pachuca de Soto, Hgo. ", 
				"cp":"42080",
				"telefono":"7-11-11-16",
				"email":"rosma5609@hotmail.com",
				"url":"https://www.issste.gob.mx/",
				"i":""
			},
			{
				"nombre":"LICONSA",
				"descripcion":"Abasto Social de Leche.",
				"direccion":"Cuauhtémoc No. 800 1er. piso ( a espaldas de la Villita), Col. Hilaturas Pachuca, Pachuca de Soto, Hgo. ", 
				"cp":"42060",
				"telefono":"1-53-08-47",
				"email":"msanchezo@liconsa.gob.mx",
				"url":"https://www.liconsa.gob.mx",
				"i":""
			},
			{
				"nombre":"Poder Judicial de la Federación (Suprema Corte de Justicia de la Nación)",
				"descripcion":"Abasto Social de Leche.",
				"direccion":"Blvd. Colosio No. 1209, Palacio de Justicia Federal, Edif. B, 2° Piso, Fracc. Colosio I, Reserva Villa, Aquiles Serdán, Pachuca de Soto, Hgo.", 
				"cp":"42084",
				"telefono":"71-7-06-79",
				"email":"2jdo29cto@correo.cjf.gob.mx",
				"url":"https://www.scjn.gob.mx/",
				"i":""
			},
			{
				"nombre":"Procuraduría Agraria",
				"descripcion":"Se encarga de atender ejidos y comunidades, conciliación y conflictos agrarios, representación legal, organización y reglamentos de los ejidos, ordenamiento de los territorios regularizan las posiciones ejidales capacitación grupos agrarios, representantes ejidales y obligaciones dentro de los ejidos.",
				"direccion":"Boulevard Luis Donaldo Colosio No. 233, Pachuca de Soto, Hgo.", 
				"cp":"42084",
				"telefono":"7-15-46-83, 7-15-21-62",
				"email":"ajarillo@pa.gob.mx",
				"url":"https://www.pa.gob.mx/",
				"i":""
			},
			{
				"nombre":"Procuraduria Federal de la Defensa del Trabajo",
				"descripcion":"Asuntos laborales para los trabajadores juicios y pensiones se representa con abogados en las distintas instancias",
				"direccion":"Blvd. Felipe Angeles No. 801 Edif. SELAFE P.B., Col. Cuesco, Pachuca de Soto, Hgo.", 
				"cp":"42080",
				"telefono":"71-13-35-42, 7-13-45-22, 7-13-35-11",
				"email":"hgo200@stps.gob.mx",
				"url":"https://www.profedet.gob.mx/",
				"i":""
			},
			{
				"nombre":"Procuraduria Federal del Consumidor (PROFECO)",
				"descripcion":"Atención de quejas ante el incumplimiento de alguna de las condiciones pactadas, de forma verbal o escrita con los vendedores de productos y/o servicios.",
				"direccion":"Av. Cuauhtemoc no. 700. Esquina pasaje la Villita, Col. Revolución, Pachuca de Soto, Hgo. ", 
				"cp":"42060",
				"telefono":"7-14-06-90",
				"email":"pachuca@profeco.gob.mx",
				"url":"https://www.profeco.gob.mx/",
				"i":""
			},
			{
				"nombre":"Procuraduria Federal de Protección al Ambiente (PROFEPA)",
				"descripcion":"Vigilancia normativa ambiental, auditoría ambiental voluntaria, atención a las denuncias populares programadas por denuncias",
				"direccion":"Francisco González Bocanegra No. 110 letra C., Col. Maestranza, Pachuca de Soto, Hgo.", 
				"cp":"42060",
				"telefono":"7-18-83-70 ext. 18620",
				"email":"maviorbery@profepa.gob.mx",
				"url":"https://www.profepa.gob.mx/",
				"i":""
			},
			{
				"nombre":"Registro Agrario Nacional",
				"descripcion":"Se encarga de atender ejidos y comunidades, conciliación y conflictos agrarios, representación legal, organización y reglamentos de los ejidos, ordenamiento de los territorios regularizan las posesiones ejidales capacitación grupos agrarios, representantes ejidales y obligaciones dentro de los ejidos a nivel nacional.",
				"direccion":"Blvd. Felipe Angeles No. 1960 1er. piso (Adelante de los semáforos de la Central, rjmbo a México), Col. Ampliación Santa Julia, Pachuca de Soto, Hgo. ", 
				"cp":"42080",
				"telefono":"7-18-02-44, 7-18-05-25, 7-18-08-92",
				"email":"darivera@ran.gob.mx",
				"url":"https://www.ran.gob.mx/",
				"i":""
			},
			{
				"nombre":"SAGARPA",
				"descripcion":"Promover el desarrollo integral del campo y de los mares del país que permita el aprovechamiento sustentable de sus recursos, el crecimiento sostenido y equilibrado de las regiones, la generación de empleos atractivos que propicien el arraigo en el medio rural y el fortalecimiento de la productividad y competitividad de los productos.",
				"direccion":"Carr. Pachuca - Tulancingo No. 104-A (Antes Bodega CONASUPO), Col. Felipe Angeles, Pachuca de Soto, Hgo.", 
				"cp":"42090",
				"telefono":"7-13-15-52",
				"email":"delegado@hgo.sagarpa.gob.mx",
				"url":"https://www.sagarpa.gob.mx/",
				"i":""
			},
			{
				"nombre":"Secretaría de Comunicaciones y Transportes (SCT)",
				"descripcion":"Promover sistemas de transporte y comunicaciones seguros, eficientes y competitivos, mediante el fortalecimiento del marco jurídico, la definición de políticas públicas y el diseño de estrategias que contribuyan al crecimiento sostenido de la economía y el desarrollo social equilibrado del país.",
				"direccion":"Blvd. Luis Donaldo Colosio no. 3702., km. 12, col.   Ex-hacienda de Coscotitlán, Pachuca de Soto", 
				"cp":"42080",
				"telefono":"7-13-39-27",
				"email":"gsalomonb@sct.gob.mx",
				"url":"https://www.sct.gob.mx/",
				"i":""
			},
			{
				"nombre":"Secretaría de Desarrollo Social (SEDESOL)",
				"descripcion":"Formular y coordinar la política social solidaria y subsidiaria del gobierno federal, orientada hacia el bien común, y ejecutarla en forma corresponsable con la sociedad.",
				"direccion":"José Ma. Iglesias No. 202, Col. Centro, Pachuca de Soto, Hgo.", 
				"cp":"42000",
				"telefono":"7-13-28-75, 7-13-01-66, 7-13-02-57",
				"email":"crhistian.guevara@sedesol.gob.mx",
				"url":"https://www.sedesol.gob.mx/",
				"i":""
			},
			{
				"nombre":"Secretaría de Economía",
				"descripcion":"Área de promoción de programas de la secretaría de economía, financiamiento capacitación, para las micro, pequeñas, y medianas y grandes  empresas, área de servicios, se encarga de trámites de exportación e importación de  diferentes productos.",
				"direccion":"Blvd. G. Bonfil No. 439 Zona Plateada, Torre Platinum", 
				"cp":"42084",
				"telefono":"7-15-23-03, 7-15-22-80, 7-15-22-81",
				"email":" gabriela.lugo@economia.gob.mx",
				"url":"https://www.gob.mx/se/",
				"i":""
			},
			{
				"nombre":"Secretaría de Educación Pública",
				"descripcion":"Encargada de la educación tanto científica como deportiva en todos los niveles, así como de sus contenidos, y calendarios. Como labor adicional, registra los títulos y expide cédulas profesionales, llevando un registro de profesionistas.",
				"direccion":"Blvd. Felipe Ángeles s/n., Carr. México - Pachuca, km. 84.5, col. Venta Prieta, Pachuca de Soto, Hgo.", 
				"cp":"42080",
				"telefono":"7-11-49-48",
				"email":"mmray3@hotmail.com",
				"url":"hhttps://www.gob.mx/sep",
				"i":""
			},
			{
				"nombre":"Secretaría de Gobernación",
				"descripcion":"Atiende el desarrollo político del país y coadyuva en la conducción de las relaciones del Poder Ejecutivo Federal con los otros poderes de la Unión y los demás niveles de gobierno",
				"direccion":"Blvd. Felipe Ángeles s/n.   (entre el estadio Hidalgo y la esc. Normal superior), Col.   Santa Julia, Pachuca de soto, Hgo.", 
				"cp":"42080",
				"telefono":"7-18-51-15",
				"email":"hgaleana@cgob.gob.mx",
				"url":"https://www.segob.gob.mx",
				"i":""
			},
			{
				"nombre":"Secretaría de Hacienda y Crédito Público (S.H.C.P)",
				"descripcion":"Servicios fiscales, atención a contribuyentes, recaudación,  servicio de administración tributaria",
				"direccion":"Carr. Mexico - Pachuca km. 84.5, sector primario, Col. Carlos Rovirosa, Pachuca de Soto, Hgo.", 
				"cp":"42082",
				"telefono":"7-17-14-15, 7-17-14-17",
				"email":"",
				"url":"https://www.gob.mx/hacienda",
				"i":""
			},
			{
				"nombre":"Secretaria de Desarrollo Agrario Territorial y Urbano (SEDATU)",
				"descripcion":"Tiene el propósito de planificar, coordinar, administrar, generar y ejecutar las políticas públicas de ordenamiento territorial, asegurar una vivienda digna, un desarrollo urbano y rural",
				"direccion":"Felipe de Ángeles No. 700", 
				"cp":"42090",
				"telefono":"7-14-00-19, 7-14-39-64, 7-14-83-37",
				"email":"hgo@sedatu.gob.mx",
				"url":"https://www.gob.mx/sedatu",
				"i":""
			},
			{
				"nombre":"Secretaría de Medio Ambiente y Recursos Naturales (SEMARNAT)",
				"descripcion":"Encargada de impulsar la protección, restauración y conservación de los ecosistemas y recursos naturales y bienes y servicios ambientales de México, con el fin de propiciar su aprovechamiento y desarrollo sustentable.",
				"direccion":"Blvd. Everardo Márquez No. 612  (A un costado del consejo consultivo ciudadano) , Col. De los Maestros, Pachuca de Soto, Hgo.", 
				"cp":"42092",
				"telefono":"7-17-94-01, 7-17-94-07",
				"email":"delegado@hidalgo.semarnat.gob.mx",
				"url":"https://www.semarnat.gob.mx",
				"i":""
			},
			{
				"nombre":"Secretaría de Relaciones Exteriores",
				"descripcion":"Trámmite de pasaporte",
				"direccion":"Av. Juárez no. 400 A, Col. Centro, Pachuca de soto, Hgo.", 
				"cp":"42000",
				"telefono":" 2-87-54-01",
				"email":"delpachuca@sre.gob.mx",
				"url":"https://www.gob.mx/sre/",
				"i":""
			},
			{
				"nombre":"Servicio Postal Mexicano ",
				"descripcion":"Correos recepción, entrega de correspondencia y paquetería",
				"direccion":"José Ma. Iglesias no. 300, 2° piso, Esq. Av. Juárez, Col. Centro, Pachuca de Soto, Hgo.", 
				"cp":"42002",
				"telefono":"7-13-97-54",
				"email":"rmartinez@correosdemexico.gob.mx",
				"url":"https://www.gob.mx/correosdemexico",
				"i":""
			},
			{
				"nombre":"Sub-dirección Estatal de Educación Tecnológica Agropecuaria en Hidalgo.",
				"descripcion":"Bachilleratos agropecuarios a nivel  técnico, tiene 6 planteles a nivel estatal en zonas rurales ",
				"direccion":"Blvd. Luis Donaldo Colosio km. 1.9, Col. El Chacón, Mineral de la Reforma, Hgo.", 
				"cp":"42182",
				"telefono":"1-33-66-36, 1-33-66-33",
				"email":"dgetahgo@hotmail.com",
				"url":"https://www.sems.gob.mx/en_mx/sems/dgeta",
				"i":""
			},
			{
				"nombre":"Telecomunicaciones de México",
				"descripcion":"Realiza el cobro de todos los impuestos estatales, como tenencia, vehículos cambio de propietario, impuestos sobre nominas. Multas de ISR, cobro de trámites de transito de estado como licencia de manejo, en telecom y telégrafos se pueden pagar. a nivel federal los servicios de transferencia de dinero, pago internacional y nacional",
				"direccion":"Av. Juárez no. 401 esq. Iglesias, Col. Centro, Pachuca de Soto, Hgo.", 
				"cp":"42000",
				"telefono":"7-13-32-02, 7-13-26-96, 7-13-33-17",
				"email":"hidalgo@telecomm.net.mx",
				"url":"https://www.telecomm.net.mx/",
				"i":""
			},
			{
				"nombre":"Tribunal Unitario Agrario",
				"descripcion":"Todo lo ejidal agrícola/demandas de acciones",
				"direccion":"Av. Cuauhtémoc No. 606, letra B, Col. Centro, Pachuca de Soto, Hgo.", 
				"cp":"42000",
				"telefono":"7-18-09-14, 7-18-27-78, 7-18-08-66",
				"email":"tua14@prodigy.net.mx",
				"url":"https://www.tribunalesagrarios.gob.mx",
				"i":""
			}]
		}]
	}
}

class DependenciasFederales extends Component{
	
	render(){
		return(
			<div>
				{this._renderDependenciasFed()}
			</div>
		);
	}
	_renderDependenciasFed = () =>{
		const detalles=(text)=>{
			return text.map((textos)=>{
				return(
					<div className="col-12 col-sm-12 col-md-12 dependenciafed">
						<div style={{padding:"10px 50px 0px 50px"}}>
							<div className="nombre">{textos.nombre}</div>
							<div className="description">{textos.descripcion}</div>
						</div>
						<div className="datosdep">
							<div className="row" style={{margin:"0"}}>
								<div className="col-md-6 col-xs-6 col-sm-6" style={{paddingLeft:"3px"}}>
									<div className="contacto">Datos de contacto</div>
									<div className="contactodatos">
										<ul>
											<li><i className="fa fa-map-marker" aria-hidden="true"></i>{textos.direccion + " ( "+ textos.cp +" )" }</li>
											<li><i className="fas fa-phone"></i>{textos.telefono}</li>
											<li><i className="fas fa-envelope"></i><a href={"mailto:" + textos.email}>Correo electrónico</a></li>
											<li><i className="fa fa-home" aria-hidden="true"></i><a target="_blank" href={textos.url}>Ir al sitio</a></li>
										</ul>
									</div>
								</div>
								<div className="col-md-6 col-xs-6 col-sm-6" style={{backgroundImage:'url('+textos.i+')',backgroundSize:"cover",height:"210px"}}></div>
							</div>
						</div>
					</div>
					);
			})
		}
		return depfederales.dependenciasfed.tabs.map(({ general, dependencias }, index) => {
		return(
				<div id="contentglobal" className={"container DependenciasFederales"}>
					<h1 className="titulo" >DEPENDENCIAS FEDERALES</h1>
					<p style={{paddingTop:"2%"}}>{general.description}</p>
					<div style={{padding:"2% 3%"}}>
			        </div>
			        {detalles(dependencias)}
				</div>
			);
		});
	}
}

export default DependenciasFederales;
	