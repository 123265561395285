import React, {Component} from 'react';

class AvisoPrivacidad extends Component{


	
	render(){
		return(
			<div id="contentglobal" className={"container AvisoPrivacidad"}>
				<div className={"row"}>
					<div className={"col-12 mt-3 mb-3"}>
						<iframe src="pdf/TyC.pdf" height="1000px" width="100%"></iframe>
					</div>
				{/*<div className={"col-12 mt-3 mb-3"}>
		                <p>
		                	El presente Acuerdo, constituye la descripción de los términos y condiciones generales para el uso de los contenidos disponibles por, o a través del Sitio
							Web www.hidalgo.gob.mx en adelante “Sitio Web” y Aplicaciones Móviles, así
							como a políticas y principios incorporados al presente documento, aplicables
							al uso de los contenidos y servicios disponibles a través del “Sitio Web” y
							Aplicaciones Móviles, de los cuales es propietario el Gobierno del Estado de
							Hidalgo, que celebran por una parte el Estado Libre y Soberano de Hidalgo, a
							través del Oficial Mayor, asistido en esta acto por el L.C.C. José Martín Salazar
							Ávila, en su carácter de Director General de Innovación Gubernamental y
							Mejora Regulatoria a quienes en lo sucesivo y para efectos del presente
							Acuerdo se les denominará “El Estado” y por la otra, cualquier persona que
							ingrese al “Sitio Web” o a cualquiera de las subpáginas o aplicaciones móviles
							que desplieguen su contenido, que se dé de alta o use los servicios y trámites
							que se ofrecen a través de los mismos, se le denominará como “El Usuario”;
							a quienes en forma conjunta se denominarán “Las Partes”; al tenor de los
							antecedentes, declaraciones y cláusulas siguientes:
		                </p>
		                <h3 className={"text-center mt-3"}>ANTECEDENTES</h3>
		                <p className={"mt-3"}>
		                	<span>PRIMERO.- </span>
		                	El uso y aprovechamiento de las Tecnologías de Información y
							Comunicaciones (TIC´s), se ha convertido en pieza fundamental para el diseño de
							políticas públicas en los gobiernos de todos los países que quieren alcanzar mayor
							eficiencia y eficacia en la prestación de servicios, con el objeto de reducir tiempos y
							costos en beneficio de la población.
						</p>
						<p className={"mt-3"}>
		                	<span>SEGUNDO.- </span>
		                	El Gobierno del Estado de Hidalgo, estableció como objetivo general
							dentro del Plan Estatal de Desarrollo 2016-2022 en su Eje 1 denominado “Gobierno
							Honesto, Cercano y Moderno” en el punto 1.3. “Gobierno Digital”, promover y
							coordinar acciones de modernización de la gestión pública, con el objeto de realizar
							con eficiencia y eficacia las tareas gubernamentales, partiendo de la premisa, de
							que la población tiene el derecho de obtener calidad, eficiencia y transparencia en
							los trámites y servicios ofertados por las dependencias y entidades de la
							administración pública estatal.
						</p>
						<p className={"mt-3"}>
		                	Por lo anterior, la Secretaría Ejecutiva de la Política Pública Estatal, a través de la
							Dirección General de Innovación Gubernamental y Mejora Regulatoria, en adelante
							la “DIGIGMER”, tiene la atribución de dirigir las estrategias, lineamientos y
							normatividad, referentes a los procesos y acciones de modernización tecnológica
							gubernamental; así como proponer procesos de planeación, programación y, en su
							caso, validación de proyectos relativos al mejoramiento de sistemas, mejora de
							procesos, simplificación administrativa, mejora regulatoria, regulación base cero,
							investigación y desarrollo tecnológico, automatización, desarrollo web, incluyendo
							la administración del portal institucional www.hidalgo.gob.mx y la integración del
							sistema de evaluación de la gestión pública estatal, de conformidad con los artículos
							7, fracción II, inciso e y 15 fracciones I a la X, del Reglamento Interior de la
							Secretaría Ejecutiva de la Política Pública Estatal.
						</p>
						<h3 className={"text-center mt-3"}>DECLARACIONES</h3>
						<p className={"mt-3"}>
		                	<span>1. “El Estado”</span>
		                </p>
		                <p className={"mt-3"}>
		                	1.1 Que de conformidad con los artículos 40 y 43 de la Constitución Política de
							los Estados Unidos Mexicanos y 1º de la Constitución Política para el Estado de
							Hidalgo, es parte integrante de la Federación y cuenta con personalidad jurídica
							propia.
		                </p>
		                <p className={"mt-3"}>
		                	1.2 Que según lo previsto en los artículos 61 de la Constitución Política para el
							Estado de Hidalgo y 2º de la Ley Orgánica de la Administración Pública para el
							Estado de Hidalgo, el ejercicio del Poder Ejecutivo se deposita en el Gobernador
							Constitucional del Estado de Hidalgo.
		                </p>
		                <p className={"mt-3"}>
		                	1.3 Que con fundamento en lo previsto en el artículo 3 de la Ley Orgánica de la
							Administración Pública para el Estado de Hidalgo, el Poder Ejecutivo para el
							despacho de los asuntos que le competen, se auxiliará de las dependencias de la
							administración pública del Estado de Hidalgo.
		                </p>
		                <p className={"mt-3"}>
		                	1.4 Que de acuerdo con el artículo 13 fracción XVIII de la Ley Orgánica de la
							Administración Pública para el Estado de Hidalgo, la Oficialía Mayor es una
							dependencia del Poder Ejecutivo, con facultades para suscribir el presente Acuerdo,
							de conformidad con el artículo 37 Quáter fracción III de la mencionada Ley Orgánica.
		                </p>
		                <p className={"mt-3"}>
		                	1.5 Que con fecha 2 de enero de 2017, el Licenciado Omar Fayad Meneses,
							Gobernador Constitucional del Estado Libre y Soberano de Hidalgo, tuvo a bien
							nombrar al Profr. Martiniano Vega Orozco como Oficial Mayor, de acuerdo con las
							facultades que le otorga el artículo 71 fracción XII de la Constitución Política para el
							Estado de Hidalgo.
		                </p>
		                <p className={"mt-3"}>
		                	1.6 Que con fecha 5 de Septiembre de 2016, el Licenciado Omar Fayad Meneses,
							Gobernador Constitucional del Estado Libre y Soberano de Hidalgo, tuvo a bien
							nombrar al L.C.C. José Martín Salazar Ávila como Director General de Innovación
							Gubernamental y Mejora Regulatoria, como parte integrante de la Secretaría
							Ejecutiva de la Política Pública Estatal, de conformidad con el artículo 7, fracción II,
							inciso e y artículo 15 fracciones I a la X del Reglamento Interior de la Secretaría
							Ejecutiva de la Política Pública Estatal.
		                </p>
		                <p className={"mt-3"}>
		                	1.7 Que señala como domicilio, el ubicado en: Palacio de Gobierno, sito en Plaza
							Juárez, sin número, Colonia Centro, C.P. 42000, en la ciudad de Pachuca de Soto,
							Hidalgo.
		                </p>
		                <p className={"mt-3"}>
		                	<span>2. “El Usuario”</span>
		                </p>
		                <p className={"mt-3"}>
		                	2.1 Que es mayor de edad y cuenta con personalidad jurídica para la aceptación de
							los términos y condiciones del presente Acuerdo.
		                </p>
		                <p className={"mt-3"}>
		                	<span>3. “Las Partes”</span>
		                </p>
		                <p className={"mt-3"}>
		                	3.1 Que bajo protesta de decir verdad, cuentan con plena capacidad jurídica para
							celebrar el presente Acuerdo, misma que no les ha sido revocada, ni en forma
							alguna modificada, sabedores de las penas en que incurren quienes declaran con
							falsedad.
		                </p>
		                <p className={"mt-3"}>
		                	3.2 Que es su voluntad celebrar el presente Acuerdo, en los términos y condiciones
							que se establecen en las siguientes:
		                </p>
		                <h3 className={"text-center mt-3"}>CLAUSULAS</h3>
		                <p className={"mt-3"}>
		                	<span>I. DEL OBJETO</span>
		                </p>
		                <p className={"mt-3"}>
		                	El objeto del presente Acuerdo, es regular el acceso y la utilización del “Sitio Web”
							y aplicaciones móviles entendiendo por este, cualquier tipo de contenido, trámite o
							servicio que se encuentre a disposición del público en general, dentro del dominio
							www.hidalgo.gob.mx el cual, contribuye al ejercicio y cumplimiento de los derechos
							de acceso a la información, transparencia, libertad de expresión, el derecho a la
							protección de la vida privada y protección de datos personales, de conformidad con
							la Constitución Política para el Estado de Hidalgo y la legislación aplicable.
		                </p>
		                <p className={"mt-3"}>
		                	El “Sitio Web” y las aplicaciones móviles están construidos para facilitar el acceso a
							“El Usuario”, independientemente de sus capacidades físicas, sensoriales o
							intelectuales, así como del tipo de dispositivo, versión y tipo de navegador,
							programa informático, velocidad de la conexión, condiciones ambientales, etc. 
		                </p>
		                <p className={"mt-3"}>
		                	<span>II. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES </span>
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” que desee acceder o hacer uso del “Sitio Web”, aplicaciones móviles o
							de los trámites y servicios, podrá hacerlo aceptando los términos y condiciones del
							presente Acuerdo y declara expresamente su aceptación utilizando para tal efecto,
							medios electrónicos, en términos de lo dispuesto en el artículo 1787 y demás
							relativos del Código Civil para el Estado de Hidalgo. En caso contrario, deberá
							abstenerse de utilizar el “Sitio Web”.
		                </p>
		                <p className={"mt-3"}>
		                	En caso de que “El Usuario” acceda, utilice y observe el “Sitio Web” o aplicaciones
							móviles se considerará como una absoluta y expresa aceptación de los términos y
							condiciones estipulados. “El Estado” no guardará una copia individualizada del
							presente Acuerdo celebrado entre “El Usuario” y “El Estado”, por lo que se le
							recomienda a “El Usuario” que guarde una copia del mismo para su propio
							expediente.
		                </p>
		                <p className={"mt-3"}>
		                	<span>III. DEL ADMINISTRADOR DEL “SITIO WEB” Y APLICACIONES MÓVILES</span>
		                </p>
		                <p className={"mt-3"}>
		                	La “DIGIGMER” será responsable de la administración, tratamiento y uso de la
							información que recabe en forma directa, a través del “Sitio Web” y aplicaciones
							móviles. 
		                </p>
		                <p className={"mt-3"}>
		                	Debido a que el “Sitio Web” y las aplicaciones móviles integran a los sitios web de
							las dependencias y entidades de la administración pública estatal como subpáginas,
							es responsabilidad de éstas, cumplir con las pautas de accesibilidad en el contenido
							de sus propios sitios web, en términos de las disposiciones aplicables
		                </p>
		                <p className={"mt-3"}>
		                	El “Sitio Web” y aplicaciones móviles podrán incluir hipervínculos o enlaces que
							permitan acceder a páginas web de terceros, subpáginas, enlaces, contenidos,
							trámites, servicios, funciones, imágenes, videos, comentarios, motores de pago,
							motores de búsqueda, aplicaciones móviles, etc., sin embargo, estos enlaces se
							proporcionan sólo con fines informativos. Los titulares de dichos sitios web
							dispondrán de sus propias políticas de privacidad y protección de datos personales,
							por lo cual, la “DIGIGMER” no asume ningún tipo de responsabilidad por los datos
							que sean facilitados por “El Usuario” en distintos sitios, al “Sitio Web” y aplicaciones
							móviles.
		                </p>
		                <p className={"mt-3"}>
		                	El “Sitio Web” y aplicaciones móviles pueden contener la utilización de estos
							enlaces, contenidos, trámites, servicios y funciones, los cuales, tienen por objeto
							mejorar la experiencia de “El Usuario” al hacer uso del “Sitio Web” y aplicaciones
							móviles sin que pueda considerarse una sugerencia, recomendación o invitación
							para hacer uso de sitios externos.
		                </p>
		                <p className={"mt-3"}>
		                	La “DIGIGMER” en ningún caso, revisará o controlará el contenido de sitios externos
							o subpáginas, de igual forma, no hace propios los contenidos y cualquier otro
							material existente en los referidos sitios enlazados, por lo cual, la “DIGIGMER” no
							asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse
							por el acceso o uso de los contenidos, trámites y servicios disponibles en los sitios
							web no administrados por la “DIGIGMER”, a los que “El Usuario” pueda acceder
							mediante el “Sitio Web” y aplicaciones móviles. 
		                </p>
		                <p className={"mt-3"}>
		                	Las dependencias y entidades de la administración pública estatal, que sean
							responsables de la información, trámites y servicios publicados en las subpáginas y
							en el propio “Sitio Web” y de aplicaciones móviles, darán continuidad (por los medios
							que establezcan) a las solicitudes realizadas por “El Usuario”.
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” se reserva el derecho de retirar todos aquellos comentarios y
							aportaciones que vulneran la Ley, el respeto a la dignidad de la persona, que sean
							discriminatorios, atenten contra los derechos de tercero o el orden público, o bien,
							que a su juicio no resulten adecuados para su publicación. En cualquier caso “El
							Estado” no será responsable de las opiniones vertidas por “El Usuario” a través de
							comentarios o publicaciones que estos realicen.
		                </p>
		                <p className={"mt-3"}>
		                	<span>IV. DE “EL USUARIO”</span>
		                </p>
		                <p className={"mt-3"}>
		                	El acceso, utilización del “Sitio Web”, así como de los recursos habilitados para
							interactuar entre “El Usuario” y “El Estado” tales como servicios de mensajería,
							servicios de chateo, tableros de noticias, blogs, otros foros, aplicaciones móviles y
							otros servicios disponibles en, o a través del “Sitio Web” quedarán sujetos al
							presente Acuerdo y a sus ulteriores modificaciones, sin perjuicio a la aplicación de
							la legislación aplicable, por tanto, se tendrá por aceptado desde el momento en el
							que se accede al “Sitio Web” o aplicación móvil, por lo que se recomienda a “El
							Usuario” revisar las actualizaciones que se realicen al presente documento. 
		                </p>
		                <p className={"mt-3"}>
		                	Es responsabilidad de “El Usuario” hacer uso del “Sitio Web” o aplicaciones móviles
							de acuerdo a la forma en las que fueron diseñados, en este sentido, queda prohibida
							la utilización de cualquier tipo de software que automatice la interacción o descarga
							ilegal de los contenidos proporcionados.
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” se compromete a utilizar la información, contenidos, trámites y servicios
							ofrecidos a través del “Sitio Web” y aplicaciones móviles de manera lícita, sin
							contravenir lo dispuesto en el presente Acuerdo, la moral o el orden público, y se
							abstendrá de realizar cualquier acto que pueda suponer una afectación a los
							derechos de terceros, o perjudique de algún modo el funcionamiento del “Sitio Web”
							y/o aplicaciones móviles pertenecientes a “El Estado”.
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” se compromete a proporcionar información lícita y veraz en los
							contenidos, trámites y servicios ofertados por el “Sitio Web”, aplicaciones móviles o
							subpáginas. En todo caso “El Usuario” notificará de forma inmediata a “El Estado”
							acerca de cualquier hecho que permita suponer el uso indebido de la información.
		                </p>
		                <p className={"mt-3"}>
		                	El solo uso o acceso al “Sitio Web” y aplicaciones móviles no supone el
							establecimiento de ningún tipo de relación entre “El Estado” y “El Usuario”. 
		                </p>
		                <p className={"mt-3"}>
		                	Para poder usar el “Sitio Web” y aplicaciones móviles, “El Usuario” debe tener por
							lo menos 18 años de edad o estar accediendo bajo la supervisión de un padre o
							tutor legal, con la capacidad jurídica necesaria para sujetarse a los términos y
							condiciones del presente Acuerdo.
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” es responsable de las opiniones que emita y de cualquiera de las
							actividades que realice dentro del “Sitio Web”, aplicaciones móviles y subpáginas.
		                </p>
		                <p className={"mt-3"}>
		                	<span>Queda prohibido para “El Usuario”:</span>
		                </p>
		                <p className={"mt-3"}>
		                	Personificar a cualquier otra persona o entidad, ya sea real o ficticia
		                </p>
		                <p className={"mt-3"}>
		                	Dañar, inutilizar, alterar, editar, borrar, quitar, o de otra manera cambiar el
							significado, la apariencia, el propósito de cualquiera de los códigos y contenidos que
							se encuentren protegidos, ya sea a través de un acceso restringido mediante un
							usuario y contraseña, o porque no cuente con los permisos para visualizarlos,
							editarlos o manipularlos y por ningún motivo realizará actos tendientes a vulnerar la
							seguridad de la información, bases de datos y archivos
		                </p>
		                <p className={"mt-3"}>
		                	Instalar elementos de origen fraudulento e ilegal, que contengan virus, componentes
							de espionaje u otros elementos dañinos que; restrinjan, interfieran o interrumpan la
							funcionalidad de servidores o redes conectadas al “Sitio Web” y subpáginas o inhiba
							el acceso y utilización a cualquier otro usuario.
		                </p>
		                <p className={"mt-3"}>
		                	Ofrecer, distribuir, comprar o vender algún producto o servicio en, o a través de sus
							comentarios presentados en el “Sitio Web” y aplicaciones móviles, así como emitir
							declaración u opinión alguna que pudiera considerarse amenazante, abusiva,
							hostigante, calumniosa, difamatoria, obscena, ofensiva, de publicidad, cadenas o
							esquemas de pirámides de cualquier tipo, hechos falsos o engañosos que
							constituyan o alienten conductas que pudieran constituir actos criminales, viole,
							plagie o infrinja los derechos de terceros incluyendo, sin limitación, derechos de
							autor, marcas, confidencialidad, contratos, patentes, derechos de privacidad o
							cualquier otro derecho de propiedad, incluyendo, sin limitación, la alteración o retiro
							de cualquier marca comercial, marca registrada, logos institucionales, marca de
							servicios o cualquier otro contenido de propiedad o notificación de derechos de
							propiedad o cualquier acción que dé lugar a responsabilidad civil o de violación a
							cualquier ley local, estatal, nacional o internacional.
		                </p>
		                <p className={"mt-3"}>
		                	<span>Al hacer uso del “Sitio Web” y aplicaciones móviles, “El Usuario” acepta que
								su experiencia, se lleve de la forma siguiente:</span>
		                </p>
		                <p className={"mt-3"}>
		                	El acceso al “Sitio Web” y aplicaciones móviles puede implicar la utilización de
							cookies, las cuales, son pequeñas cantidades de información que se almacenan en
							el navegador utilizado por “El Usuario”. Este tipo de información será utilizada para
							mejorar el “Sitio Web”, detectar errores, y posibles necesidades que “El Usuario”
							pueda tener, lo anterior a efecto de ofrecer trámites, servicios y contenidos de mejor
							calidad. La información que se recopile será anónima y no se identificará a “El
							Usuario” individualmente.
		                </p>
		                <p className={"mt-3"}>
		                	Las cookies facilitan la navegación, la hacen más amigable, y no dañan el
							dispositivo de navegación, para ello, pueden recabar información para ingresar al
							“Sitio Web”, almacenar las preferencias de “El Usuario”, así como la interacción que
							este tenga con el “Sitio Web”, como por ejemplo; la fecha y hora en la que se accede
							al “Sitio Web” el tiempo que se ha hecho uso de este, los sitios visitados antes y
							después del mismo, el número de páginas visitadas, la dirección IP de la cual
							accede “El Usuario”, la frecuencia de las visitas, etc.
		                </p>
		                <p className={"mt-3"}>
		                	En caso de que “El Usuario” no desee que se recopile este tipo de información,
							deberá deshabilitar, rechazar, restringir o eliminar el uso de cookies en su
							navegador de internet. Los procedimientos para realizar estas acciones pueden
							diferir de un navegador a otro; en consecuencia se sugiere revisar las instrucciones
							facilitadas por el desarrollador del navegador. En el supuesto que rechace el uso de
							cookies (total o parcialmente) “El Usuario” podrá continuar haciendo uso del “Sitio
							Web”, aunque podrían quedar deshabilitadas algunas de las funciones del mismo.
		                </p>
		                <p className={"mt-3"}>
		                	La información, peticiones o comentarios que “El Usuario” emita en el “Sitio Web”
							podrán ser utilizados para la realización de informes estadísticos con el objeto de
							proporcionar calidad en la información, trámites y servicios en beneficio de “El
							Usuario”.
		                </p>
		                <p className={"mt-3"}>
		                	Es posible que en el futuro estas políticas respecto a cookies cambien o se
							actualicen, por ello es recomendable revisar las actualizaciones que se realicen al
							presente Acuerdo.
		                </p>
		                <p className={"mt-3"}>
		                	<span>I. DEL ACCESO Y NAVEGACIÓN EN EL “SITIO WEB” Y APLICACIONES MÓVILES</span>
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” no garantiza de ningún modo la continuidad y disponibilidad de los
							contenidos, trámites y servicios ofrecidos a través del “Sitio Web” y aplicaciones
							móviles, no obstante, “El Estado” llevará a cabo las acciones que de acuerdo a sus
							posibilidades le permitan mantener el buen funcionamiento del “Sitio Web” y
							aplicaciones móviles sin que esto suponga alguna responsabilidad por parte de “El
							Estado”. 
		                </p>
		                <p className={"mt-3"}>
		                	De igual forma “El Estado” no será responsable ni garantiza que el contenido o
							software al que pueda accederse a través del “ Sitio Web” se encuentre libre de
							errores, software malicioso, o que pueda causar algún daño a nivel de software o
							hardware en el equipo, a través del cual “El Usuario” accede al “Sitio Web”.
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” tampoco se hace responsable de los daños que pudiesen ocasionarse
							por un uso inadecuado del “Sitio Web” y aplicaciones móviles. 
		                </p>
		                <p className={"mt-3"}>
		                	En los casos que sea requerida la utilización de la Firma Electrónica Avanzada
							dentro del “Sitio Web”, “El Usuario” deberá contar con su Certificado vigente y activo
							conforme a lo previsto en la Ley sobre el Uso de Medios Electrónicos y Firma
							Electrónica Avanzada para el Estado de Hidalgo. Así mismo, “El Usuario” asume
							cualquier tipo de responsabilidad derivada del mal uso que hagan con su Firma
							Electrónica Avanzada y/o Certificado Digital y acepta la responsabilidad de entregar
							información propia y auténtica en idioma español, salvo disposición jurídica que
							permita que la información y documentación se presente en idioma distinto.
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” deberá tomar sus propias precauciones para asegurar que durante el
							acceso al “Sitio Web”, sus equipos no se expongan a riesgos como virus
							informáticos, spyware´s, software malicioso u otras formas de amenazas
							informáticas.
		                </p>
		                <p className={"mt-3"}>
		                	En ningún caso “El Estado” será responsable por las pérdidas, daños o perjuicios
							de cualquier tipo que surjan por el solo acceso o utilización del “Sitio Web”.
		                </p>
		                <p className={"mt-3"}>
		                	<span>II. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</span>
		                </p>
		                <p className={"mt-3"}>
		                	Para “El Estado” es de gran importancia su privacidad y confianza, por ello se
							compromete a adoptar las medidas necesarias que estén a su alcance para
							asegurar la privacidad de los datos personales recabados a través del “Sitio Web” y
							aplicaciones móviles, los cuales serán tratados de conformidad con los principios
							de licitud, calidad, finalidad, lealtad y responsabilidad, de forma que se garantice su
							seguridad, se evite su alteración, perdida o tratamiento no autorizado. 
		                </p>
		                <p className={"mt-3"}>
		                	Los datos personales recolectados por las dependencias y entidades de la
							administración pública estatal, a través de su portal institucional dentro del “Sitio
							Web”, aplicaciones móviles o por cualquier otro sujeto obligado que utilice el “Sitio
							Web” para la prestación de trámites y servicios digitales, y que recabe datos
							personales, serán tratados bajo su responsabilidad, conforme a sus atribuciones
							legales de conformidad con lo establecido en la Ley General de Protección de Datos
							Personales en Posesión de Sujetos Obligados.
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” tratará los Datos Personales que obtenga de “El Usuario” de manera
							confidencial a través de medidas de seguridad y esquemas previstos para tales
							efectos. Se entiende que “El Usuario” en su carácter de Titular de los Datos
							Personales, consiente tácitamente el tratamiento de sus Datos, cuando habiéndose
							puesto a su disposición este Acuerdo no manifieste su oposición.
		                </p>
		                <p className={"mt-3"}>
		                	<span>Transferencia de Datos</span>
		                </p>
		                <p className={"mt-3"}>
		                	No se realizarán transferencias de datos personales, salvo aquéllas que sean
							necesarias para atender requerimientos de información de una autoridad
							competente, que estén debidamente fundados y motivados.
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” podrá transferir sus datos personales a terceros mexicanos o
							extranjeros, cuando la transferencia: i) Este prevista en una Ley o Tratado en los
							que México o “El Estado” sea parte; ii) Sea necesaria en virtud de un Contrato,
							Convenio, Acuerdo o Instrumento Legal celebrado o por celebrar en interés de “El
							Usuario” y de “El Estado”; iii) Sea necesaria o legalmente exigida para la
							salvaguarda de un interés público, o para la procuración o administración de justicia;
							iv) Sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un
							proceso judicial: v) Sea precisa para el mantenimiento o cumplimiento de una
							relación jurídica de “El Estado” y “El Usuario”.
		                </p>
		                <p className={"mt-3"}>
		                	En el caso de que exista un mandato de autoridad judicial o de aquella autoridad
							facultada por Ley, “El Estado” podrá proporcionar y conservar información que la
							autoridad considere necesaria para cumplir con procesos legales o para responder
							a quejas de terceras personas por violación a derechos de autor, marcas, patentes,
							seguridad e integridad de los usuarios o que contravengan este Acuerdo o
							cualquiera otra disposición legal.
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” se reserva el derecho a modificar su política de privacidad, de acuerdo
							a sus necesidades o derivado de algún cambio en la legislación. El acceso o 
							utilización del “Sitio Web” y aplicaciones móviles después de dichos cambios,
							implicará la aceptación de estos. 
		                </p>
		                <p className={"mt-3"}>
		                	<span>Ejercicio de Derechos ARCO</span>
		                </p>
		                <p className={"mt-3"}>
		                	De acuerdo con la Ley General de Protección de Datos Personales en Posesión de
							Sujetos Obligados, “El Usuario” o su representante legal podrá ejercer en cualquier
							momento sus derechos de Acceso, Rectificación, Cancelación y Oposición (ARCO)
							en los casos previstos en la mencionada Ley, también podrá en cualquier momento:
							i) limitar el uso o divulgación de sus datos personales; o ii) revocar su consentimiento
							para dar tratamiento a sus datos personales, sin que se le atribuyan efectos
							retroactivos. El ejercicio de cualquiera de los derechos no es requisito previo ni
							impide el ejercicio del otro. 
		                </p>
		                <p className={"mt-3"}>
		                	Para ejercer cualquiera de los derechos anteriores se deberá: i) Solicitar a través de
							correo electrónico a webhgo@hidalgo,gob.mx; ii) indicar el nombre del titular y su
							dirección de correo electrónico o domicilio en que deseen recibir la respuesta a su
							solicitud; iii) adjuntar copia legible de una identificación oficial del titular y, en caso
							de representante legal también se deberá adjuntar la carta poder firmada ante dos
							testigos o instrumento público, así como copia de la identificación oficial del
							representante legal; iv) describir clara y precisamente los datos personales respecto
							de los que se busca ejercer alguno de los derechos mencionados; v) en el caso de
							rectificación de datos personales, se deberá adjuntar la documentación que
							sustente la solicitud; vi) señalar o adjuntar cualquier otro elemento o documento que
							facilite la localización de datos personales.
		                </p>
		                <p className={"mt-3"}>
		                	En caso de que la información de la solicitud sea insuficiente o errónea para
							atenderla o no se acompañen los documentos solicitados, “El Estado” podrá requerir
							a “El Usuario” o a su representante legal, por una vez y dentro de los cinco días
							hábiles siguientes a la recepción de la solicitud, que aporte elementos o documentos
							necesarios para dar trámite a la misma. De no obtener respuesta en ese plazo se
							tendrá por no presentada la solicitud. “El Estado” dará respuesta a su solicitud al
							correo electrónico o al domicilio señalado por el titular dentro de los 20 días hábiles
							siguientes a la fecha en que recibió la solicitud, a efecto de que si resulta
							procedente, se haga efectiva la misma dentro de los 15 días hábiles siguientes a la
							fecha en que se comunica la respuesta. Los plazos antes señalados podrán ser
							ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las
							circunstancias del caso.
		                </p>
		                <p className={"mt-3"}>
		                	Tratándose de solicitudes de acceso se procederá a la entrega previa acreditación
							de la identidad de “El Usuario” y representante legal. En este caso la entrega de
							datos será gratuita, debiendo el titular cubrir únicamente los gastos justificados de
							envío o costo de reproducción en copias u otros formatos.
		                </p>
		                <p className={"mt-3"}>
		                	<span>III. SEGURIDAD</span>
		                </p>
		                <p className={"mt-3"}>
		                	El “Sitio Web” y las aplicaciones móviles pertenecientes a “El Estado” cuentan con
							las medidas de seguridad de la información necesarias para brindar a “El Usuario”
							un servicio seguro con la protección de datos personales e información, en
							cumplimiento a la legislación aplicable, así como la utilización del protocolo HTTPS
							para brindar mayor seguridad a “El Usuario”.
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” no se responsabiliza por cualquier falla en las medidas de seguridad
							cuando dicho incumplimiento se deba a circunstancias fuera de su control, caso
							fortuito o fuerza mayor.
		                </p>
		                <p className={"mt-3"}>
		                	<span>IV. PROPIEDAD INTELECTUAL E INDUSTRIAL</span>
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” por sí o por parte cesionaria, es titular de todos los derechos de
							propiedad intelectual e industrial de todo el contenido del “Sitio Web” y aplicaciones
							móviles, entendiendo por este el código fuente que hace posible su funcionamiento,
							así como las imágenes, archivos de audio o video, logotipos, marcas,
							combinaciones de colores, estructuras, diseños, fotografías, ilustraciones, gráficos,
							otros medios visuales, copias, textos, software, títulos, archivos de Onda de choque,
							datos y materiales del mismo, el aspecto y el ambiente, la organización del “Sitio
							Web” y la compilación de los contenidos, datos y los materiales en el “Sitio Web”,
							incluyendo pero no limitado a, cualesquiera derechos de autor, derechos de marca,
							derechos de patente, derechos de base de datos, derechos morales y otras
							propiedades intelectuales y derechos patrimoniales del mismo. 
		                </p>
		                <p className={"mt-3"}>
		                	El uso del “Sitio Web” y aplicaciones móviles no le otorga a “El Usuario” propiedad
							de ninguno de los contenidos, códigos, datos o materiales a los que pueda acceder
							en, o a través del “Sitio Web” y demás elementos que lo distinguen. Serán por
							consiguiente, protegidas por la legislación mexicana en materia de Propiedad
							Intelectual e Industrial, así como por los tratados internacionales aplicables. Por
							consiguiente, queda expresamente prohibida la reproducción, distribución, o
							difusión de los contenidos del “Sitio Web”, con fines comerciales o electorales, en
							cualquier soporte y por cualquier medio sin la autorización de “El Estado”. 
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” que reproduzca o procese información contenida en el “Sitio Web” y
							aplicaciones móviles deberá referir la localización electrónica y fecha en que se
							realizó la consulta de la información, así como observar si tiene derechos
							reservados y respetarlos en términos de las normas relativas a derechos de autor y
							propiedad industrial.
		                </p>
		                <p className={"mt-3"}>
		                	En caso de que “El Usuario” considere que cualquiera de los contenidos del “Sitio
							Web” y aplicaciones móviles, suponga una violación de los derechos de protección
							de la propiedad industrial o intelectual, deberá comunicarlo inmediatamente a “El
							Estado” a través de los datos de contacto disponibles en el propio “Sitio Web”.
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” reconoce que no adquiere ningún derecho de propiedad al descargar
							algún material con derechos de autor a través del “Sitio Web” y aplicaciones móviles.
		                </p>
		                <p className={"mt-3"}>
		                	Si “El Usuario” hace uso indebido del “Sitio Web” o aplicaciones móviles, será sujeto
							a las leyes aplicables locales, nacionales e internacionales.
		                </p>
		                <p className={"mt-3"}>
		                	<span>V. LICENCIA LIMITADA</span>
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” se compromete a respetar los derechos de propiedad intelectual e
							industrial de “El Estado”. 
		                </p>
		                <p className={"mt-3"}>
		                	“El Usuario” podrá visualizar, descargar, almacenar, imprimir, fotocopiar y analizar
							los contenidos e información del “Sitio Web” y aplicaciones móviles, así como los
							Datos Abiertos, desde cualquier dispositivo o computadora, siempre y cuando sea
							exclusivamente para investigación o uso personal.
		                </p>
		                <p className={"mt-3"}>
		                	<span>VI. MODIFICACIONES AL “SITIO WEB” Y APLICACIONES MÓVILES</span>
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado” se reserva la facultad de modificar en cualquier momento y sin previo
							aviso; presentación, contenidos, funcionalidad, trámites, servicios y la configuración
							que pudiera estar contenida en el “Sitio Web” y aplicaciones móviles. En este
							sentido, “El Usuario” reconoce y acepta que “El Estado” se reserva el derecho a su
							sola discreción de cambiar, modificar, añadir o quitar todo o en parte y en cualquier
							momento, los elementos que conforman el “Sitio Web”, aplicaciones móviles o el
							acceso a los mismos. 
		                </p>
		                <p className={"mt-3"}>
		                	El acceso al “Sitio Web” y aplicaciones móviles por parte de “El Usuario” tiene
							carácter libre y por regla general es gratuito sin que “El Usuario” tenga que
							proporcionar una contraprestación para poder disfrutar de ello, salvo en lo referente
							al costo de trámites y servicios que hubiere contratado “El Usuario”.
		                </p>
		                <p className={"mt-3"}>
		                	Se hace del conocimiento de “El Usuario” que “El Estado” podrá administrar o
							gestionar el “Sitio Web” y aplicaciones móviles de manera directa o a través de un
							tercero, lo cual no modifica en ningún sentido lo establecido en el presente Acuerdo.
		                </p>
		                <p className={"mt-3"}>
		                	<span>VII. VIGENCIA</span>
		                </p>
		                <p className={"mt-3"}>
		                	“El Estado”, así como “El Usuario”, reconocen que el presente Acuerdo entrará en
							vigor a partir de su aceptación y navegación en el “Sitio Web” y/o aplicaciones
							móviles.
		                </p>
		                <p className={"mt-3"}>
		                	<span>VIII. LEGISLACIÓN Y JURISDICCIÓN APLICABLE </span>
		                </p>
		                <p className={"mt-3"}>
		                	El Estado se reserva la facultad de presentar las acciones civiles o penales que
							considere necesarias por la utilización indebida del “Sitio Web” y aplicaciones
							móviles, de sus contenidos, trámites y servicios o por el incumplimiento del presente
							Acuerdo.
		                </p>
		                <p className={"mt-3"}>
		                	Este Acuerdo está sujeto a la legislación aplicable en la República Mexicana y el
							Estado de Hidalgo. De surgir cualquier controversia en relación a la interpretación y 
							la aplicación del presente Acuerdo, las partes se someterán a la jurisdicción
							ordinaria de los tribunales que correspondan conforme a derecho en el Estado al
							que se hace referencia.
		                </p>
		                <p className={"mt-3"}>
		                	Por lo anteriormente expuesto, tanto “El Estado” como “El Usuario”, aceptan estar
							conformes y enterados del alcance legal, valor de éste y consecuencias de todas y
							cada una de las estipulaciones del presente Acuerdo, publicado en el “Sitio Web” el
							1 de Mayo de 2019.
		                </p>
		            </div>*/}
				</div>
			</div>
		);
	}
}

export default AvisoPrivacidad;