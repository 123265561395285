import React, { Component } from 'react';
class Banner extends Component {
	render(){
		return (
			<div class="topic-banner" style={{margin:"0px !important",overflow:"hidden",backgroundImage:"url('https://www.gob.mx/cms/uploads/topic/main_image/19/foto3.jpg')",position:"relative",top:"46px"}}>
			</div>
		);
	}
};
export default Banner;
