export const GabineteIndex = {
    gabinete: {
        resume: {
            "name": "Gabinete",
            "description": "",
            "url": "/Dependencias"
        },
        "titulo": "GABINETE",
        "introduccion": "",
        "servidores": [
            {
                "secretaria": "SECRETARÍA DE GOBIERNO",
                "nombre": "Guillermo Olivares Reyna",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/GuillermoOlivaresReyna.png",
                "facebook": "",
                "twitter": "",
                "youtube": "",
                "web": "https://s-gobierno.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://s-gobierno.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://s-gobierno.hidalgo.gob.mx/pag/ruts.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-gobierno.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Organismos Descentralizados",
                        "url": "https://gobierno.hidalgo.gob.mx/OrgDescentralizados"
                    }
                ],
                "ig": "",
                "descripcion": "SEGOB",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "LICENCIADO EN DERECHO",
                "semblanza": [],
                "skills": ["Director de */Organización y Capacitación Campesina/* en el Estado de Hidalgo (1984).", "Asesor adscrito a la */Coordinación de Protección Institucional de la Sala Superior del Tribunal Electoral del Poder Judicial de la Federación/* (2012- 2014).", "Secretario Técnico de la */Comisión de Justicia en la LXIV Legislatura del Senado de la Republica./*"],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_gobierno_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de peticiones relativas a problemáticas públicas con respuesta generada",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 100 },
                            { name: "Resultado 2018", uv: 94.7 }
                        ]
                    },
                    {
                        titulo: "Tiempo promedio en la inscripción de escrituras públicas ante el Instituto de la Función Registral del Estado de Hidalgo",
                        data: [
                            { name: "Base 2016", uv: 45 },
                            { name: "Meta 2018", uv: 29 },
                            { name: "Resultado 2018", uv: 26 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de avance del Plan Anual de Digitalización del Acervo Registral como parte del proceso de modernización",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 50 },
                            { name: "Resultado 2018", uv: 56.7 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de registro de nacimientos realizados de manera oportuna de niñas y niños del estado de Hidalgo",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 94 },
                            { name: "Resultado 2018", uv: 94.5 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de prestadores de servicios turísticos con Carta Compromiso del Código de Conducta Nacional para la Protección de las Niñas, Niños y Adolescentes en el Sector de los Viajes y el Turismo",
                        data: [
                            { name: "Base 2017", uv: 30 },
                            { name: "Meta 2018", uv: 40 },
                            { name: "Resultado 2018", uv: 36 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE HACIENDA",
                "nombre": "María Esther Ramírez Vargas",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/MariaEstherRamirezVargas.png",
                "facebook": "",
                "twitter": "https://twitter.com/JessieBla",
                "youtube": "",
                "web": "https://s-finanzas.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda de la Secretaria",
                        "url": "https://s-finanzas.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Paq. Hacendario 2019",
                        "url": "https://s-finanzas.hidalgo.gob.mx/servicios/paqueteHacendario2019.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-finanzas.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Armonización",
                        "url": "https://s-finanzas.hidalgo.gob.mx/pag/armonizacionContable.html"
                    },
                    {
                        "name": "Servicios",
                        "url": "https://s-finanzas.hidalgo.gob.mx/pag/servicios.html"
                    },
                    {
                        "name": "Notificaciones",
                        "url": "https://portaltributario.hidalgo.gob.mx/Notificaciones/Notificaciones.html"
                    }

                ],
                "ig": "",
                "descripcion": "FINANZAS",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["En el Poder Ejecutivo Federal, */Titular de la Oficina de Representación de la SEDATU/* en Hidalgo.", " En el Poder Judicial, Directora de Administración y Finanzas del */Tribunal Superior de Justicia del Estado de Hidalgo./*", "En el Poder Legislativo, */Secretaria Técnica de Comisión de Hacienda/* en la Sexagésima Legislatura del Congreso del Estado."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_finanzas_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de la deuda pública directa respecto al Producto Interno Bruto Estatal",
                        data: [
                            { name: "Base 2016", uv: 2.47 },
                            { name: "Meta 2018", uv: 2.45 },
                            { name: "Resultado 2018", uv: 1.5 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de avance de los programas de trabajo para mantener un buen perfil crediticio de acuerdo con las calificadoras ",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 50 },
                            { name: "Resultado 2018", uv: 50 }
                        ]
                    },
                    {
                        titulo: "Índice general de avance de Presupuesto Basado en Resultados-Sistema de Evaluación del Desempeño en el ámbito estatal",
                        data: [
                            { name: "Base 2016", uv: 85.5 },
                            { name: "Meta 2022", uv: 95 },
                            { name: "Meta 2030", uv: 100 },
                            { name: "Resultado 2018", uv: 83.7 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "Secretaría de Agricultura y Desarrollo Rural",
                "nombre": "Napoleón González Pérez",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/NapoleonGonzalezPerez.png",
                "facebook": "",
                "twitter": "https://twitter.com/Car_muniz",
                "youtube": "",
                "web": "https://sedagro.hidalgo.gob.mx",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://sedagro.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://sedagro.hidalgo.gob.mx/pag/tramites.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://sedagro.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Convocatorias",
                        "url": "https://sedagro.hidalgo.gob.mx/pag/convocatorias.html"
                    },
                    {
                        "name": "Programas",
                        "url": "https://sedagro.hidalgo.gob.mx/pag/programas.html"
                    },
                    {
                        "name": "Beneficiarios",
                        "url": "https://sedagro.hidalgo.gob.mx/pag/beneficiarios.html"
                    }
                ],
                "ig": "",
                "descripcion": "SEDAGROH",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Actualmente es productor de aguacate hass, lo cual le permite conocer de primera mano las necesidades del estado en el rubro.", "Fue diputado local en la */LX legislatura del Honorable Congreso del estado de Hidalgo./*", "En la parte docente destaca su paso por la */Universidad Anáhuac Ciudad de México/*, campus Sur como catedrático."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_desarrollo_agropecuario_b.svg",
                indicadores: [
                    {
                        titulo: "Índice del PIB del sector primario estatal",
                        data: [
                            { name: "Base 2016", uv: 100 },
                            { name: "Meta 2022", uv: 102 },
                            { name: "Meta 2030", uv: 105 }
                        ]
                    },
                    {
                        titulo: "Total de árboles y especies forestales no maderables que se producen por año en viveros de la SEDAGROH",
                        data: [
                            { name: "Base 2016", uv: 2128774 },
                            { name: "Meta 2018", uv: 2192637 },
                            { name: "Resultado 2018", uv: 1727000 }
                        ]
                    },
                    {
                        titulo: "Producción total de crías de peces",
                        data: [
                            { name: "Base 2017", uv: 6090000 },
                            { name: "Meta 2018", uv: 6272700 },
                            { name: "Resultado 2018", uv: 6272700 }
                        ]
                    },
                    {
                        titulo: "Total de productores que obtuvieron un crédito por gestiones de la SEDAGROH",
                        data: [
                            { name: "Base 2017", uv: 782 },
                            { name: "Meta 2018", uv: 805 },
                            { name: "Resultado 2018", uv: 805 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "Secretaría de Infraestructura Pública y Desarrollo Urbano Sostenible",
                "nombre": "Alejandro Sánchez García",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/AlejandroSanchezGarcia.png",
                "facebook": "https://www.facebook.com/josemenesesarrieta/",
                "twitter": "https://twitter.com/JoseMenesesA",
                "youtube": "",
                "web": "https://s-obraspublicas.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://s-obraspublicas.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Trámites",
                        "url": "https://s-obraspublicas.hidalgo.gob.mx/pag/Tramites.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-obraspublicas.hidalgo.gob.mx/pag/Transparencia.html"
                    },
                    {
                        "name": "Interés",
                        "url": "https://s-obraspublicas.hidalgo.gob.mx/pag/Interes.html"
                    }
                ],
                "ig": "",
                "descripcion": "SOPOT",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Catedrático en la */Universidad Iberoamericana/* en las especialidades de matemáticas, matemáticas financieras y dirección administrativa.", "Coordinador Regional en Molango, y Diputado local al */Congreso del Estado en la LVIII legislatura/* del año 2002 al 2005.", " Director General de la */CAASIM/* en el año 2005.", "Director General de la */Corporación Internacional Hidalgo/* de la SEDECO del año 2006 al 2010."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_bras_publicas_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de cobertura del servicio de alcantarillado sanitario en el estado",
                        data: [
                            { name: "Base 2016", uv: 85.5 },
                            { name: "Meta 2018", uv: 86.8 },
                            { name: "Resultado 2018", uv: 86.3 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de cobertura del servicio de agua potable en el estado",
                        data: [
                            { name: "Base 2016", uv: 87.9 },
                            { name: "Meta 2018", uv: 88.6 },
                            { name: "Resultado 2018", uv: 88.4 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de kilómetros de carreteras en el estado ampliadas y modernizadas",
                        data: [
                            { name: "Base 2016", uv: 8 },
                            { name: "Meta 2018", uv: 12.7 },
                            { name: "Resultado 2018", uv: 12 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de kilómetros de carreteras del estado en regulares y malas condiciones",
                        data: [
                            { name: "Base 2016", uv: 60 },
                            { name: "Meta 2018", uv: 50 },
                            { name: "Resultado 2018", uv: 49 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de infraestructura construida con elementos de sostenibilidad (resiliente) en el estado de Hidalgo",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 10 },
                            { name: "Resultado 2018", uv: 8.7 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE SEGURIDAD PÚBLICA DEL ESTADO DE HIDALGO",
                "nombre": "Salvador Cruz Neri",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/SalvadorCruzNeri.png",
                "facebook": "https://www.facebook.com/MauricioDelmarSaavedra/",
                "twitter": "https://twitter.com/Mauricio_Delmar",
                "youtube": "",
                "web": "https://s-seguridad.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://s-seguridad.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://s-seguridad.hidalgo.gob.mx/pag/tramites.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-seguridad.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Ligas de Interés",
                        "url": "https://s-seguridad.hidalgo.gob.mx/pag/ligasInteres.html"
                    },
                    {
                        "name": "Ceresos",
                        "url": "https://s-seguridad.hidalgo.gob.mx/pag/ceresos.html"
                    }
                ],
                "ig": "",
                "descripcion": "SEGURIDAD",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Ha participado en cursos en el extranjero, como: */“Liderazgo para Altos Mandos”/* impartido por el FBI en Los Ángeles, California. Además del curso: */“Análisis de Inteligencia Policial”/* impartido por el Servicio Secreto de la Embajada de Israel.", "Coordinador Estatal en los estados de: Campeche, Tlaxcala, Morelos y Querétaro.", "Delegado Regional de la */Agencia de Investigación Criminal (PGR)/* en Oaxaca y Zacatecas.", "Titular de la */Dirección General de Asuntos Internos en la Secretaría de Seguridad Ciudadana/* de la Ciudad de México. "],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_seguridad_b.svg",
                indicadores: [
                    {
                        titulo: "Tasa de prevalencia delictiva por cada cien mil habitantes de 18 años y más",
                        data: [
                            { name: "Base 2015", uv: 19245 },
                            { name: "Meta 2022", uv: 18107 },
                            { name: "Meta 2030", uv: 16297 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de la población que ha participado en los programas de prevención social de la violencia y la delincuencia",
                        data: [
                            { name: "Base 2016", uv: 11.5 },
                            { name: "Meta 2018", uv: 17.5 },
                            { name: "Resultado 2018", uv: 29.4 }
                        ]
                    },
                    {
                        titulo: "Avance en la implementación del Sistema Integral de Videovigilancia del Estado de Hidalgo",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 50 },
                            { name: "Resultado 2018", uv: 48 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE CULTURA",
                "nombre": "Neyda Naranjo Baltazar",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/NeydaNaranjoBaltazar.png",
                "facebook": "https://www.facebook.com/",
                "twitter": "https://www.facebook.com/",
                "youtube": "",
                "web": "https://s-cultura.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://s-cultura.hidalgo.gob.mx/agenda.html"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://s-cultura.hidalgo.gob.mx/tramites.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-cultura.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Programas y Proyectos",
                        "url": "https://s-cultura.hidalgo.gob.mx/programas.html"
                    }
                ],
                "ig": "",
                "descripcion": "CULTURA",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Ella es originaria del municipio de Zacualtipán, Licenciada en Nutrición egresada de la Universidad Autónoma del Estado de Hidalgo, con Maestría en Administración Hospitalaria del Centro Universitario Siglo XXI, y Maestría en Gestión Pública por el Instituto Tecnológico Latinoamericano; actualmente es Doctorante en Dirección y Gestión de Organizaciones por el mismo Instituto.",
                    "Cuenta con cursos de Tanatología Existencial por el Instituto de Tanatología Existencial México, de Inteligencia Emocional por la Universidad La Salle Pachuca y de Relaciones Humanas por la Universidad Iberoamericana de Hidalgo; además de Diplomado en Tanatología por la Universidad La Salle Pachuca y de Administración y Gestión Pública.",
                    "En el servicio público ha desempeñado los siguientes puestos: Subsecretaria Desarrollo Social y Humano, Oficial mayor en Zacualtipán (La 1ra mujer en ocupar ese cargo); Subdirectora en DIF municipal Zacualtipán; Enlace Legislativo Regional (2018-2022); y Nutrióloga en la Secretaria de Salud de Hidalgo."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_cultura_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de participación de la población estatal en las actividades artísticas y culturales",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2022", uv: 42 },
                            { name: "Meta 2030", uv: 50 }
                        ]
                    },
                    {
                        titulo: "Cobertura de servicios artísticos y culturales descentralizados a municipios ",
                        data: [
                            { name: "Base 2016", uv: 51.2 },
                            { name: "Meta 2018", uv: 91.4 },
                            { name: "Resultado 2018", uv: 91.6 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE CONTRALORÍA",
                "nombre": "Álvaro Bardales Ramírez",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/AlvaroBardalesRamirez.png",
                "facebook": "",
                "twitter": "",
                "youtube": "",
                "web": "https://s-contraloria.hidalgo.gob.mx",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://s-contraloria.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://s-contraloria.hidalgo.gob.mx/pag/tram_servicios.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-contraloria.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Ligas de Interés",
                        "url": "https://s-contraloria.hidalgo.gob.mx/pag/ligas_interes.html"
                    }
                ],
                "ig": "",
                "descripcion": "CONTRALORÍA",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Contador y Auditor Certificado", "Maestro en Derecho Fiscal.", "Master en Gestión de Calidad, contador y auditor registrado en las instancias de fiscalización.", "Con 30 años de ejercicio profesional independiente."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_contraloria_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de trámites y servicios públicos atendidos según estándares de eficacia",
                        data: [
                            { name: "Base 2016", uv: 32 },
                            { name: "Meta 2018", uv: 80 },
                            { name: "Resultado 2018", uv: 96.5 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de cumplimiento de los compromisos estatales formalizados en programas de trabajo en materia de Contraloría Social",
                        data: [
                            { name: "Base 2016", uv: 28 },
                            { name: "Meta 2018", uv: 75 },
                            { name: "Resultado 2018", uv: 100 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE SALUD",
                "nombre": "Vanesa Escalante Arroyo",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/VanesaEscalanteArroyo.jpg",
                "facebook": "https://es-la.facebook.com/saludhgo/",
                "twitter": "https://twitter.com/salud_hidalgo?lang=es",
                "youtube": "",
                "web": "https://s-salud.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://s-salud.hidalgo.gob.mx/pages/agenda.html"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://s-salud.hidalgo.gob.mx/pages/tramites.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-salud.hidalgo.gob.mx/pages/transparencia.html"
                    },
                    {
                        "name": "Organismos Descentralizados",
                        "url": "https://s-salud.hidalgo.gob.mx/pages/organismos.html"
                    },
                    {
                        "name": "Información relevante",
                        "url": "https://s-salud.hidalgo.gob.mx/pages/informacion.html"
                    }
                ],
                "ig": "",
                "descripcion": "SALUD",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Médica especialista en Medicina de Urgencias, con alta especialidad en urgencias cardiovasculares.", "Maestría en Bioética con */Mención Honorifica y Galardón al mejor desempeño académico/* en 2019, (IPN).", "Especialista en derechos humanos avalado por la */CNDH, y la Universidad Castilla La Mancha/*, España.", "Defensora de los derechos humanos de las personas con discapacidad, mujeres que sufren violencia obstétrica y personas sujetas de investigación en medicina.", "Madre de un niño con síndrome de Down."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_salud_b.svg",
                indicadores: [
                    {
                        titulo: "Índice de atención del paquete garantizado de servicios de promoción y prevención por una mejor salud de la población",
                        data: [
                            { name: "Base 2016", uv: 68 },
                            { name: "Meta 2022", uv: 100 },
                            { name: "Meta 2030", uv: 100 }
                        ]
                    },
                    {
                        titulo: "Abasto de insumos médicos contenidos en el cuadro básico de los Servicios de Salud de Hidalgo",
                        data: [
                            { name: "Base 2016", uv: 30 },
                            { name: "Meta 2018", uv: 90 },
                            { name: "Resultado 2018", uv: 90.05 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de población con carencia por acceso a los servicios de salud",
                        data: [
                            { name: "Base 2016", uv: 17.3 },
                            { name: "Meta 2022", uv: 10 },
                            { name: "Meta 20308", uv: 3 }
                        ]
                    },
                    {
                        titulo: "Usuarios satisfechos con la atención otorgada a través de unidades médicas móviles",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 85 },
                            { name: "Resultado 2018", uv: 87.3 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE DESARROLLO ECONÓMICO",
                "nombre": "Carlos Henkel Escorza",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/CarlosHenkelEscorza.png",
                "facebook": "https://www.facebook.com/SedecoHidalgo/",
                "twitter": "https://twitter.com/Sedeco_Hgo",
                "youtube": "",
                "web": "https://sedeco.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://sedeco.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Servicios",
                        "url": "https://sedeso.hidalgo.gob.mx/pag/tramites.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://sedeso.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Organismos Descentralizados",
                        "url": "https://sedeso.hidalgo.gob.mx/pag/programas.html"
                    }
                ],
                "ig": "",
                "descripcion": "SEDECO",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Fundador de la empresa */HP Constructora S.A. de C.V./*", "Presidente de la */Cámara Mexicana de la Industria de la Construcción/* en Hidalgo", "Representante patronal propietario de */CONCAMIN/* ante el */Consejo de Gobierno del IMSS/* delegación Hidalgo del 2017 al 2020.", "Presidente del */Consejo Coordinador Empresarial de Hidalgo./*"],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_desarrollo_economico_b.svg",
                indicadores: [
                    {
                        titulo: "Creación de nuevos empleos formales",
                        data: [
                            { name: "Base PA2011-2016", uv: 7928 },
                            { name: "Meta PA2017-2022", uv: 10000 },
                            { name: "Resultado 2018", uv: 10000 },
                            { name: "Meta PA2023-2030", uv: 15000 }
                        ]
                    },
                    {
                        titulo: "Crecimiento de la captación de inversión privada nacional y extranjera",
                        data: [
                            { name: "Base PA2011-2016", uv: 6338 },
                            { name: "Meta PA2017-2022", uv: 10000 },
                            { name: "Meta 2018", uv: 15000 },
                            { name: "Resultado 2018", uv: 20031 }
                        ]
                    },
                    {
                        titulo: "Nivel de eficacia en el acompañamiento y políticas de estímulos para la captación de proyectos de inversión",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 35 },
                            { name: "Resultado 2018", uv: 50 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de avance en el conjunto de acciones para incrementar la capacidad instalada de transformación en subestaciones eléctricas de distribución y potencia del estado",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 50 },
                            { name: "Resultado 2018", uv: 50 }
                        ]
                    },
                    {
                        titulo: "Incremento porcentual de teleconsultas otorgadas por los Servicios de Salud de Hidalgo en las unidades hospitalarias",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 2.1 },
                            { name: "Resultado 2018", uv: 1.5 }
                        ]
                    },
                    {
                        titulo: "Porcentaje del número de becas otorgadas a hidalguenses",
                        data: [
                            { name: "Base 2017", uv: 0 },
                            { name: "Meta 2018", uv: 100 },
                            { name: "Resultado 2018", uv: 99 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE MEDIO AMBIENTE Y RECURSOS NATURALES DE HIDALGO",
                "nombre": "Mónica Patricia Mixtega Trejo",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/MonicaPatriciaMixtegaTrejo.png",
                "facebook": "https://www.facebook.com/semarnath/",
                "twitter": "https://twitter.com/medioambiente_H",
                "youtube": "",
                "web": "https://s-medioambiente.hidalgo.gob.mx",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://s-medioambiente.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Verificación Vehicular",
                        "url": "https://s-medioambiente.hidalgo.gob.mx/pag/verificacion.html"
                    },
                    {
                        "name": "Programas",
                        "url": "https://s-medioambiente.hidalgo.gob.mx/pag/programas.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-medioambiente.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Tramites y Servicios",
                        "url": "https://s-medioambiente.hidalgo.gob.mx/pag/tramites.html"
                    }
                ],
                "ig": "",
                "descripcion": "SEMARNATH",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Magistrada del */Tribunal Electoral del Estado de Hidalgo./*", "Secretaria Técnica de la */Comisión de Pueblos y Comunidades indígenas del Congreso del Estado de Hidalgo./*", "Secretaria Proyectista en el */Tribunal Electoral del estado de Hidalgo./*"],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_medio_ambiente_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de regulación ambiental de unidades económicas de competencia estatal",
                        data: [
                            { name: "Base 2016", uv: 26.3 },
                            { name: "Meta 2018", uv: 32.6 },
                            { name: "Resultado 2018", uv: 35.09 }
                        ]
                    },
                    {
                        titulo: "Superficie protegida por esquemas de conservación y manejo sostenible",
                        data: [
                            { name: "Avance 2018", uv: 10.17 },
                            { name: "Meta 2022", uv: 11.9 },
                            { name: "Meta 2030", uv: 13.5 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de avance en las etapas para la elaboración de la Estrategia Estatal de Biodiversidad",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 60 },
                            { name: "Resultado 2018", uv: 56 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de datos recabados que cumplen con la normatividad para la medición de la calidad del aire",
                        data: [
                            { name: "Base 2016", uv: 83.1 },
                            { name: "Meta 2018", uv: 85 },
                            { name: "Resultado 2018", uv: 85.4 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de vehículos con verificación aprobatoria",
                        data: [
                            { name: "Base 2016", uv: 17 },
                            { name: "Meta 2018", uv: 20 },
                            { name: "Resultado 2018", uv: 24.6 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DEL TRABAJO Y PREVISIÓN SOCIAL",
                "nombre": "Oscar Javier González Hernandéz",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/OscarJavierGonzalezHernandez.png",
                "facebook": "https://www.facebook.com/EguiluzTapia/",
                "twitter": "https://twitter.com/EguiluzTapia",
                "youtube": "",
                "web": "https://s-trabajo.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda de la Secretaria",
                        "url": "https://s-trabajo.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Servicios",
                        "url": "https://s-trabajo.hidalgo.gob.mx/pag/servicios.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-trabajo.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Programas y Proyectos",
                        "url": "https://s-trabajo.hidalgo.gob.mx/pag/proyectos.html"
                    }
                ],
                "ig": "",
                "descripcion": "STPS",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Titular de la */Delegación Federal de Secretaría de Gobernación en Hidalgo./*", "Delegado Federal de la */Secretaría del Trabajo/* en el Estado de Baja California Sur.", "Coordinador General de la campaña del LIC. Julio Ramón Menchaca Salazar, para Senador de la República."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_trabajo_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de personas que lograron obtener un empleo a través de los Programas del Servicio Nacional de Empleo Hidalgo",
                        data: [
                            { name: "Base 2016", uv: 86 },
                            { name: "Meta 2018", uv: 87 },
                            { name: "Resultado 2018", uv: 87.1 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de alumnos que concluyeron satisfactoriamente su capacitación para el trabajo",
                        data: [
                            { name: "Base 2016", uv: 80 },
                            { name: "Meta 2018", uv: 84 },
                            { name: "Resultado 2018", uv: 85 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de registro de nacimientos realizados de manera oportuna de niñas y niños del estado de Hidalgo",
                        data: [
                            { name: "Base 2017", uv: 5 },
                            { name: "Meta 2018", uv: 45 },
                            { name: "Resultado 2018", uv: 25.2 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE TURISMO",
                "nombre": "Elizabeth Quintanar Gómez",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/ElizabethQuintanarGomez.png",
                "facebook": "https://www.facebook.com/EduardoJavierBG/",
                "twitter": "https://twitter.com/EduardoJavierBG",
                "youtube": "",
                "web": "https://s-turismo.hidalgo.gob.mx",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://s-turismo.hidalgo.gob.mx/pag/Agenda.html"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://s-turismo.hidalgo.gob.mx/pag/Servicios.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://s-turismo.hidalgo.gob.mx/pag/Transparencia.html"
                    },
                    {
                        "name": "Viajando por Hidalgo",
                        "url": "https://hidalgo.travel/"
                    }
                ],
                "ig": "",
                "descripcion": "TURISMO",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["*/Directora de Turismo Municipal/* de Pachuca del 2003 al 2006.", "Presidenta de la */Asociación de Guías de Turistas Certificados/* del Estado de Hidalgo 2021.", "Creadora y operadora del */Programa de Senderos para Personas con Discapacidad/* en el Parque Nacional el Chico."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_turismo_b.svg",
                indicadores: [
                    {
                        titulo: "Índice de PIB turístico estatal",
                        data: [
                            { name: "Base 2016", uv: 100 },
                            { name: "Meta 2022", uv: 104 },
                            { name: "Meta 2030", uv: 110 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de ocupación hotelera en el estado de Hidalgo",
                        data: [
                            { name: "Base 2017", uv: 51.4 },
                            { name: "Meta 2018", uv: 52.5 },
                            { name: "Resultado 2018", uv: 54 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de prestadores de servicios turísticos certificados ante el Sistema Nacional de Certificación Turística",
                        data: [
                            { name: "Base", uv: 7.8 },
                            { name: "Meta 2018", uv: 11 },
                            { name: "Resultado 2018", uv: 10.6 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de prestadores de servicios turísticos con Carta Compromiso del Código de Conducta Nacional para la Protección de las Niñas, Niños y Adolescentes en el Sector de los Viajes y el Turismo",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 5 },
                            { name: "Resultado 2018", uv: 4.7 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "SECRETARÍA DE EDUCACIÓN PÚBLICA",
                "nombre": "Natividad Castrejón Valdez",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/NatividadCastrejonValdez.png",
                "facebook": "https://www.facebook.com/AtilanoRP/",
                "twitter": "https://twitter.com/atilanorp",
                "youtube": "",
                "web": "http://www.hgo.sep.gob.mx/",
                "MenuSec": [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://www.hgo.sep.gob.mx/Agenda/"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://www.hgo.sep.gob.mx/content/aplicaciones/tramites/"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://www.hgo.sep.gob.mx/transparencia.html"
                    },
                    {
                        "name": "Ligas de Interés",
                        "url": "https://www.hgo.sep.gob.mx/"
                    }
                ],
                "ig": "",
                "descripcion": "SEPH",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Por más de 20 años impartió cursos, talleres y conferencias en el sector empresarial y en escuelas públicas y privadas.", "Fundador y Director de la */Universidad Humanista Hidalgo./*", "Empresario y asesor empresarial y Político."],
                "icon": "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_educación_b.svg",
                "indicadores": [
                    {
                        titulo: "Porcentaje de población con carencia por rezago educativo ",
                        data: [
                            { name: "Base 2014", uv: 19.1 },
                            { name: "Meta 2022", uv: 14.1 },
                            { name: "Meta 2030", uv: 8 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de abandono escolar en educación secundaria",
                        data: [
                            { name: "Base 2016", uv: 2.8 },
                            { name: "Meta 2018", uv: 2.6 },
                            { name: "Resultado 2018", uv: 3.2 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de cobertura en Educación Superior",
                        data: [
                            { name: "Base 2016", uv: 37.8 },
                            { name: "Meta 2018", uv: 40 },
                            { name: "Resultado 2018", uv: 41.1 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de avance en la instalación y funcionamiento de la Universidad Digital del Estado de Hidalgo UNIDEH",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 40 },
                            { name: "Resultado 2018", uv: 38 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de Planteles Tecnológicos públicos de Educación Media Superior con opción educativa de formación Dual",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 12.7 },
                            { name: "Resultado 2018", uv: 12.7 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de acciones educativas innovadoras emitidas por las academias estatales, aplicadas al entorno escolar",
                        data: [
                            { name: "Base 2017", uv: 0 },
                            { name: "Meta 2018", uv: 3 },
                            { name: "Resultado 2018", uv: 4.1 }
                        ]
                    }
                ]
            },
            {
                "secretaria": "Secretaría de Bienestar e Inclusión Social",
                "nombre": "Ricardo Gómez Moreno",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/RicardoGomezMoreno.png",
                "facebook": "#",
                "twitter": "#",
                "youtube": "#",
                "web": "https://sedeso.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://sedeso.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Trámites y Servicios",
                        "url": "https://sedeso.hidalgo.gob.mx/pag/tramites.html"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://sedeso.hidalgo.gob.mx/pag/transparencia.html"
                    },
                    {
                        "name": "Programas Sociales",
                        "url": "https://sedeso.hidalgo.gob.mx/pag/programas.html"
                    }
                ],
                "ig": "",
                "descripcion": "SEDESO",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": [
                    "Licenciado en Derecho, diplomado en Contabilidad y Presupuesto, así como en Derecho Electoral.",
                    "Dedicado a la política desde 1997, en el servicio público fue asesor legislativo en el Congreso de la Unión y en el Congreso del Estado de Hidalgo. Asesor presupuestario en los ayuntamientos municipales de Guanajuato y Jalisco.",
                    "En 2022, en la administración del gobernador Julio Menchaca Salazar, fue designado como Subsecretario de Desarrollo Político de la Secretaría de Gobierno del Estado de Hidalgo.",
                    "A partir de Julio de 2024 recibió nombramiento como titular de la Secretaría de Bienestar e Inclusión Social."
                ],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_desarrollo_social_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de incremento en la atención de migrantes y sus familias",
                        data: [
                            { name: "Base 2017", uv: 7 },
                            { name: "Meta 2018", uv: 14.5 },
                            { name: "Resultado 2018", uv: 15.7 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de adultos mayores de 60 años y más, apoyados por el Programa para la Atención de las Personas Adultas Mayores con acciones gerontológicas",
                        data: [
                            { name: "Base 2016", uv: 5.2 },
                            { name: "Meta 2018", uv: 6.3 },
                            { name: "Resultado 2018", uv: 5.9 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de artesanos atendidos con apoyos gubernamentales para capacitación, producción y comercialización de sus artesanías",
                        data: [
                            { name: "Base 2016", uv: 15.9 },
                            { name: "Meta 2018", uv: 18 },
                            { name: "Resultado 2018", uv: 15.3 }
                        ]
                    },
                    {
                        titulo: "Porcentaje de viviendas atendidas que se encuentran con al menos un factor de incidencia en las carencias por calidad y espacios en la vivienda y/o servicios básicos de la vivienda en el estado de Hidalgo",
                        data: [
                            { name: "Base 2017", uv: 0 },
                            { name: "Meta 2018", uv: 3.22 },
                            { name: "Resultado 2018", uv: 3.22 }
                        ]
                    }

                ]
            },
            {
                "secretaria": "SECRETARÍA DE MOVILIDAD Y TRANSPORTE",
                "nombre": "Lyzbeth Robles Gutiérrez",
                "imagen": "https://cdn.hidalgo.gob.mx/transicion/gobierno/gabinete/gabinetedefault.png",
                "landscape": "https://cdn.hidalgo.gob.mx/transicion/gobierno/assets/rectanglewine.png",
                "imagenGabinete": "https://cdn.hidalgo.gob.mx/gobierno/images/gabinete/LyzbethRoblesGutierrez.png",
                "facebook": "",
                "twitter": "https://twitter.com/joseguevaram",
                "youtube": "",
                "web": "https://movilidadytransporte.hidalgo.gob.mx/",
                MenuSec: [
                    {
                        "name": "Agenda del Secretario",
                        "url": "https://movilidadytransporte.hidalgo.gob.mx/pag/agenda.html"

                    },
                    {
                        "name": "Sistema de Transporte Convencional",
                        "url": "https://stch.hidalgo.gob.mx/"
                    },
                    {
                        "name": "Sistema Integrado de Transporte Masivo",
                        "url": "https://sitmah.hidalgo.gob.mx/"
                    },
                    {
                        "name": "Transparencia",
                        "url": "https://movilidadytransporte.hidalgo.gob.mx/pag/transparencia.html"
                    }
                ],
                "ig": "",
                "descripcion": "MOVILIDAD Y TRANSPORTE",
                "imgwellcome": "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png",
                "grado": "",
                "semblanza": [],
                "skills": ["Ha sido abogada postulante, brindando asesoría legal en las materias Civil, Familiar, Mercantil, y Amparo, entre otras.", "Carrera Judicial. Ejerció diversos cargos en el */Poder Judicial del Estado de Hidalgo/*, destacando el de Jueza de Primera Instancia, impartiendo justicia en las materias Civil, Familiar y Mercantil.", "Asesora Parlamentaria en el */Senado de la República/*, teniendo a su cargo, entre otras actividades, la elaboración de proyectos de ley."],
                icon: "https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_movilidad_b.svg",
                indicadores: [
                    {
                        titulo: "Porcentaje de vehículos de transporte público convencional con inspección vehicular aprobada",
                        data: [
                            { name: "Base 2016", uv: 61 },
                            { name: "Meta 2018", uv: 67 },
                            { name: "Resultado 2018", uv: 63 }
                        ]
                    },
                    {
                        titulo: "Percepción de calidad en el servicio que oferta a los usuarios del Sistema de Transporte Masivo",
                        data: [
                            { name: "Base 2016", uv: 0 },
                            { name: "Meta 2018", uv: 85 },
                            { name: "Resultado 2018", uv: 74 }
                        ]
                    }
                ]
            }
        ]
    }
}