export const Org = {
	organos:{
		resume:{
            name: "ORGANISMOS DESCENTRALIZADOS",
            description: "Son entidades estatales creadas por ley o decreto del Congreso del Estado, con personalidad jurídica y patrimonio propio, cualquiera que sea la estructura legal que adopte, constituida con fondos o bienes provenientes de la Administración Pública Estatal; su objetivo es la presentación de un servicio público o social, la investigación científica y tecnológica y la obtención o aplicación de recursos para fines de asistencia o seguridad social.",
            url: "/OrgDescentralizados"
        },
		titulo:"ORGANISMOS DESCENTRALIZADOS",
		introduccion:[
			"Son entidades estatales creadas por ley o decreto del Congreso del Estado, con personalidad jurídica y patrimonio propio, cualquiera que sea la estructura legal que adopte, constituida con fondos o bienes provenientes de la Administración Pública Estatal; su objetivo es la presentación de un servicio público o social, la investigación científica y tecnológica y la obtención o aplicación de recursos para fines de asistencia o seguridad social.",
			"Dentro del Gobierno del Estado de Hidalgo se cuenta con los siguientes, agrupados por sectores administrativos:"
		],
		descentralizado:[ 
			{
				"secretaria":"Secretaría de Gobierno",
				"list":[
					{
						"name":"Instituto Hidalguense de las Mujeres",
						"description":"Tiene el propósito de coordinar y ejecutar acciones orientadas a promover el desarrollo integral de las mujeres a fin de garantizar su plena participación en la vida económica, política, cultural y social de la entidad.",
						"url":"https://mujeres.hidalgo.gob.mx/",
						"logo":"ihm_logo.png"
					},	
					{
						"name":"Ciudad de las Mujeres",
						"description":"bla bla bla bla bla bla bla bla bla bla bla bla ",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Hidalguense para el Desarrollo Municipal",
						"description":"Ayuda a los municipios",
						"url": "https://indemun.hidalgo.gob.mx/",
						"logo":"ihm_logo.png"
					},
					{
						"name":"Centro de Justicia para Mujeres del Estado de Hidalgo",
						"description":"bla bla bla bla bla bla bla bla bla bla bla bla ",
						"url": "https://justiciamujeres.hidalgo.gob.mx/",
						"logo":"ihm_logo.png"
					
					}
				]
			},
			{
			"secretaria":"Secretaría Ejecutiva de la Política Pública Estatal",
				"list":[
					
				]
			},
			{
			"secretaria":"Secretaría de Finanzas Públicas",
				"list":[
					{
						"name":"Instituto Catastral del Estado de Hidalgo",
						"description":"Normar y ejercer la función catastral de manera coordinada con los Municipios, respetando plenamente su autonomía en términos del Artículo 115 Constitucional",
						"url": "https://catastro.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto para el Financiamiento del Estado de Hidalgo",
						"description":"",
						"url": "https://financiamiento.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría de Desarrollo Social",
				"list":[
					{
						"name":"Instituto Hidalguense de la Juventud",
						"description":"",
						"url": "https://juventud.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto para la Atención de las y los Adultos Mayores",
						"description":"Proporcionar a las y los adultos mayores atención gerontológica integral, basada en el Modelo Hidalgo de Atención Gerontológica.",
						"url": "https://adultosmayores.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Dirección General de Atención a Migrantes",
						"description":"Proporcionar a las y los migrantes hidalguenses atención dentro y fuera del país.",
						"url": "https://migrantes.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría de Obras Públicas",
				"list":[
					{
						"name":"Comisión Estatal de Vivienda",
						"description":"Encargado de promover acciones de vivienda de calidad y sustentabilidad a las familias hidalguenses, preferentemente a las de más bajos ingresos, contribuyendo con ello a su desarrollo humano y a un mejor habitat.",
						"url": "https://cevi.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Comisión Estatal del Agua y Alcantarillado",
						"description":"",
						"url": "https://ceaa.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Comisión de Agua y Alcantarillado de Sistemas Intermunicipales",
						"description":"",
						"url": "https://caasim.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Comisión de Agua y Alcantarillado del Sistema del Valle del Mezquital",
						"description":"",
						"url": "https://caasvam.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto de Vivienda, Desarrollo Urbano y Asentamientos Humanos",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Sistema de Autopistas, Servicios Conexos y Auxiliares del Estado de Hidalgo",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Hidalguense de la Infraestructura Física Educativa",
						"description":"",
						"url": "https://inhife.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Centro Estatal de Maquinaria para el Desarrollo",
						"description":"Encargado de atender los requerimientos técnicos y administrativos de servicio de maquinaria y equipo del Estado, beneficiando el desarrollo integral de los Hidalguenses a partir de la realización de obras de tipo social y atención a contingencias.",
						"url": "https://maquinaria.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}]
			},
			{
			"secretaria":"Secretaría de Desarrollo Económico",
				"list":[
					{
						"name":"Corporación Internacional Hidalgo",
						"description":"",
						"url": "https://coinhi.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Hidalguense de Competitividad Empresarial",
						"description":"",
						"url": "https://ihce.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Corporación de Fomento de Infraestructura Industrial",
						"description":"",
						"url": "https://cofoin.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Agencia Estatal de Energía de Hidalgo",
						"description":"",
						"url": "https://cefaen.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Agencia de Desarrollo Valle de Plata",
						"description":"",
						"url": "https://agenciadedesarrollovalledeplata.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría de Desarrollo Agropecuario",
				"list":[
					{
						"name":"Consejo Hidalguense del Café",
						"description":"",
						"url": "https://concafe.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría de Turismo",
				"list":[
					{
						"name":"Operadora de Eventos del Estado de Hidalgo",
						"description":"",
						"url": "https://opeventos.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Administradora del Teatro de la Ciudad San Francisco y Parque David Ben Gurións",
						"description":"",
						"url": "https://teatros.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría de Educación Pública",
				"list":[
					{
						"name":"Instituto Hidalguense de Educación",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Hidalguense de Educación para Adultos",
						"description":"",
						"url": "https://ihea.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Politécnica de Tulancingo",
						"description":"",
						"url": "https://www.upt.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Politécnica de Pachuca",
						"description":"",
						"url": "https://www.upp.edu.mx/main/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Politécnica de Francisco I. Madero",
						"description":"",
						"url": "https://www.upfim.edu.mx/index.html",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Politécnica de Huejutla",
						"description":"",
						"url": "https://uphuejutla.edu.mx/wordpress/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Politécnica Metropolitana de Hidalgo",
						"description":"",
						"url": "https://www.upmetropolitana.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Tecnológica de Tula-Tepeji",
						"description":"",
						"url": "https://uttt.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Tecnológica de Tulancingo",
						"description":"",
						"url": "https://utec-tgo.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Tecnológica de la Huasteca Hidalguense",
						"description":"",
						"url": "https://www.uthh.edu.mx/2015/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Tecnológica del Valle del Mezquital",
						"description":"",
						"url": "https://www.utvm.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Tecnológica de la Sierra Hidalguense",
						"description":"",
						"url": "https://www.utsh.edu.mx/php/inicio.php",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Tecnológica de la Zona Metropolitana del Valle de México",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Tecnológico Superior de Huichapan",
						"description":"",
						"url": "https://www.iteshu.edu.mx/iteshu/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Tecnológico Superior del Occidente del Estado de Hidalgo",
						"description":"",
						"url": "https://www.itsoeh.edu.mx/front/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Tecnológico Superior del Oriente del Estado de Hidalgo",
						"description":"",
						"url": "https://www.itesa.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Intercultural del Estado de Hidalgo",
						"description":"",
						"url": "https://universidadinterculturalhgo.edu.mx/wp/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Colegio de Bachilleres del Estado de Hidalgo",
						"description":"",
						"url": "https://www.cobaeh.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Bachillerato del Estado de Hidalgo",
						"description":"",
						"url": "https://www.bachillerato-hgo.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Colegio de Estudios Científicos y Tecnológicos del Estado de Hidalgo",
						"description":"",
						"url": "https://www.cecyteh.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Colegio de Educación Profesional Técnica del Estado de Hidalgo",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"El Colegio del Estado de Hidalgo",
						"description":"",
						"url": "https://www.elcolegiodehidalgo.edu.mx/inicio/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Hidalguense de Financiamiento a la Educación Superior",
						"description":"",
						"url": "https://ihfes.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Instituto Hidalguense del Deporte",
						"description":"",
						"url": "https://deporte.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Tecnológica Minera de Zimapán",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Politécnica de la Energía",
						"description":"",
						"url": "https://www.upenergia.edu.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Universidad Tecnológica de Mineral de la Reforma",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Radio y Televisión de Hidalgo",
						"description":"",
						"url": "https://radioytelevision.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}

				]
			},
			{
			"secretaria":"Secretaría de Salud",
				"list":[
					{
						"name":"Servicios de Salud de Hidalgo",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Régimen Estatal de Protección Social en Salud del Estado de Hidalgo",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría de Seguridad Pública",
				"list":[
					{
						"name":"Policía Industrial Bancaria",
						"description":"",
						"url": "https://policiaindustrialbancaria.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría del Trabajo y Previsión Social",
				"list":[
					{
						"name":"Instituto de Capacitación para el Trabajo del Estado de Hidalgo",
						"description":"",
						"url": "https://icathi.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría de Movilidad y Transporte",
				"list":[
					{
						"name":"Sistema Integrado de Transporte Masivo de Hidalgo",
						"description":"",
						"url": "https://sitmah.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Sistema de Transporte Convencional de Hidalgo",
						"description":"",
						"url": "https://stch.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			},
			{
			"secretaria":"Secretaría de Cultura",
				"list":[
					{
						"name":"Escuela de Música del Estado de Hidalgo",
						"description":"",
						"url": "#",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Consejo Estatal para la Cultura y las Artes de Hidalgo",
						"description":"",
						"url": "https://cultura.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			}
			,
			{
			"secretaria":"Organismos no sectorizados",
				"list":[
					{
						"name":"Secretaría Técnica del Sistema Estatal Anticorrupción de Hidalgo (STSEAH)",
						"description":"",
						"url": "https://sistemaanticorrupcion.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Sistema Estatal para el Desarrollo Integral de la Familia Hidalgo (DIF)",
						"description":"",
						"url": "https://dif.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Comisión para el Desarrollo Sostenible de los Pueblos Indígenas (CEDSPI)",
						"description":"",
						"url": "https://cedspi.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Consejo de Ciencia, Tecnología e Innovación de Hidalgo (CITNOVA)",
						"description":"",
						"url": "https://citnova.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":" Museo Interactivo para la Niñez y la Juventud Hidalguense El Rehilete  (Museo El Rehilete)",
						"description":"",
						"url": "https://rehilete.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					},
					{
						"name":"Consejo Rector de Pachuca Cd. del Conocimiento y la Cultura (CRPCCYC)",
						"description":"",
						"url": "https://ciudaddelconocimiento.hidalgo.gob.mx/",
						"logo":"hidalgo.jpg"
					}
				]
			}
		]
	}
}	