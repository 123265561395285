import React,{Component} from 'react';

const pub = {
    pasos:[
      {
        "title":"Beneficiarios",
        "list":[
          {
            content: 'Son las personas que cumplen los requisitos de la normatividad correspondiente, formando parte de la población atendida por los programas sociales;'
          }
        ]
      },
      {
        "title":"Catálogos",
        "list":[
          {
            content: 'Es el listado general de programas, subprogramas y proyectos institucionales relacionados con la promoción del desarrollo social y humano, realizado por cada instancia ejecutora de programas sociales, el cual incluirá la descripción de los mismos;'
          }
        ]
      },
      {
        "title":"Padrón",
        "list":[
          {
            content: 'Es el instrumento que contiene la información oficial actualizada, sistematizada e integral sobre los beneficiarios y apoyos de los diversos programas de desarrollo social que operan el Estado de Hidalgo; que incluye a las personas habitantes del Estado, atendidas por los programas públicos, Municipales, Estatales, Federales, privados y mixtos de desarrollo social;'
          }
        ]
      },
      {
        "title":"Reglas",
        "list":[
          {
            content: 'Es la normatividad aplicada por las Dependencias u Organismos para la ejecución de sus programas;'
          }
        ]
      },
      {
        "title":"Secretaría",
        "list":[
          {
            content: 'Es la Secretaría de Desarrollo Social del Gobierno del Estado de Hidalgo como Dependencia coordinadora y administradora del Padrón de Beneficiarios.'
          }
        ]
      }
    ]
} 
class pub1 extends Component {
 
  render(){
    return (
      <div id="contentglobal" className="pub">
        <div>
          <center>
              <h1 style={{
                    paddingTop: '2%',
                    textAlign: 'center',
                    letterSpacing: '15px',
                    fontWeight: 'bold',
                    fontSize: '4vw',
                    lineHeight: '50px',
                    border: '0px',
                    borderBottom: '2px #000 solid',
                    width: '50vw'
                  }}
              >PADRÓN ÚNICO</h1>
            </center>
            <center>
              <h1 style={{
                    letterSpacing: '15px',
                    fontWeight: 'bold',
                    fontSize: '3vw',
                    lineHeight: '50px',
                    border: '0px'
                  }}
              >DE BENEFICIARIOS</h1>
            </center>
        </div>
        <div className="introduccion">
          Sus disposiciones son de orden público e interés Social y su aplicación corresponde al
          Ejecutivo Estatal a través de la Secretaría de Desarrollo Social, así como de las Dependencias 
          y Entidades de la Administración Pública Estatal y Municipal en el Estado, en el ámbito de sus
          respectivas competencias.
          A continuación damos a conocer algunos conceptos contenidos en el:
          <div>
            <a href="http://intranet.e-hidalgo.gob.mx/NormatecaE/docto.taf?Tar=6&IdD=1679" target="_blank">ACUERDO QUE CONTIENE LOS LINEAMIENTOS GENERALES PARA LA INTEGRACIÓN Y ACTUALIZACIÓN DEL PADRÓN DE BENEFICIARIOS DEL ESTADO DE HIDALGO</a>
          </div>
          <div>
            Conozca más
          </div>
        </div>
        
        <div className="Timeline">
         {this._renderTime()}
        </div>
      </div>
      );
  }
  _renderTime = () =>{
    return pub.pasos.map(({title,list},i)=>{
      const organismos = (list) =>{
        return list.map((text)=>{
          return(
            <li>{text.content}</li>
          );
        });
      };
      let linea = "rgba(9,72,102,1)";
      if(pub.pasos.length===(i+1)){
        linea = "white";
      }
      
      return(
        <div className={"skillrow"}>
          <div className="circulo"/>
          <div className={"line"+(i+1)} style={{ position: 'absolute', height: "calc(100% + 25px)",width: "3px", background: linea, left: "10px"}}/>
            <h6 style={{fontWeight:"bold",width: "250px",paddingLeft:"25px",marginBottom: "0px"}}>{title}</h6>
            <hr/>
            <ul>
              {organismos(list)}
            </ul>
        </div>
      );
    });
  };
}

export default pub1;