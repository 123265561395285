const Reducer = (state = { title:"", clusterSelector: 0 ,click: false}, action) => {
	switch(action.type){
		case "GLOBAL_STATE":
		console.log(action.data);
			return {...state, ...action.data}
		break;
		default: 
			return state;
		break;
	}
};

const Action = (data) => {
	return {
		type: "GLOBAL_STATE",
		data: data
	}
};

export default {
	states: {
		stateGlobal: Reducer
	},
	actions: {
		setStateGlobal: Action
	}
}