import React, { Component } from 'react';
class Footer extends Component {
  

  render(){
    const currentYear = new Date().getFullYear();
    return (
      <footer className="main-footer"> 
        <div className="container-fluid">
          <div className="row first-row-footer">
            <div className="col-md-9 col-12">
              <div className="footer-logo-tels">
                <div className="footer-logo">
                  <img alt="logotipo gob.mx" src="https://cdn.hidalgo.gob.mx/transicion/logo_hgo_2019.png" />
                </div>
                <div className="tels-footer">
                  <div>
                    <p><span>911</span></p>
                    <p>Emergencia</p>
                  </div>
                  <div>
                    <p><span>089</span></p>
                    <p>Denuncia Anónima</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="contacto">
                <h6><a href="/AvisoPrivacidad">Aviso de privacidad</a></h6>
                <h6>Contacto:</h6>
                <p>
                  Plaza Juárez s/n Col. Centro<br/>
                  Pachuca de Soto, Hidalgo, México
                </p>
              </div>
            </div>
          </div>
          <hr/>
          <div className="row second-row-footer">
            <div className="col-sm-9 col-12">
              <p>Copyrights © {currentYear} Todos los derechos reservados por Hidalgo de la Transformación</p>
            </div>
            <div className="col-sm-3 col-12">
                <div className="social-media">
                  <a href="https://www.facebook.com/gobhidalgo" target="_blanc">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="https://twitter.com/gobiernohidalgo" target="_blanc">
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};
export default Footer;
