import React, { Component } from 'react';
import Router from './Router';
import Header from './Components/Header';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
      	<Header/>
        <Navbar/>
        <div className="inner">
          <Router />
          <Footer/>
        </div>
      </div>
    );
  }
}

export default App;

