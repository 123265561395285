import React,{Component} from 'react';
import { GabineteIndex } from '../index/gabinete';
import {Link} from 'react-router-dom';
import Gabinete from './Gabinete';
// import parse from 'html-react-parser';

class GabineteDetalle extends Component {
  state = { progress: 50 }

  _showw = () => {
    let Menu = document.getElementById('MS')
    if (Menu.style.display == '' || Menu.style.display == 'none' ) {
      Menu.style.display = 'block';
    }
    else{
      Menu.style.display = 'none';
    }
  };

  render() {
    return (
      <div className="GabineteDetalle">
        {this._renderGabineteDetalle()}
      </div>
    );

  }
  _renderGabineteDetalle = () => {
    const parrafo = (texto) => {
      return texto.map((text) => {
        return(
          <p>{text}</p>
        );
      });
    }; 

    

    const ligas = (MenuSec) =>{
        return MenuSec.map((text)=>{
          return(
            <a href={text.url} target="_blank" style={{color:"black"}}>{text.name}</a>
          );
        });
      };
    const render_skills = (skills) => {
      return skills.map((item) =>{
        let itemparsed = item.split("*");
        itemparsed = itemparsed.map((texts) => {
          if (texts.indexOf("/") > -1) {
            return <b>{ texts.split('/').join("") }</b>;
          } 
          return texts;
        });

        return <li>{itemparsed}</li>
      });
    };
    return GabineteIndex.gabinete.servidores.map(({titulo,nombre,imagen,imagenGabinete, landscape,facebook,twitter,youtube,web,MenuSec,grado,semblanza,skills,icon,secretaria},i)=>{    
      if(this.props.match.params.nombre === nombre){
        return(
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 col-12">
                  <div className="Experiencia">
                    <h2 className="nombre-title">{nombre}</h2>
                    <h6>{secretaria}</h6>
                    <div className="ExperienciaTimeline">
                      <ul>
                        {render_skills(skills)}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12 frame-foto">
                  <div className="secretario">
                    <div style={{backgroundImage: "url(" + imagenGabinete + ")"}} alt="secretario" className="secretario_img"/>
                    <div className={"secretario_name"}>
                      {nombre}
                    </div>
                    {/*<div className={"secretario_grado"}>
                      {grado}
                    </div>
                    <div  className="iconoCircle" style={{backgroundImage:"url("+icon+")", backgroundSize: '70% auto', backgroundPosition: 'center',    backgroundRepeat: 'no-repeat'}}>
                    </div>
                    <div className={"secretario_icons"}>
                      <button className="icons" onClick={()=>window.open(facebook)}><i class="fab fa-facebook-square"></i></button>
                      <button className="icons" onClick={()=>window.open(twitter)}><i class="fab fa-twitter"></i></button>
                      {nombre === "Alejandro Enciso Arellano"? null:<button className="web" onClick={()=>window.open(web)}><i class="fas fa-globe"></i></button>}
                    </div>*/}
                    <div className={"secretario_cargo"}>
                        <p>TITULAR DE: {secretaria}</p>
                      {/*<button onClick={this._showw}><i class="fas fa-arrow-circle-down coll"></i></button>*/}
                    </div>
                    {/*<div className="MenuSec" id="MS">
                      {ligas(MenuSec)}
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  }
}

export default GabineteDetalle;