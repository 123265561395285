export const DependenciasIndex = {
    dependencias:{
        resume:{
            name: "Dependencias",
            description: "Dependencias del Poder Ejecutivo que constituyen la Administración Pública Central",
            url: "/Dependencias"
        },
        titulo:"DEPENDENCIAS ESTATALES",
        introduccion:"Dependencias del Poder Ejecutivo que constituyen la Administración Pública Central",
        "secretarias":[
            {
                "nombre": "Secretaría de Gobierno",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Gobierno/Gobierno_vino_h.svg",
                "facebook": "https://www.facebook.com/gobhidalgo/",
                "twitter": "https://twitter.com/gobiernohidalgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-gobierno.hidalgo.gob.mx/",
                direccion:"Plaza Juárez s/n Col. Centro, Pachuca de Soto, Hidalgo",
                cp:"C.P. 42000",
                tel:"+52 (771) 71 76000",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_gobierno_b.svg"
            }
            //,
            // {
            //     "nombre": "Secretaría Ejecutiva de la Política Pública",
            //     "img": "",
            //     "facebook": "https://www.facebook.com/SecretariaEjecutivaHGO/",
            //     "twitter": "https://twitter.com/SEPPEHidalgo",
            //     "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
            //     msg:"https://www.hidalgo.gob.mx/escribele",
            //     "url":"https://secretariaejecutiva.hidalgo.gob.mx/",
            //     direccion:"Palacio de Gobierno, 4to. Piso, Col. Centro, Pachuca de Soto, Hidalgo",
            //     cp:"",
            //     tel:"+52 (771) 71 760 00",
            //     icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_ejecutiva_b.svg"
            // }
            ,
            {
                "nombre": "Secretaría de Hacienda",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Hacienda/Hacienda_vino_h.svg",
                "facebook": "https://www.facebook.com/SecretariaDeFinanzasHidalgo/",
                "twitter": "https://twitter.com/FinanzasHidalgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://hacienda.hidalgo.gob.mx/",
                direccion:"Plaza Juárez S/N, Palacio de Gobierno Primer Piso Col. Centro, Pachuca de Soto, Hidalgo",
                cp:"C.P. 42000. ",
                tel:"+52 (771) 71 7 62 07",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_finanzas_b.svg"
            },
            {
                "nombre": "Secretaría de Bienestar e Inclusión Social",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Bienestar/Bienestar_vino_h.svg",
                "facebook": "https://www.facebook.com/Daniel-Jim%C3%A9nez-Rojo-857080744428584/?fref=ts",
                "twitter": "https://twitter.com/danielrolandoj",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://sebiso.hidalgo.gob.mx",
                direccion:"Blvd. Everardo Márquez No. 101, Plaza Bella, Col. Periodistas, Pachuca de Soto, Hidalgo",
                cp:"C.P. 42060",
                tel:"+52 (771) 714 6521",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_desarrollo_social_b.svg"
            },
            {
                "nombre": "Secretaría de Infraestructura Pública y Desarrollo Urbano Sostenible",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Infraestructura/Infraestructura_vino_h.svg",
                "facebook": "https://www.facebook.com/SOPOTHidalgo/",
                "twitter": "https://twitter.com/SOPOTHIDALGO",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-infraestructura.hidalgo.gob.mx/",
                direccion:"Ex-Centro Minero Edif. 2B, 1er. piso, Pachuca de Soto, Hidalgo",
                cp:"",
                tel:"+52 (771) 771 71 7 80 00",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_bras_publicas_b.svg"
            },
            {
                "nombre": "Secretaría de Desarrollo Económico",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Economia/Economia_vino_h.svg",
                "facebook": "https://www.facebook.com/SedecoHidalgo/",
                "twitter": "https://twitter.com/Sedeco_Hgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://sedeco.hidalgo.gob.mx/",
                direccion:"Camino Real de la Plata #305 Fraccionamiento, Zona Plateada, Pachuca de Soto, Hidalgo",
                cp:"CP. 42084",
                tel:"(01) 771 688 60 26",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_desarrollo_economico_b.svg"
            },
            {
                "nombre": "Secretaría de Medio Ambiente y Recursos Naturales",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/MedioAmbiente/MedioAmbiente_vino_h.svg",
                "facebook": "https://www.facebook.com/semarnath/",
                "twitter": "https://twitter.com/medioambiente_H",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-medioambiente.hidalgo.gob.mx/",
                direccion:"Calle Vicente Segura No. 100, Col. Adolfo Lopéz Mateos, Parque Ecológico Cubitos, Pachuca de Soto, Hidalgo",
                cp:"",
                tel:"(771) 133 63 20, 107 013 04, 133 63 51",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_medio_ambiente_b.svg"
            },
            {
                "nombre": "Secretaría de Agricultura y Desarrollo Rural",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Campo/Campo_vino_h.svg",
                "facebook": "https://www.facebook.com/HIDALGO.SEDAGRO/",
                "twitter": "https://twitter.com/SEDAGRO_HIDALGO",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://sedagro.hidalgo.gob.mx/",
                direccion:"Carretera México-Pachuca Km. 93.5 Col. Venta Prieta, Ex-Centro Minero Edificio 1A, Pachuca de Soto, Hidalgo",
                cp:"CP 42080",
                tel:"+52 (771) 71 78 000",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_desarrollo_agropecuario_b.svg"
            },
            {
                "nombre": "Secretaría de Turismo",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Turismo/Turismo_vino_h.svg",
                "facebook": "https://www.facebook.com/SECTURHidalgo/",
                "twitter": "https://twitter.com/secturhidalgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-turismo.hidalgo.gob.mx/",
                direccion:"Blvd. Camino Real de la Plata No. 340, Zona Plateada, Pachuca de Soto, Hidalgo",
                cp:"CP 42084",
                tel:"+52 (771) 718 4489 y +52 (771) 133 0645",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_turismo_b.svg"
            },
            {
                "nombre": "Secretaría de Contraloría",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Contraloria/Contraloria_vino_h.svg",
                "facebook": "https://www.facebook.com/SecretariaContraloriaHgo/",
                "twitter": "https://twitter.com/Contraloria_Hgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-contraloria.hidalgo.gob.mx/",
                direccion:"Camino Real de la Plata # 301, tercer piso Fracc. Zona Plateada, C.P. 42084 Pachuca de Soto, Hidalgo",
                cp:"C.P. 42088",
                tel:"+52 01 (771) 713 6803",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_contraloria_b.svg"
            },
            {
                "nombre": "Secretaría de Educación Pública",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Educacion/Educacion_vino_h.svg",
                "facebook": "https://www.facebook.com/SecretariaDeEducacionPublicaHidalgo/",
                "twitter": "https://twitter.com/@SEPHidalgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://seph.gob.mx/",
                direccion:"Dirección: Blvd. Felipe Ángeles s/n, Col. Venta Prieta, Pachuca de Soto, Hidalgo",
                cp:"C.P. 42083 ",
                tel: "(01 771) 71 7 35 00",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_educación_b.svg"
            },
            {
                "nombre": "Secretaría de Salud",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Salud/Salud_vino_h.svg",
                "facebook": "https://www.facebook.com/saludhgo/",
                "twitter": "https://twitter.com/Salud_Hidalgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-salud.hidalgo.gob.mx/",
                direccion:"Blvd. de la Minería #130; Fracc. Puerta de Hierro, Pachuca de Soto, Hidalgo",
                cp:"",
                tel:"+52 (771) 273 01 01",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_salud_b.svg"
            },
            {
                "nombre": "Secretaría de Seguridad Pública del Estado de Hidalgo",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Seguridad/Seguridad_vino_h.svg",
                "facebook": "https://www.facebook.com/SeguridadPublicaHGO/",
                "twitter": "https://twitter.com/SSP_Hidalgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-seguridad.hidalgo.gob.mx/",
                direccion:"Blvd Luis Donaldo Colosio S/N, Fraccionamiento Colosio II,Pachuca de Soto, Hidalgo, Méxic",
                cp:"C.P. 42039",
                tel:"+52 (771) 47 31 300 y (771) 47 31 400",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_seguridad_b.svg"
            },
            {
                "nombre": "Secretaría del Trabajo y Previsión Social",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Trabajo/Trabajo_vino_h.svg",
                "facebook": "https://www.facebook.com/Strabajohgo",
                "twitter": "https://twitter.com/@strabajohgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-trabajo.hidalgo.gob.mx/",
                direccion:"Blvd. Luis Donaldo Colosio #216, Fracc. Arboledas de San Javier, Pachuca de Soto, Hidalgo",
                cp:"",
                tel:"+52 (771) 71 78000, 71 76000 ext. 2764",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_trabajo_b.svg"
            },
            {
                "nombre": "Secretaría de Movilidad y Transporte",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Movilidad/Movilidad_vino_h.svg",
                "facebook": "https://www.facebook.com/Secretar%C3%ADa-de-Movilidad-y-Transporte-de-Hgo-301326669918709/",
                "twitter": "https://twitter.com/MovilidadHgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://movilidadytransporte.hidalgo.gob.mx/",
                direccion:"Calle 16 de enero de 1869, S/N. Colonia Periodistas, Pachuca de Soto, Hidalgo. C.P. 42060  Teléfono (771) 717 6000 Ext. 1827",
                cp:"",
                tel:"044 771 717 6400",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_movilidad_b.svg"
            },
            {
                "nombre": "Secretaría de Cultura",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Cultura/Cultura_vino_h.svg",
                "facebook": "https://www.facebook.com/CulturaHidalgo/",
                "twitter": "https://twitter.com/CulturaHidalgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://s-cultura.hidalgo.gob.mx/",
                direccion:"Nicolás Bravo No. 202, Colonia Centro, Pachuca de Soto, Hidalgo",
                cp:"",
                tel:"+52 (771) 71 5 0474 / 71 5 04 97 / 71 5 3628",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_cultura_b.svg"
            },
            {
                "nombre": "Unidad de Planeación y Prospectiva",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Planeacion/Planeacion_vino_h.svg",
                "facebook": "https://www.facebook.com/Unidad-de-Planeaci%C3%B3n-y-Prospectiva-Hidalgo-1420999494849173/",
                "twitter": "https://twitter.com/PlaneacionHgo",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://u-planeacion.hidalgo.gob.mx/",
                direccion:"Plaza Juárez S/N, Palacio de Gobierno, cuarto piso. Col. Centro, Pachuca de Soto, Hidalgo",
                cp:"C.P. 42000.",
                tel:"", 
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Secretaria_planeacion_b.svg"
            },
            {
                "nombre": "Oficialía Mayor",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/OficialiaMayor/OficialiaMayor_vino_h.svg",
                "facebook": "https://www.facebook.com/Oficialiamayordehidalgo",
                "twitter": "",
                "youtube": "https://www.youtube.com/user/CCSOCIALGOBHGO",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://oficialiamayor.hidalgo.gob.mx/",
                direccion:"Plaza Juárez S/N, Palacio de Gobierno, primer piso. Col. Centro, Pachuca de Soto, Hidalgo",
                cp:"C.P. 42000.",
                tel:"(771) 71 7 62 83",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Oficialia_mayor_b.svg"
            }
        ]
    },
    apoyo:{
        titulo:"ÁREAS DE APOYO",
        areas:[
            {
            "nombre": "Procuraduría General De Justicia",
                "img": "https://cdn.hidalgo.gob.mx/gobierno/images/secretarias/Procuraduria/Procuraduria_vino_h.svg",
                "facebook": "https://www.facebook.com/pgjeh",
                "twitter": "https://twitter.com/PGJE_Hidalgo",
                "youtube": "",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://procuraduria.hidalgo.gob.mx/",
                direccion:"Carretera México – Pachuca Km 84.5, Centro Cívico, Pachuca de Soto, Hidalgo.",
                cp:"CP. 42083",
                tel:"+52 (771) 71 79000 Ext. 9217",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Procu_b.svg"
            },
            {
                "nombre": "DIF",
                "img": "https://cdn.hidalgo.gob.mx/transicion/logos/secretarias/DIF.png",
                "facebook": "https://www.facebook.com/dif.hidalgo",
                "twitter": "https://twitter.com/difhgo",
                "youtube": "",
                msg:"https://www.hidalgo.gob.mx/escribele",
                "url":"https://dif.hidalgo.gob.mx/",
                direccion:"Salazar #100 Col Centro, Pachuca de Soto, Hidalgo.",
                cp:"C.P. 42000.",
                tel:"+52 (771) 71 73100",
                icon:"https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Dif_b.svg"
            } 
        ]
    }
};