import React,{Component} from 'react'; 
import { GobernadorIndex } from '../index/gobernador';
import {Link} from 'react-router-dom';
const img = [
  {img:"https://cdn.hidalgo.gob.mx/gobierno/images/comunicacion/vial.jpg"},
  {img:"https://cdn.hidalgo.gob.mx/gobierno/images/comunicacion/cartillaElectronica.jpg"},
  {img:"https://cdn.hidalgo.gob.mx/gobierno/images/comunicacion/hospitales.jpg"},
  {img:"https://cdn.hidalgo.gob.mx/gobierno/images/comunicacion/fotoNota.jpg"},
  {img:"https://cdn.hidalgo.gob.mx/gobierno/images/comunicacion/israelFelix.jpg"},
  {img:"https://cdn.hidalgo.gob.mx/gobierno/images/comunicacion/contraloria.jpg"}
];

class Gobernador extends Component { 
  componentDidMount(){
    const $ = window.$;
    $.ajax({
          type: "POST",
          url: "https://comunicacion-a.hidalgo.gob.mx/traer/notas",
          data: {secretaria:"Secretaría de Salud"},
          success: function(res){
            res.success.notas.forEach( function({title,descripcion,id,content,date,secretaria}, index) {
              let activ ="";
              if(index===0){
                activ = "active";
              }
              let img = "";
              content.forEach( function({type,urls},index){
                if(type==="Gallery" && urls.length === 1){
                  img = urls[0];
                }
              });
              $('#innerCarousel').append('<div class="carousel-item '+activ+'"><a class="noticiasLink"  href="https://comunicacion-a.hidalgo.gob.mx/detalles/'+id+'" target="_blank"><div style="min-height:500px"><div class="noticiasBanner" style="background-image: url('+img+');background-size: cover;background-repeat: no-repeat; max-height:500px; min-height: 500px;width: 100%;background-color:#092432;background-position:center"><div class="informacionNoticia"><h5>'+title+'</h5><p>'+descripcion+'</p><p>Ir a Nota</p></div></div></div></a></div>');
            });
          },
          dataType: 'json'
        });
  }
  state = { progress: 50 }
  render() {
    return (
      <section className="Gobernador">
        <div className="container container-forheader">
          <div className="landscape" style={{
              backgroundImage: "url("+(GobernadorIndex.gobernador.landscape)+")",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: 330
            }}
          />
        </div>
        <div className="gober">
          <div style={{backgroundImage: "url("+GobernadorIndex.gobernador.imagen+")", backgroundSize: "cover"}} alt="gobernador" className={"gobernador_img"}/>
        </div>
        <div className={"gobernador_name"}>
          {GobernadorIndex.gobernador.nombre}
        </div>
        <div className={"gobernador_cargo"}>
          {GobernadorIndex.gobernador.titulo}
        </div>
        {/*<div className={"gobicons"}>
          <button className="icons" onClick={()=>window.open(GobernadorIndex.gobernador.facebook)}><i className="fab fa-facebook-square"></i></button>
           <button className="icons" onClick={()=>window.open(GobernadorIndex.gobernador.twitter)}><i className="fab fa-twitter"></i></button>
          <Link to={{pathname:"/"}}>
            <button className="icon"><i className="fas fa-arrow-circle-left"></i></button>
          </Link>
        </div>*/}
        <div className="container sectionDetalle">
          <div className="row ">
            <div className="col-12 ">
              <div>
                <span>
                   {this._renderSemblanza()} 
                </span>
              </div>   
            </div>          
            <div className="col-12 trayectoria">
              {this._renderTrayectoria()}     
            </div>
          </div>
          <div className="texture"></div>
        </div>
      </section>
    );
  };

  _renderBox = () => { 
    return img.map(({img},i) => {
      return(
        <div className="box"
          style={{
                  backgroundImage: "url("+img+")",
                  backgroundSize: 'cover',
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
        ></div>
      );
    });
  };
  _renderTrayectoria = () => {
    let itemparsed = GobernadorIndex.gobernador.trayectoria[0].split("*");
        itemparsed = itemparsed.map((texts) => {
          if (texts.indexOf("/") > -1) {
            return <b>{ texts.split('/').join("") }</b>;
          } 
          return texts;
        });
        return itemparsed;
  };
  _renderSemblanza = () =>{
    return GobernadorIndex.gobernador.semblanza.map((text)=>{
      if(text.black){
        return(<b>{text.black}</b>)
      }
      if(text.br){
        return(<div><hr/></div>)
      }
      return(<div className="semblanza-div">{text}<hr/></div>)
    });
  };
}

export default Gobernador;