import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Inicio from './views/Inicio';
import Gobernador from './views/Gobernador';
import Gabinete from './views/Gabinete';
import informes from './views/informes';
import GabineteDetalle from './views/GabineteDetalle';
import OrgDescentralizados from './views/OrgDescentralizados';
import Dependencias from './views/Dependencias';
import ExGoberDetalle from './views/ExGoberDetalle';
import exgobernadores from './views/exgobernadores';
import dependenciasfederales from './views/dependenciasfederales';
import AvisoPrivacidad from './views/AvisoPrivacidad';
import Emergencias from './views/Emergencias';
import pub from './views/pub';
import Escribele from './views/Escribele';
import Agenda from './views/Agenda'

const RedirectToExternalURL = () => {
    // Redirigir al usuario a la URL externa
    React.useEffect(() => {
        window.location.href = 'https://www.hidalgo.gob.mx';
    }, []);

    // Este componente no renderiza nada, ya que la redirección ocurre inmediatamente
    return null;
};


class Router extends Component {

	render(){
		return (
            // <Switch>
            //     <Route exact path='/AvisoPrivacidad' component={AvisoPrivacidad}/>
            //     {/* Ruta de respaldo */}
            //     <Route component={RedirectToExternalURL} />
            // </Switch>
			<Switch>
                <Route exact path='/' component={Gobernador}/>
                <Route exact path='/Gobernador' component={Gobernador}/>
                <Route exact path='/Gabinete' component={Gabinete}/>
                <Route exact path='/informes' component={informes}/>
                <Route exact path='/GabineteDetalle/:nombre' component={GabineteDetalle}/>
                <Route exact path='/OrgDescentralizados' component={Gobernador}/>
                <Route exact path='/Dependencias' component={Dependencias}/>
                <Route exact path='/exgobernadores' component={Gobernador}/>
                <Route exact path='/ExGoberDetalle/:nombre' component={Gobernador}/>
                <Route exact path='/dependenciasfederales' component={dependenciasfederales}/>
                <Route exact path='/AvisoPrivacidad' component={AvisoPrivacidad}/>
                <Route exact path='/Emergencias' component={Emergencias}/>
                <Route exact path='/pub' component={pub}/>
                <Route exact path='/Escribele' component={Escribele}/>
                <Route exact path='/Agenda' component={Gobernador}/>
            </Switch>
		);
	}
};

export default Router;