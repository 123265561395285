import React,{Component} from 'react';
import { Card } from 'antd';
import { InformeIndex } from '../index/informe';
const { Meta } = Card;
let gobiernoA = "";

class Informes extends Component {
  state = {
    periodo:"0"
  }

  handleChange = (e) => {
    this.setState({ periodo: e.target.value });
  }
 
  render(){
    
    const {
      periodo
    } = this.state;
    if(periodo ==="0"){
      gobiernoA = (InformeIndex.informe.actual);
    }
    if(periodo === "1"){
      gobiernoA = (InformeIndex.informe.anterior);
    }
    return (
        <div id="contentglobal" className="Informes" style={{backgroundImage:"url("+`${gobiernoA.imgGob}`+")"}}>
          <div className="container">

            <div className="row ImgGobMov">
              <div className="col-sm-12">
                <select style={{ "width": 150,
                                  float: 'right',
                                  marginTop: '25px',
                                  height: '25px',
                                  background: 'transparent',
                                  color: 'white',
                                  fontSize: '11px'}} 
                onChange={this.handleChange}>
                  <option value="0" style={{color:"black"}}>Omar Fayad Meneses</option>
                  <option value="1" style={{color:"black"}}>Francisco Olvera Ruiz</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <center>
                  <h1 style={{color: "#FFF"}}>{gobiernoA.gobernador}</h1>
                </center>
              </div>
            </div>

            <div className="row">
              <div className="col-12 sectionInformes">
                <div className="row" style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                  {this._renderInforme()}
                </div>
              </div>
            </div>

            {/*<div className="row">
              <div className="col-12" style={{textAlign:'center',fontWeight: "bold",width:'100%',color:'white'}}>
                <div className="row sectionEje">
                  {this._renderEjes()}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12" style={{height: 80, marginTop: 30, marginBottom: 30}}>
                <center>
                  <a href={gobiernoA.planEstatal} target="_blank" className="button"style={{padding:"1%",color:"black",zIndex:'999', border: '1px solid white', color: 'white'}}>
                    Plan Estatal de Desarrollo
                  </a>
                </center>
              </div>
            </div>*/}
            
          </div>
        </div>
      );
  }
  _renderInforme = () =>{
    return gobiernoA.informes.map(({imgInformes,fecha,text,download,url,numInforme},i)=>{
      if(text==="PROXIMAMENTE"){
        return(
          <div>
            <div className="boxInforme proximamente" style={{background:"white",padding:'0'}}>
              <div>
                  <p>{fecha}</p>
                  <p>{text}</p>
              </div>
            </div>
            <div style={{height: '38px'}}>
              
            </div>
          </div>
        );
      }
      return(
        <div>
          <div className="boxInforme" style={{backgroundImage:"url("+`${imgInformes}`+")",backgroundSize:'cover',backgroundPosition:'center',position: 'relative',padding:'0'}}>
            <div className="SitioInforme">
              <div style={{textDecoration:"none",heigt:"100%",padding:'10px',display: 'flex',justifyContent: 'center',color:"white"}}>
                {numInforme}
              </div>
            </div>
          </div>
          <a href={download} target="_blank" style={{textDecoration:"none"}}><button className="download"><i class="fas fa-download"></i></button></a>
        </div>
      );
    });
  }
  _renderEjes = () => {
    return gobiernoA.ejes.map(({imgEje,texto},i)=>{
      return(
        <div style={{margin:"2%",marginTop:"0"}}>
          <div className="card">
            <img style={{}} className="card-img-top" src={`${imgEje}`}/>
            <div className="card-body">
              {texto}
            </div>
          </div>
        </div>        
      );
    });
  }
}

export default Informes;