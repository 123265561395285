import React,{Component} from 'react';
import { Org } from '../index/descentralizados';

class OrgDescentralizados extends Component {
  render(){
    return (
      <div id="contentglobal" className="OrgDescentralizados">
          <h1 className="titulo">ORGANISMOS DESCENTRALIZADOS</h1>
          <hr className="hrorganismos"/>
        <div className="introduccion">
          {this._renderparrafos()}
        </div>
        {/*<div style={{padding:"2% 5%"}}>
          <div className="input-group" style={{width:"25vw"}}>
            <input type="text" className="form-control" placeholder="ORGANISMOS DESCENTRALIZADOS"/>
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon2"><i className="fas fa-search"></i></span>
            </div>
          </div>
        </div>*/}
        <div className="Timeline">
          {this._renderTime()}
        </div>
      </div>
      );
  }
  _renderTime = () =>{
    return Org.organos.descentralizado.map(({secretaria,list},i)=>{
      const organismos = (list) =>{
        return list.map((text)=>{
          return(
            <li><a href={text.url} target="_blank" style={{color:"black"}}>{text.name}</a></li>
          );
        });
      };
      let linea = "rgba(9,72,102,1)";
      if(Org.organos.descentralizado.length===(i+1)){
        linea = "white";
      }
      
      return(
        <div className={"skillrow"}>
          <div className="circulo"/>
          <div className={"line"+(i+1)} style={{ position: 'absolute', height: "calc(100% + 25px)",width: "3px", background: linea, left: "10px"}}/>
            <h6 style={{fontWeight:"bold",width: "250px",paddingLeft:"25px",marginBottom: "0px"}}>{secretaria}</h6>
            <hr/>
            <ul>
              {organismos(list)}
            </ul>
        </div>
      );
    });
  };
  _renderparrafos = () => {
    return Org.organos.introduccion.map((text)=>{
      return(
        <p>
          {text}
        </p>
      );
    }); 
  };
}

export default OrgDescentralizados;