import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; 
import { Calendar } from 'antd';
import ReactPlayer from 'react-player';
import { GobernadorIndex } from '../index/gobernador';
import { GabineteIndex } from '../index/gabinete';
import Cluster from '../Components/Cluster';
import { connect } from 'react-redux';
import Globals from '../globals';
const IndexCluster = [GobernadorIndex.gobernador, ...GabineteIndex.gabinete.servidores];

class  NotaComunicacion extends Component {
  componentDidMount() {
    const $ = window.$;
    $.ajax({
          type: "POST",
          url: "https://comunicacion-a.hidalgo.gob.mx/traer/notas",
          data: {secretaria:"Secretaría de Salud"},
          success: function(res){
            res.success.notas.forEach( function({title,descripcion,id,content,date,secretaria}, index) {
              let activ ="";
              if(index===0){
                activ = "active";
              }
              let img = "";
              content.forEach( function({type,urls},index){
                if(type==="Gallery" && urls.length === 1){
                  img = urls[0];
                }
              });
              $('#innerCarousel').append('<div class="carousel-item '+activ+'"><a class="noticiasLink"  href="https://comunicacion-a.hidalgo.gob.mx/detalles/'+id+'" target="_blank"><div style="min-height:500px"><div className="noticiasBanner" style="background-image: url('+img+');background-size: cover;background-repeat: no-repeat; max-height:500px; min-height: 500px;width: 100%;background-color:#092432;background-position:center"><div className="informacionNoticia"><h5>'+title+'</h5><p>'+descripcion+'</p><p>Ir a Nota</p></div></div></div></a></div>');
            });
          },
          dataType: 'json'
        });
   }
  render(){
    return (
      <div className="container-not" style={{borderTop:"none"}}>
        <div className="container">
          <div className="row">
            <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="titulo-noticias" style={{marginTop:"45px"}}>
                      <center>
                        <h1>Noticias</h1>
                      </center>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div id="contenthtml" className="content"></div>
                  </div>
                  <div className="col-md-5 col-12 col-lg-5">
                  <iframe className="frame-fb" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Ffacebook.com%2Fgobhidalgo%2F&tabs=timeline&width=400px&height=808&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="400px" height="808" style={{border:"none", overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default connect(
)(NotaComunicacion);