import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';

class  Chart1 extends Component {
  render(){
    const { selected } = this.props;
    if(selected){
      return(
        <LineChart width={200} height={200} data={selected}
            margin={{top: 5, right: 5, left: -15, bottom: 2}}>
          <XAxis dataKey="name"/>
          <YAxis/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip/>
          <Legend />
          <Line type="monotone" dataKey="uv" stroke="#092432" activeDot={{r: 8}}/>
        </LineChart>
      )
      }
      return(
        <div></div>
      )
  }
}
export default Chart1;