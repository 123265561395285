import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import ExGoberDetalle from './ExGoberDetalle';

const ExGobernadoresIndex = {
    exgobernadores:{
        resume:{
            name: "Ex-Gobernadores",
            description: "Cronología de los Gobernadores que ha tenido nuestro Estado, y que han sido los encargados de dirigir el destino del Estado",
            url: "/exgobernadores"
        },
        titulo:"Ex-Gobernadores",
        introduccion:"Cronología de los Gobernadores que ha tenido nuestro Estado, y que han sido los encargados de dirigir el destino del Estado desde 1869 hasta el 2011. Es la Sala de Ex-Gobernadores, ubicada en el segundo piso del Palacio de Gobierno del Estado de Hidalgo, la que alberga los retratos al óleo de los 33 ex mandatarios estatales que han dado rumbo al Estado. Es un espacio utilizado para llevar a cabo diversos actos protocolarios.",
        exgober:[
            {
                nombre:"Lic. José Francisco Olvera Ruíz",
                imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/olvera.png",
                periodo:"2011- 2016"
            },
            {
              nombre:"Lic. Miguel Angel Osorio Chong",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/osorio.png",
              periodo:"2005- 2011"
            },
            {
              nombre:"Lic. Manuel Ángel Nuñez Soto",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/nunez.png",
              periodo:"1999-2005"
            },
            {
              nombre:"Lic. Humberto A. Lugo Gil",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lugogil.png",
              periodo:"1998-1999"
            },
            {
              nombre:"Lic. Jesús Murillo Karam",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/murillo.png",
              periodo:"1993 - 1998"
            },
            {
              nombre:"Lic. Adolfo Lugo Verduzco",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lugov.png",
              periodo:"1987 - 1993"
            },
            {
              nombre:"Arq. Guillermo Rosell de la Lama",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rosell.png",
              periodo:"1981 - 1987"
            },
            {
              nombre:"Lic. José Luis Suárez Molina",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/suarez.png",
              periodo:"1976 - 1978"
            },
            {
              nombre:"Lic. Jorge Rojo Lugo",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rojolugo.png",
              periodo:"1975 - 1981"
            },
            {
              nombre:"Lic. Raúl Lozano Ramírez",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lozano.png",
              periodo:"1975"
            },
            {
              nombre:"Lic. Manuel Sánchez Vite",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/sanchez.png",
              periodo:"1969 - 1975"
            },
            {
              nombre:"Dr. Otoniel Miranda Andrade",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/miranda.png",
              periodo:"ABRIL DE 1975"
            },
            {
              nombre:"Profr. Donaciano Serna Leal",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/serna.png",
              periodo:"1970 - 1972"
            },
            {
              nombre:"Lic. Carlos Ramírez Guerrero",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/ramirez.png",
              periodo:"1963 - 1969"
            },
            {
              nombre:"Mayor Oswaldo Cravioto Cisneros",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/cravioto.png",
              periodo:"1958 - 1963"
            },
            {
              nombre:"Lic. Alfonso Corona del Rosal",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/corona.png",
              periodo:"1957 - 1963"
            },
            {
              nombre:"Quintín Rueda Villagrán",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rueda.png",
              periodo:"1951 - 1957"
            },
            {
              nombre:"Lic. Vicente Aguirre del Castillo",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/aguirre.png",
              periodo:"1945 - 1951"
            },
            {
              nombre:"José Lugo Guerrero",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lugoguerrero.png",
              periodo:"1941 - 1945"
            },
            {
              nombre:"Coronel Otilio Villegas Lora",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/villegas.png",
              periodo:"1940"
            },
            {
              nombre:"Lic. Javier Rojo Gómez",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rojogomez.png",
              periodo:"1937 - 1941"
            },
            {
              nombre:"Ernesto Viveros Pérez",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/azuara.png",
              periodo:"1933 - 1937"
            },
            {
              nombre:"Bartolomé Vargas Lugo",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/vargas.png",
              periodo:"1929 - 1933"
            },
            {
              nombre:"Matías Rodríguez Melgarejo",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rodriguez.png",
              periodo:"1925 - 1929"
            },
            {
              nombre:"Nicolás Flores",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/flores.png",
              periodo:"1917 - 1921"
            },
            {
              nombre:"Ramón M. Rosales",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rosales.png",
              periodo:"1911 - 1913"
            },
            {
              nombre:"Pedro L. Rodríguez",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lrodriguez.png",
              periodo:"1901 - 1911"
            },
            {
              nombre:"Francisco Cravioto",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/franciscocravioto.png",
              periodo:"1885 - 1889"
            },
            {
              nombre:"Simón Cravioto",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/simoncravioto.png",
              periodo:"1881 - 1885"
            },
            {
              nombre:"Rafael Cravioto",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rafaelcravioto.png",
              periodo:"1876 - 1901"
            },
            {
              nombre:"Justino Fernández",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/fernandez.png",
              periodo:"1873 - 1876"
            },
            {
              nombre:"Antonio Tagle",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/tagle.png",
              periodo:"1869 - 1873"
            },
            {
              nombre:"Juan Crisóstomo Doria",
              imagen:"https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/doria.png",
              periodo:"ENERO - MAYO 1869"
            }
            
        ]
    }
}
class Exgobernadores extends Component {
  
  render(){
    return (
        <div id="contentglobal" className="Exgobernadores" >
          <div className="container">
            <h1 className="titulo">EX-GOBERNADORES</h1>
            <hr/>
          </div>
          <div style={{textAlign:"justify",padding:'3%'}}>
            {ExGobernadoresIndex.exgobernadores.introduccion} 
          </div>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
            {this._renderExgobernadores()}
          </div>
        </div>
      );
  }
  _renderExgobernadores = () => {
    return ExGobernadoresIndex.exgobernadores.exgober.map(({nombre,imagen,periodo},i)=>{
      return(
        <div style={{display:'inline-block',margin:"4%",marginBottom:"1%",marginTop:"1%"}}>
          <div className="card">
            <div className="card-img-top" style={{backgroundImage: "url("+imagen+")", backgroundSize: "cover"}}/>
            <div className="card-body">
              <h6 style={{paddingTop:"2%"}}>{nombre}</h6>
              <div className="periodo">{periodo}</div>
              <div><a href={"/ExGoberDetalle/"+nombre} style={{color:"rgba(9,72,102,1)"}}>Ver Más</a></div>
            </div>
          </div>   
        </div>   
      );
    });
  }
}

export default Exgobernadores;