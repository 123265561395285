import React,{Component} from 'react';

class Escribele extends Component {
	
  render(){
    return (
      <div id="contentglobal" className="Escribele" style={{minHeight: "100vh",marginTop: "110px"}}>
      	<div>
      		<center>
      			<h1 style={{
      				paddingTop: '2%',
    				textAlign: 'center',
    				letterSpacing: '15px',
    				fontWeight: 'bold',
    				fontSize: '3vw',
    				lineHeight: '50px',
    				border: '0px',
    				borderBottom: '2px #000 solid',
    				width: '40vw'
      			}}>Escríbele a tu</h1>
      		</center>
      		<center>
      			<h1 style={{
    				textAlign: 'center',
    				letterSpacing: '15px',
    				marginBottom: '30px',
    				fontWeight: 'bold',
    				fontSize: '3vw',
    				lineHeight: '50px',
    				border: '0px',
    				width: '50vw'
      			}}>Funcionario</h1>
      		</center>
      	</div>
        <iframe style={{width:"100%", minHeight: "100vh"}} src="https://notes.e-hidalgo.gob.mx:8080/Ehidalgo/Escribe/escribe2016.nsf/MENSAJECIUDADANO?OpenForm"/>
      </div>
      );
  }
 
}

export default Escribele;