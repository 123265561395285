import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import exgobernadores from './exgobernadores';
const ExGobernadoresIndex = {
    exgobernadores:{
        exgober:[
            {
                nombre:"Lic. José Francisco Olvera Ruíz",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/olvera.png",
                periodot:"Gobernador Constitucional del Estado de Hidalgo 1 de Abril De 2011 - 4 de Septiembre de 2016",
                introduccion:[
                  "Nació en Pachuca, capital del Estado de Hidalgo, el 15 de Junio de 1956, cursó sus estudios en instituciones públicas, recibiéndose como Licenciado en Derecho por la Universidad Autónoma del Estado de Hidalgo, vía titulación automática por promedio.",
                  "Cuenta con Maestría en Administración Pública, por el Instituto Tecnológico de Monterrey y la Universidad del Valle de México."
                ],
                detalle:[
                  "En julio de 2010 fue electo Gobernador Constitucional del Estado de Hidalgo, cargo que asumió el 1 de Abril de 2011 y que entregó al actual mandatario, Omar Fayad Meneses el 5 de Septiembre de 2016.",
                  "Como presidente municipal de Pachuca, Francisco Olvera fue socio fundador de la Asociación de Municipios del Estado de Hidalgo (AMEH, AC), organismo que se encuentra afiliado a la Federación Nacional de Municipios de México (FENAMM, AC).",
                  "Antes, Francisco Olvera fue Secretario de Gobierno en la administración de Miguel Ángel Osorio Chong. De igual forma, fue Diputado Local en dos ocasiones.",
                  "Entre 1999 y 2001 fungió como Coordinador General Jurídico.",
                  "En 1995 se desempeñó como Director del Archivo General de Notarías del Estado de Hidalgo. Con anterioridad, durante nueve años, fue Director de Catastro en la entidad.",
                  "En su trayectoria, destaca su paso por la presidencia del Club de Fútbol Pachuca, equipo al que llevó a disputar una final.",
                  "Cuenta con diversas actividades partidistas, tales como presidente del Comité Directivo Municipal y Secretario General del Comité Directivo Estatal, ambos cargos dentro del Partido Revolucionario Institucional.",
                  "Francisco Olvera se encuentra habilitado como Corredor Público por parte de la Secretaría de Economía del Gobierno Federal.",
                  "José Francisco Olvera se encuentra casado con la señora María Guadalupe Romero Delgado, quienes tienen dos hijos, Francisco y Jorge Olvera Romero."
                ]
            },
            {
                nombre:"Lic. Miguel Angel Osorio Chong",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/osorio.png",
                periodot:"",
                introduccion:[
                  "Nació en Pachuca, Hidalgo, el 5 de agosto de 1964.",
                  "Realizó sus estudios de Licenciatura en Derecho en la Universidad Autónoma del Estado de Hidalgo"
                ],
                detalle:[
                  "En el servicio público se ha desempeñado como Oficial Mayor en la Presidencia Municipal de Pachuca; en la administración estatal fue Subsecretario de Gobierno, Secretario de Desarrollo Social, Secretario de Desarrollo Regional y Secretario de Gobierno, donde su experiencia política le permitió resolver los problemas estudiantiles en la Normal Luis Villa Real del Mexe,a la que posteriormente transformó en la Universidad Politécnica Francisco I.Madero cuando fue Gobernador.",
                  "Fue también Diputado Federal por el sexto distrito con cabecera en Pachuca y coordinó la fracción priísta del estado de Hidalgo, así como Vicecoordinador de su Grupo Parlamentario en la LIX Legislatura, donde impulsó las iniciativas de Protección a Víctimas del Delito y la de Rendición de Cuentas para Legisladores Federales.",
                  "Fue Gobernador Constitucional del Estado de Hidalgo del 1º de abril de 2005 al 31 de marzo de 2011, en donde obtuvo importantes logros como la construcción de tres nuevas Instituciones de Educación Superior, alcanzó el primer lugar estatal en cobertura universal de Educación Básica, e impulsó la construcción de 13 nuevos centros de salud municipales, seis hospitales regionales y el Gran Hospital del Niño DIF.",
                  "También destaca la inversión en más de 8,700 kilómetros de infraestructura carretera, la implementación del programa de nutrición y el programa integral alimentario, que en la última medición redujo en gran parte el nivel de desnutrición en zonas de alta marginación.",
                  "En su Gobierno se implementó el programa de útiles escolares en beneficio de todos los alumnos de las escuelas públicas de preescolar, primaria y secundaria del estado.",
                  "Se desempeñó como Coordinador de Diálogo y Acuerdo Político de la oficina de Enrique Peña Nieto.",
                  "Fue Coordinador General de Política y Seguridad del Equipo de Transición del Presidente Electo Enrique Peña Nieto, y actualmente es Secretario de Gobernación."
                ]
            },
            {
                nombre:"Lic. Manuel Ángel Nuñez Soto",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/nunez.png",
                periodot:"Fue electo Gobernador del Estado de Hidalgo del 1 de abril de 1999 al 31 de Marzo de 2005.",
                introduccion:[
                  "Nació en la ciudad de Actopan, Hidalgo, el 30 de enero de 1951.",
                  "Licenciado en Economía por la UNAM."
                ],
                detalle:[
                  "Fue electo Gobernador del Estado de Hidalgo del 1 de abril de 1999 al 31 de Marzo de 2005.",
                  "Se desempeñó como jefe de la oficina de México en Canadá para las negociaciones del TLC.",
                  "Fue Director de Programación Externa en el Banco de Comercio Exterior. Consejero Comercial de México en Canadá.",
                  "En 1993, se incorporó como Secretario de Industria y Comercio. Fue nombrado Secretario de Gobierno del Estado.",                  ,
                  "Fue diputado Federal y se desempeño como Coordinador de los Diputados del Estado de Hidalgo.",
                  "Fue también Secretario de la Comisión de Comercio; miembro de las comisiones de programación, presupuesto y cuenta pública y de Patrimonio y Fomento Industrial.",
                  "Fue nombrado integrante de la comisión Permanente para el primer año de ejercicio de la LVII Legislatura.",
                  "Se afilió al PRI en 1968 donde se desempeño en la Coordinación ejecutiva de la Secretaría de Administración y Finanzas del Comité Ejecutivo Nacional."
                ]
            },
            {
                nombre:"Lic. Humberto A. Lugo Gil",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lugogil.png",
                periodot:"Fue Gobernador Constitucional Sustituto del Estado de Hidalgo para el período del 29 de Octubre 1998 al 31 de Marzo de 1999.",
                introduccion:[
                  "Nació en Huichapan, Hidalgo.",
                  "Recibió el Titulo de Licenciado en Derecho, en la Universidad Nacional Autónoma de México."
                ],
                detalle:[
                  "Fue Gobernador Constitucional Sustituto del Estado de Hidalgo para el período del 29 de Octubre 1998 al 31 de Marzo de 1999.",
                  "A partir de 1956 es militante del Partido Revolucionario Institucional en donde ha ocupado los cargos de Secretario de Prensa y Propaganda, Secretario de Acción Social, Secretario de Acción Popular, Secretario de Acción Política y Coordinación Legislativa, Oficial Mayor y Secretario General del Comité Ejecutivo Nacional.",
                  "Fue Coordinador de la Comisión Nacional Electoral del Consejo Político Nacional de P.R.I., Secretario General de la C.N.O.P., Presidente de la Comisión Política del C.E.N. de este Sector, Delegado General de esta Central y del C.E.N. del P.R.I. en diversos Estados de la República.",
                  "Secretario del Consejo Político Estatal en Hidalgo, Secretario Adjunto a la Presidencia del Comité Ejecutivo Nacional de Partido Revolucionario Institucional y Presidente de la Comisión Nacional para el desarrollo del proceso de selección interna del propio Instituto Político.",
                  "Fue Diputado al Congreso de la Unión en las XLVII y LII Legislaturas, Presidente de la Gran Comisión de la Cámara de Diputados, Senador de la República en dos ocasiones y Presidente de la Gran Comisión de la Cámara de Senadores.",
                  "Presidente y Vicepresidente de las Delegaciones Interparlamentarias México – Estados Unidos, Presidente de la Delegación Mexicana en diversas reuniones Interparlamentarias efectuadas en países de América Latina, Europa, Asia y Oceanía.",
                  "Presidente de la Asamblea Mundial de Parlamentos sobre Población y Desarrollo, Vicepresidente del Parlamento Latinoamericano y Presidente de la Comisión de Asuntos Políticos del propio Parlamento.",
                  "Fue Agente del Ministerio Público en Pachuca, Hidalgo, Jefe de Departamento en la Dirección General de Estudios Hacendarios de la Secretaría de Hacienda y Crédito Público.",
                  "Director General de Departamentos y Oficinas de la H. Cámara de Diputados en la LVI Legislatura, Delegado del Departamento del Distrito Federal en Venustiano Carranza"
                ]
            },
            {
                nombre:"Lic. Jesús Murillo Karam",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/murillo.png",
                periodot:"Fue Gobernador del Estado de Hidalgo del 1 de abril de 1993 al 28 de octubre de 1998.",
                introduccion:[
                  "Nació en Mineral del Monte, Hidalgo, en 1948.",
                  "Recibió el Título de Licenciado en Derecho, en la Universidad Autónoma de Hidalgo."
                ],
                detalle:[
                  "Fue Gobernador del Estado de Hidalgo del 1 de abril de 1993 al 28 de octubre de 1998.",
                  "En el año de 1998, solicitó licencia al Congreso de Estado, para ocupar la Sub-secretaría de Gobernación.",
                  "Fue Secretario del H. Ayuntamiento de Tulancingo, Hidalgo",
                  "Sub-Procurador de Justicia en el Estado",
                  "Secretario General de Gobierno",
                  "Diputado al Congreso de la Unión en las LI y LIII Legislaturas",
                  "Oficial Mayor de la Secretaría de la Reforma Agraria",
                  "Senador de la República.",
                  "Dentro del Partido Revolucionario Institucional, ocupó diversos cargos directivos, como:",
                  "Presidente del Comité Directivo Estatal en Hidalgo",
                  "Subsecretario de Organización en el Comité Directivo en el Distrito Federal",
                  "Delegado del Partido Revolucionario Institucional en los Estados de Durango y San Luis Potosí."
                ]
            },
            {
                nombre:"Lic. Adolfo Lugo Verduzco",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lugov.png",
                periodot:"Gobernó el Estado de Hidalgo del 1 de abril de 1987 al 31 de marzo de 1993.",
                introduccion:[
                  "Nació en Huichapan, Hidalgo, en 1933. ",
                  "Recibió el Título de Licenciado en Derecho y la Maestría en Ciencia Política, en la Universidad Nacional Autónoma de México."
                ],
                detalle:[
                  "Gobernó el Estado de Hidalgo del 1 de abril de 1987 al 31 de marzo de 1993.",
                  "Fue miembro del Consejo Técnico consultivo de la Confederación Nacional Campesina en 1967",
                  "Jefe del Departamento de Adquisiciones del Instituto Mexicano del Seguro Social de 1966 a 1970.",
                  "Fue Secretario de Planeación y programación del Instituto de Estudios Políticos Económicos y Sociales del P.R.I. ( IEPES ) de 1969 a 1970.",
                  "En el año de 1970 a 1976 fue Subdirector de Filiales CONASUPO.",
                  "Director General del Instituto Nacional del Consumidor de 1976 a 1979",
                  "Oficial Mayor de la Secretaria de Programación y Presupuesto de 1979 a 1981.",
                  "Secretario General del PRI de 1981 a 1982",
                  "Senador de la República y Presidente del Comité Ejecutivo Nacional del PRI de 1982 a 1986",
                  "Presidente del Instituto Nacional de Administración Pública (I.N.A.P.)."
                ]
            },
            {
                nombre:"Arq. Guillermo Rosell de la Lama",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rosell.png",
                periodot:"Gobernó el Estado de Hidalgo del 1 de abril de 1981 al 31 de marzo de 1987.",
                introduccion:[
                  "Nació el 22 de Julio de 1925.",
                  "Recibió el Título de Arquitecto, en la Universidad Nacional Autónoma de México."
                ],
                detalle:[
                  "Gobernó el Estado de Hidalgo del 1 de abril de 1981 al 31 de marzo de 1987.",
                  "Fue miembro del Consejo Técnico Consultivo de la Confederación Nacional Campesina en 1967",
                  "Jefe del Departamento de Adquisiciones del Instituto Mexicano del Seguro Social de 1966 a 1970.",
                  "Director del Departamento de Turismo y Gerente de Promoción y Planeación de la Secretaria de Comunicaciones y Obras Públicas.",
                  "En el año 1958 a 1964 fue Oficial Mayor, de la Secretaría del Patrimonio Nacional",
                  "Senador de la República",
                  "Secretario de Turismo de 1976 a 1980, durante el Gobierno del Presidente José López Portillo."
                ]
            },
            {
                nombre:"Lic. José Luis Suárez Molina",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/suarez.png",
                periodot:"Gobernó interinamente al Estado de Hidalgo del 1 de Diciembre de 1976 al 1 de Junio de 1978.",
                introduccion:[
                  "Nació en Tula, Hidalgo",
                  "Recibió el Título de Licenciado en Derecho en la Universidad Nacional Autónoma de México."
                ],
                detalle:[
                  "Fue Dirigente Juvenil del Partido Revolucionario Institucional y Presidente del Comité Directivo Estatal del mismo partido.",
                  "Diputado Federal a la XLII y XLV Legislaturas.",
                  "Gobernó interinamente al Estado de Hidalgo del 1 de Diciembre de 1976 al 1 de Junio de 1978."
                ]
            },
            {
                nombre:"Lic. Jorge Rojo Lugo",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rojolugo.png",
                periodot:"El 7 de septiembre asumió la gubernatura para terminar el periodo que concluía el 31 de marzo de 1981",
                introduccion:[
                  "Nació en  Huichapan, Estado de Hidalgo, 19 de junio de 1933.",
                  "Recibió el Título de de la Universidad Nacional Autónoma de México,"
                ],
                detalle:[
                  "Fue electo diputado federal por el V Distrito Electoral Federal de Hidalgo a la XLV Legislatura de 1961 a 1964",
                  "Del 1965 a 1970 fue Subdirector general del Banco Nacional Hipotecario.",
                  "Del 1970 a 1975 fue Director General del Banco Nacional Hipotecario.",
                  "A partir del 10 de enero de 1975 fue Director General del Banco Nacional Agropecuario por nombramiento del presidente Luis Echeverría Álvarez.",
                  "El 29 de abril del 1975 y ante la declaratoria de desaparición de poderes en Hidalgo fue designado candidato del PRI a gobernador y electo en el proceso electoral extraordinario.",
                  "El 7 de septiembre asumió la gubernatura para terminar el periodo que concluía el 31 de marzo de 1981 y para el que originalmente había sido electo Otoniel Miranda.",
                  "Solicitó licencia a la gubernatura el 1 de diciembre de 1976 al ser nombrado Secretario de la Reforma Agraria por el presidente José López Portillo, permaneciendo en el cargo hasta el 1 de junio de 1978 en que retornó a la gubernatura y concluyó su periodo."
                ]
            },
            {
                nombre:"Lic. Raúl Lozano Ramírez",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lozano.png",
                periodot:"Estuvo a cargo de la Administración como Gobernador provisional del 29 de Abril de 1975 al 7 de Septiembre del mismo año.",
                introduccion:[
                  "Nació en Molango, Hidalgo, el 9 de Marzo de 1912. ",
                  "Recibió el Título de Abogado en la Facultad de Jurisprudencia de la Universidad Nacional de México."
                ],
                detalle:[
                  "Estuvo a cargo de la Administración como Gobernador provisional del 29 de Abril de 1975 al 7 de Septiembre del mismo año.",
                  "Secretario de la Junta Central de Conciliación y Arbitraje",
                  "Magistrado del Tribunal Superior de Justicia",
                  "Diputado al Congreso del Estado por el Distrito de Meztitlán",
                  "Secretario General del Gobierno del Estado, Magistrado y Presidente del Tribunal Superior de Justicia.",
                  "Presidente Ejecutivo de la Junta Local de Electrificación en el Estado de Hidalgo",
                  "Diputado al Congreso de la Unión y Senador de la República por el Estado de Hidalgo."
                ]
            },
            {
                nombre:"Lic. Manuel Sánchez Vite",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/sanchez.png",
                periodot:"Fue Gobernador del 1 de Abril de 1969 al 31 de Marzo de 1975.",
                introduccion:[
                  "Nació en Molango, Hidalgo, el 17 de Mayo de 1915.",
                  "Recibió el Título de Abogado en 1947, en la Escuela Nacional de Jurisprudencia de la Universidad Nacional Autónoma de México."
                ],
                detalle:[
                  "Dedicado al Magisterio desde 1935.",
                  "Fue asesor Jurídico en el Sindicato Nacional de Trabajadores de la Educación, en el Sindicato del ISSSTE",
                  "Fue asesor Jurídico en el Sindicato de Trabajadores de Agricultura y Ganadería en la República Mexicana.",
                  "De 1942 a 1946 fue Secretario General de la Sección IX del Sindicato Nacional de Trabajadores de la Educación",
                  "Representante de los Centros Escolares del Distrito Federal en 1946",
                  "Secretario General del SNTE en 1952",
                  "Presidente de la Comisión Política del SNTE en 1955",
                  "Representante de la Federación del Sindicato de Trabajadores al Servicio del Estado para crear el Sindicato del ISSSTE en 1962",
                  "Diputado por el Estado de Hidalgo al Congreso de la Unión",
                  "Oficial Mayor de la Contaduría Mayor de Hacienda",
                  "Procurador General de Justicia en el Estado de Hidalgo",
                  "Senador de la República por el Estado de Hidalgo.",
                  "Presidente del Comité Ejecutivo Nacional del Partido Revolucionario Institucional del 5 de Diciembre de 1970 al 22 de Febrero de 1972; tiempo durante el cual el Profesor Donaciano Serna Leal fue Gobernador Interino.",
                  "Fue Gobernador del 1 de Abril de 1969 al 31 de Marzo de 1975.",
                  "Los Gobernadores que suplieron al Gral. Rafael Cravioto en distintas épocas de ese periodo, fueron: Francisco Valenzuela, Ramon F. Riveroll, Coronel Simn Cravioto, ",
                  "Francisco Cravioto, Pedro L. Rodríguez y el Lic. Francisco Hernández."
                ]
            },
            {
                nombre:"Dr. Otoniel Miranda Andrade",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/miranda.png",
                periodot:"Fue Gobernador del 1 al 28 de abril de 1975.",
                introduccion:[
                  "Nació en Jacala, Hidalgo.",
                  "Recibió el Título de Médico Cirujano de la Universidad Nacional Autónoma de México."
                ],
                detalle:[
                  "Fue Director de la Clínica de la Policía Bancaria e Industrial de la Ciudad de México",
                  "Jefe de los Servicios Coordinados de Salud Pública en el Estado de Hidalgo",
                  "Director del Instituto de Seguridad y Servicios Sociales de los Trabajadores del Estado (ISSSTE)",
                  "Director del Seguro Social en el Estado.",
                  "Fue Gobernador del 1 al 28 de abril de 1975.",
                  "El Congreso de la Unión, declaró la desaparición de los Poderes del Estado, y el 29 de Abril de 1975 la gran comisión nombró al Licenciado Raúl Lozano Ramírez, Gobernador Provisional."
                ]
            },
            {
                nombre:"Profr. Donaciano Serna Leal",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/serna.png",
                periodot:"Gobernó Interinamente al Estado de Hidalgo, del 5 de Diciembre de 1970 al 22 de Febrero de 1972",
                introduccion:[
                  "Nació en Molango, Hidalgo.",
                  "Maestro de instrucción Primaria, Curso el Doctorado en Pedagogía y la carrera de Contador Público."
                ],
                detalle:[
                  "Fue Recaudador de Rentas del Estado y Secretario General de la Sección XV del SNTE, de 1959 a 1962",
                  "Fue Diputado al Congreso del Estado de 1960 a 1963.",
                  "Gobernó Interinamente al Estado de Hidalgo, del 5 de Diciembre de 1970 al 22 de Febrero de 1972",
                  "Maestro de instrucción Primaria"
                ]
            },
            {
                nombre:"Lic. Carlos Ramírez Guerrero",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/ramirez.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1963 al 31 de Marzo de 1969.",
                introduccion:[
                  "Nació en Molango, Hidalgo el 13 de Marzo de 1909.",
                  "Obtuvo el título de abogado en la Facultad de Jurisprudencia de la Universidad Nacional de México."
                ],
                detalle:[
                  "Distinguido maestro universitario",
                  "Fue Secretario General de Gobierno en 1940",
                  "Oficial Mayor en 1951",
                  "Director del Instituto Científico y Literario Autónomo del Estado (I.C.L.A)",
                  "Diputado Federal al Congreso de la Unión en la XLIII Legislatura",
                  "Senador por el Estado de Hidalgo de 1958 a 1963.",
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1963 al 31 de Marzo de 1969, durante su Gobierno decretó la creación de la Universidad Autónoma de Hidalgo (U.A.H.).",
                  "Al término de su Gobierno fue designado por el Presidente Gustavo Díaz Ordaz, Procurador de Justicia del Distrito y Territorios Federales."
                ]
            },
            {
                nombre:"Mayor Oswaldo Cravioto Cisneros",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/cravioto.png",
                periodot:"Fue Gobernador Interino y Sustituto Constitucional del Estado de Hidalgo entre 1958 y 1963.",
                introduccion:[
                  "Nació en Pachuca, Hidalgo, el 17 de Marzo de 1918.",
                  "Recibió el grado de subteniente en 1939, se graduó como oficial de Estado Mayor en la Escuela Superior de Guerra en 1948."
                ],
                detalle:[
                  "Fue Director General de Seguridad Pública del Estado de Hidalgo.",
                  "Fue Gobernador Interino y Sustituto Constitucional del Estado de Hidalgo entre 1958 y 1963.",
                  "Fue Senador de la República",
                  "Secretario de Organización del Comité Ejecutivo Nacional del Partido Revolucionario Institucional."
                ]
            },
            {
                nombre:"Lic. Alfonso Corona del Rosal",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/corona.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1957 al 31 de Marzo de 1963.",
                introduccion:[
                  "Nació en Ixmiquilpan, Hidalgo, el 1 de Junio de 1909.",
                  "Recibió el Título de Abogado en 1937 en la Universidad Nacional Autónoma de México. En 1923 se graduó de teniente de caballería en el H. Colegio Militar. En 1930 y 1931 cursó los bachilleratos de Ciencias Sociales y Ciencias Biológicas."
                ],
                detalle:[
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1957 al 31 de Marzo de 1963.",
                  "En 1938 fue representante de la Primera Zona Militar y de las Escuelas Militares, de la Asamblea Constituyente del Partido de la Revolución Mexicana, subjefe de la Secretaría de Acción Militar",
                  "Secretario de Trabajo y Previsión Social durante la Presidencia del General Ávila Camacho",
                  "Diputado al Congreso de la Unión en la XLII Legislatura",
                  "Secretario Particular del Lic. Javier Rojo Gómez en el Departamento del Distrito Federal de 1943 a 1946",
                  "Senador de la República por el Estado de Hidalgo a la XL y XLI Legislatura",
                  "Gerente del Banco del Ejército.",
                  "En 1958 fue Presidente del Partido Revolucionario Institucional y Jefe del Departamento del Distrito Federal.",
                  "El Gobernador Interino que cubrió sus Licencias fue el Mayor Oswaldo Cravioto Cisneros."
                ]
            },
            {
                nombre:"Quintín Rueda Villagrán",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rueda.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1951 al 31 de Marzo de 1957.",
                introduccion:[
                  "Nació en Huichapan, Hidalgo"
                ],
                detalle:[
                  "Representó al Estado de Hidalgo en el Congreso de la Unión.",
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1951 al 31 de Marzo de 1957.",
                  "El Licenciado Próspero Macotela Cravioto, fue Gobernador Interino en su periodo.",
                  "Fue subgerente del Banco Ejidal en la Ciudad de México",
                  "Contralor General en la Secretaría de Agricultura y Ganadería",
                  "Promotor del Complejo Industrial de Ciudad Sahagún."
                ]
            },
            {
                nombre:"Lic. Vicente Aguirre del Castillo",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/aguirre.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1945 al 31 de Marzo de 1951.",
                introduccion:[
                  "Nació en Mixquiahuala de Juárez, Hidalgo. ",
                  "Recibió el Título de Licenciado en Derecho en la Universidad Nacional Autónoma de México."
                ],
                detalle:[
                  "Fue Diputado al Congreso del Estado",
                  "Diputado al Congreso de la Unión",
                  "Senador de la República de 1940 a 1946.",
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1945 al 31 de Marzo de 1951."
                ]
            },
            {
                nombre:"José Lugo Guerrero",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lugoguerrero.png",
                periodot:"Gobernó Constitucionalmente al Estado de Hidalgo del 1 de abril de 1941 al 31 de marzo de 1945.",
                introduccion:[
                  "Nació en Huichapan, Hidalgo, el 17 de Septiembre de 1897.",
                  "Recibió el Título de Licenciado en Derecho en la Universidad Nacional Autónoma de México."
                ],
                detalle:[
                  "Fue electo Presidente Municipal de Huichapan 1926 a 1929",
                  "Diputado al Congreso del Estado en las Legislaturas XXVIII y XXXIV (1925-1937).",
                  "En 1926 fue electo por el Congreso del Estado Gobernador Interino, en sustitución del Corl. Matías Rodríguez quien solicitó licencia del 2 al 10 de febrero de ese año.",
                  "De 1930 a 1931 fue Presidente Municipal de Pachuca",
                  "De 1932 a 1934 Diputado al Congreso de la Unión",
                  "En 1940 Senador de la República.",
                  "Gobernó Constitucionalmente al Estado de Hidalgo del 1 de abril de 1941 al 31 de marzo de 1945.",
                  "Su fino trato para con toda la ciudadanía, le ganó el sobrenombre del “Gobernador Caballero”."
                ]
            },
            {
                nombre:"Coronel Otilio Villegas Lora",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/villegas.png",
                periodot:"Gobernador sustituto del Estado de Hidalgo en 1940.",
                introduccion:[
                  "Nació en Zimapán, Hidalgo, el 13 de Diciembre de 1888. ",
                  "Obtuvo el grado de Coronel y en 1916 de General Brigadier."
                ],
                detalle:[
                  "Fue Jefe de las Fuerzas Revolucionarias que operaban en Hidalgo y en 1918 en Puebla.",
                  "En 1922 fue Presidente Municipal de Jacala, Hidalgo.",
                  "Fue Diputado Federal en cuatro Legislaturas y Senador de la República por el Estado de Hidalgo.",
                  "Gobernador sustituto del Estado de Hidalgo en 1940.",
                  "Fue Presidente Municipal de Zimapán de 1944 a 1945."
                ]
            },
            {
                nombre:"Lic. Javier Rojo Gómez",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rojogomez.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1937 al 31 de Marzo de 1941.",
                introduccion:[
                  "Nació en Huichapan, Hidalgo, el 28 de Junio de 1896.",
                  "Recibió el Título de Abogado en 1924, en la Escuela Nacional de Jurisprudencia."
                ],
                detalle:[
                  "Fue electo Diputado al Congreso del Estado en 1920, ocupó el puesto de Secretario General de Gobierno en 1923.",
                  "En 1925 fue Diputado al Congreso de la Unión por el Distrito de Zimapán; en 1930 fue designado Juez de Distrito en materia Administrativa.",
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1937 al 31 de Marzo de 1941.",
                  "En 1940 fue Jefe del entonces Departamento Central, actualmente Gobierno del Distrito Federal.",
                  "Fue nombrado Embajador en Japón e Indonesia, Secretario Gral. de la Confederación Nacional Campesina (C.N.C.) en Agosto de 1962, fue Gobernador del territorio de Quintana Roo en 1965.",
                  "Los Gobernadores Interinos que cubrieron diversas etapas de su mandato fueron: C. Polioptro F. Martínez y Gral. Otilio Villegas Lara."
                ]
            },
            {
                nombre:"Ernesto Viveros Pérez",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/azuara.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1933 al 31 de Marzo de 1937.",
                introduccion:[
                  "Nació en Tetepango, Hidalgo.",
                  "Ilustre maestro y acreditado miembro del magisterio."
                ],
                detalle:[
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1933 al 31 de Marzo de 1937.",
                  "Fue Administrador de Rentas del municipio de Pachuca, y Tesorero General del Estado.",
                  "Fue Presidente Municipal de Pachuca en 1932."
                ]
            },
            {
                nombre:"Bartolomé Vargas Lugo",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/vargas.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1929 al 31 de Marzo de 1933.",
                introduccion:[
                  "Nació en Tulancingo, Hidalgo, en 1890."
                ],
                detalle:[
                  "Fue Oficial Mayor de la Comisión Nacional Agraria, precursora de la Secretaría de la Reforma Agraria.",
                  "Fue Diputado al Congreso de la Unión de 1928 a 1930.",
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1929 al 31 de Marzo de 1933.",
                  "Fue Director-Gerente del Banco Nacional de Crédito Agrícola; Secretario General del Partido Nacional Revolucionario antecedente primario del Partido Revolucionario Institucional (P.R.I.).",
                  "Fue Presidente del Comité Directivo Estatal de la Federación de Partidos del Pueblo Mexicano en el Estado de Hidalgo; y consejero del Departamento de Asuntos Agrarios y Colonización."
                ]
            },
            {
                nombre:"Matías Rodríguez Melgarejo",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rodriguez.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1925 al 31 de Marzo de 1929.",
                introduccion:[
                  "Nació en Tetepango, Hidalgo, el 24 de Febrero de 1876."
                ],
                detalle:[
                  "En 1916 alcanzó el grado de Mayor, obtuvo el grado de Teniente Coronel y posteriormente fue electo al Congreso Constituyente de Querétaro por el Distrito de Tula.",
                  "Fue diputado de 1918 a 1920, y de 1922 a 1924 representando al Distrito de Tula, en el Congreso de la Unión.",
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1925 al 31 de Marzo de 1929.",
                  "Fungieron como Gobernadores Interinos: Fausto Trejo, Lauro Alburquerque, José Lugo Guerrero, Raymundo L. Gómez y Rafael Márquez Vega."
                ]
            },
            {
                nombre:"Nicolás Flores",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/flores.png",
                periodot:"Gobernó al Estado de Hidalgo constitucionalmente a partir del 28 de Junio de 1917 al 31 de Marzo de 1921.",
                introduccion:[
                  "Nació en Pisaflores, Municipio de Jacala, Hidalgo, el 10 de Septiembre de 1873."
                ],
                detalle:[
                  "Fue Gobernador Interino del Estado de Hidalgo en las siguientes fechas: ",
                  "Del 5 de Agosto al 6 de Octubre de 1914",
                  "Del 21 de Octubre al 29 de Noviembre de 1914",
                  "Del 4 de Agosto de 1915 al 5 de Enero de 1917.",
                  "Gobernó al Estado de Hidalgo constitucionalmente a partir del 28 de Junio de 1917 al 31 de Marzo de 1921.",
                  "Los Gobernadores interinos durante su periodo fueron: Ing. Ernesto Castillo, Ing. Gustavo Durán, Pablo Salinas Gil y Alberto Cravioto Galindo."
                ]
            },
            {
                nombre:"Ramón M. Rosales",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rosales.png",
                periodot:"Asumió el cargo de Gobernador Constitucional el 1 de Abril de 1913, renuncia el 3 de Julio del mismo año, al llegar a la Presidencia de la República el Gral. Victoriano Huerta.",
                introduccion:[
                  "Nació en Pachuca, Hgo, el 15 de Agosto de 1872.",
                  "Se recibió como Sociólogo y Filósofo, en el Instituto de Ciencias de Nueva York."
                ],
                detalle:[
                  "Se convirtió en un anti-reeleccionista prestigiado motivado por los Clubs Antirreeleccionistas convocados por Don Camilo Arriaga, prestigiado masón, fue impulsor de esa organización filosófica en el Estado.",
                  "Fue nombrado Gobernador Interino el 4 de Noviembre de 1911, renunciando el 16 de Octubre de 1912.",
                  "Asumió el cargo de Gobernador Constitucional el 1 de Abril de 1913, renuncia el 3 de Julio del mismo año, al llegar a la Presidencia de la República el Gral. Victoriano Huerta.",
                  "Los Gobernadores Interinos que cubrieron el período durante esa etapa convulsiva de la Revolución fueron: Lic. Joaquín González, Lic. Francisco de P. Olvera, Gral. Agustín Sanguines, Lic. Agustín Pérez, Gral. Nicolás Flores, C. Filiberto Rubio, Gral. Fortunato Maycotte, C. Alfredo J. Machuca, C. José L. Aguilar, Lic. Miguel Gómez Noriega, C. Alfredo Rodríguez y Lic. Amador Castañeda J."
                ]
            },
            {
                nombre:"Pedro L. Rodríguez",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/lrodriguez.png",
                periodot:"",
                introduccion:[
                  "Nació en San Pedro Apóstol, Etla, Oaxaca, el 29 de Junio de 1841."
                ],
                detalle:[
                  "Fue nombrado diputado al Congreso Local del Estado de Hidalgo, así como Jefe de la Oficina de Telégrafos de Tulancingo.",
                  "Ocupó el cargo de Gobernador Interino, después de la renuncia del Gral. Rafael Cravioto, del 4 de Noviembre de 1897 al 31 de Marzo de 1901.",
                  "Posteriormente es electo Gobernador del Estado, pasando por 3 periodos de Gobierno: 1901 - 1905; 1905 - 1909; 1909 - 1913, periodo que no concluyó ya que dejó el Gobierno el 16 de Mayo de 1911.",
                  "Durante los periodos de su Gobierno fue suplido en distintas ocasiones por: Lic. Francisco Hernández, Lic. Carlos Sánchez Mejorada, C. Gabriel Rivera, Lic. Francisco Hernández, Lic. Luis Hernández, Lic. Francisco Hernández, Lic. Joaquín González, Lic. Jesús Silva Espinoza, C. Emilio Asiain, Lic. Ramón M. Rosales, C. Filiberto Rubio, Lic. Amador Castañeda, Lic. Manuel Lara."
                ]
            },
            {
                nombre:"Francisco Cravioto",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/franciscocravioto.png",
                periodot:"Fue Gobernador Interino en 1877, estando la administración a cargo del General Rafael Cravioto.",
                introduccion:[
                  "Nació en Huauchinango, Puebla."
                ],
                detalle:[
                  "Participó en la batalla de Puebla del 5 de Mayo de 1862 con el grado de Coronel.",
                  "Obtuvo el grado de General y fue nombrado comandante militar del Estado de Hidalgo.",
                  "Fue Gobernador Interino en 1877, estando la administración a cargo del General Rafael Cravioto.",
                  "Gobernó al Estado de Hidalgo del 1 de abril de 1885 al 31 de marzo de 1889."
                ]
            },
            {
                nombre:"Simón Cravioto",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/simoncravioto.png",
                periodot:"Gobernó al Estado de Hidalgo del 1 de Abril de 1881 al 31 de Marzo de 1885.",
                introduccion:[
                  "Nació en Huauchinango, Puebla."
                ],
                detalle:[
                  "Fue coronel en el Ejército Republicano, participó en la Batalla del 5 de Mayo de 1862 en Puebla, durante la Invasión Francesa.",
                  "Gobernó al Estado de Hidalgo del 1 de Abril de 1881 al 31 de Marzo de 1885."
                ]
            },
            {
                nombre:"Rafael Cravioto",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/rafaelcravioto.png",
                periodot:"Fue Gobernador interino en el año de 1876",
                introduccion:[
                  "Nació en Huauchinango, Puebla, el 24 de Octubre de 1829."
                ],
                detalle:[
                  "En el año de 1847 fue capitán de la Guardia Nacional del Batallón de Huauchinango.",
                  "Fue Senador de la República por el Estado de Hidalgo.",
                  "Fue Gobernador interino en el año de 1876",
                  "Posteriormente tuvo 4 periodos más de Gobierno:",
                  "1877-1881;",
                  "1889-1893;",
                  "1893-1897;",
                  "1897-1901, periodo que no concluyó ya que renunció el 3 de Noviembre de 1897.",
                  "Los Gobernadores que suplieron al Gral. Rafael Cravioto en distintas épocas de ese periodo, fueron: Francisco Valenzuela, Ramón F. Riveroll, Coronel Simn Cravioto, Francisco Cravioto, Pedro L. Rodríguez y el Lic. Francisco Hernández."
                ]
            },
            {
                nombre:"Justino Fernández",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/fernandez.png",
                periodot:"Fue electo Gobernador del Estado de Hidalgo, del 1 de Abril de 1873 al 20 de Noviembre de 1876.",
                introduccion:[
                  "Nació en la Cd. de México, el 22 de Junio de 1828.",
                  "Recibió el título de Abogado en 1853."
                ],
                detalle:[
                  "En el año de 1857 fue Diputado al Congreso de la Unión, así como Regidor de la Ciudad de México.",
                  "Fue diputado por San Luis Potosí y Director de la Escuela Nacional de Jurisprudencia.",
                  "En 1901, fue nombrado Ministro de Justicia e Instrucción Pública, del Gobierno de Porfirio Díaz.",
                  "Fue electo Gobernador del Estado de Hidalgo, del 1 de Abril de 1873 al 20 de Noviembre de 1876.",
                  "Durante su gobierno, hubo 3 Gobernadores Interinos: Lic. Joaquín Claro Tapia, Gral. Rafael Cravioto y el C. Manuel Ayala."
                ]
            },
            {
                nombre:"Antonio Tagle",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/tagle.png",
                periodot:"Su período de Gobierno fue a partir del 28 de Mayo de 1869 al 31 de Marzo de 1873.",
                introduccion:[
                  "Nació en la Cd. de México."
                ],
                detalle:[
                  "Fue Diputado al Congreso de la Unión, representando al Estado de Hidalgo.",
                  "Su período de Gobierno fue a partir del 28 de Mayo de 1869 al 31 de Marzo de 1873.",
                  "Durante su Gobierno, fungieron como Gobernadores Interinos el Lic. Francisco de Asís Osorio y el Lic. Francisco de P. Olvera.",
                  "El Lic. Francisco de Asís Osorio, Abogado de profesión y Comandante Militar del Segundo Distrito del Estado de México, Presidente del Primer Tribunal Superior de Justicia del Estado de Hidalgo. Nombrado como Gobernador Interino al estado del 28 de Enero al 2 de Agosto de 1872."
                ]
            },
            {
                nombre:"Juan Crisóstomo Doria",
                img: "https://cdn.hidalgo.gob.mx/gobierno/images/exgobernadores/doria.png",
                periodot:"Su periodo de Gobierno Provisional fue del 27 de Enero al 27 de Mayo de 1869.",
                introduccion:[
                  "Nació Borbón, Tamaulipas, el 26 de Enero de 1839.",
                  "En el año de 1862 se recibió como Abogado en la Ciudad de Monterrey."
                ],
                detalle:[
                  "En 1862 fue nombrado Secretario de Gobierno del Estado de Nuevo León.",
                  "En 1865 fue Jefe Político y Comandante Militar de Nuevo León. Con el grado de coronel fue nombrado Oficial Mayor de la Secretaría de Guerra.",
                  "Fue nombrado Diputado al Congreso de la Unión por el Distrito de Linares.",
                  "Su periodo de Gobierno Provisional fue del 27 de Enero al 27 de Mayo de 1869."
                ]
            }
        ]
  }
}
class ExGoberDetalle extends Component {
  
  render() {
    return (
      <div id="contentglobal" >
        {this._renderExGoberDetalle()}
      </div>
    );
  }
  _renderExGoberDetalle = () => {

    const parrafo = (texto) => {
      return texto.map((text) => {
        return(
          <p>{text}</p>
        );
      });
    };
    return ExGobernadoresIndex.exgobernadores.exgober.map(({nombre,periodot,introduccion,detalle,img},i)=>{
      if(this.props.match.params.nombre === nombre){
        return(
          <div className="ExGoberDetalle">
            <div className="ExGober">
              <div style={{backgroundImage: "url("+img+")", backgroundSize: "cover"}} alt="ExGober" className={"ExGober_img"}/>
            </div>
            <div className={"ExGober_name"}>
              {nombre}
            </div>
            <div className={"ExGober_cargo"}>
              {periodot}
            </div>
            <div className={"ExGober_intro"}>
              {parrafo(introduccion)}
            </div>
         
            <div className="semblanza">
              <h6 style={{fontSize: '25px'}}>Detalle</h6>
              <hr/>
              <div className="textSemblanza">
               {parrafo(detalle)}
              </div>
            </div>
          </div>
        );
      }
    });
  }
}

export default ExGoberDetalle;