import React, { Component } from 'react';
import { connect } from 'react-redux';
import Globals from '../globals';

class Cluster extends Component {

	state = {
		elements: []
	};

	_count = -1;
	_event = null;

	componentDidMount(){
		const elements = document.getElementsByClassName('groupsvg');
		this.setState({ elements: elements }, () => {
			for (var i = 0; i < elements.length; i++) {
				elements[i].addEventListener("click",this._onClicHex.bind(this, i));
			}
			this._onClicHex(0);
			window.addEventListener('scroll', this.handleScroll);
		});
		
	}

	componentWillUnmount(){
		this._deleteHandleEvent();
	}

	handleScroll = (e)=>{
		if(window.scrollY > (window.innerHeight/2)){
			this._deleteHandleEvent();
		}else if(this._event === null){
			this._handleEvent();
		}
	};

	_onClicHex = (i) => {
		const { elements } = this.state;
		this._deleteHandleEvent();
		this._count = i;
		for (var i = 0; i < elements.length; i++) {
			elements[i].classList.remove("active");
		}
		elements[this._count].classList.add("active");
		this.props.setStateGlobal({ clusterSelector: this._count,click:true });
		this._handleEvent();
	};

	_handleEvent = () => {
		const { elements } = this.state;
		this._event = setInterval(() => {
			if(this._count > -1){
				elements[this._count].classList.remove("active");
			}
			this._count++;
			if(this._count === elements.length){
				this._count = 0;
			}
			elements[this._count].classList.add("active");
			this.props.setStateGlobal({ clusterSelector: this._count , click: false});
		},7000);
	};

	_deleteHandleEvent = () => {
		clearInterval(this._event);
		this._event = null;
	};

	render(){
		return (
			<div className={"cluster_container"}>
				<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				 viewBox="0 0 250 610" style={{enableBackground:"new 0 0 228.9 625.4"}} >

				 	<g className="groupsvg" id="Gobernador">
						<polygon className="st0" points="79.6,19.6 47.5,19.6 31.5,47.4 47.5,75.2 79.6,75.2 95.6,47.4 	"/>
						<g transform="translate(0,-952.36218)">
							<path className="st1" d="M52.6,987c-2.3,0-3.6,0.8-4.5,1.4c-0.9,0.6-1.4,1.1-3.2,1.1c-0.5,0-0.8,0.4-0.8,0.8v6.8
								c0,0.5,0.4,0.8,0.8,0.8c2.2,0,3.6-0.7,4.7-1.4c0.8-0.5,1.4-0.8,2.1-1v16.8c0,0.5,0.4,0.9,0.8,0.9c0.5,0,0.9-0.4,0.9-0.8
								c0,0,0,0,0,0v-24.6C53.4,987.4,53.1,987,52.6,987z M62.3,987c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4
								S64.2,987,62.3,987z M72,987c-0.4,0-0.8,0.4-0.8,0.8v24.6c0,0.5,0.4,0.9,0.8,0.9c0.5,0,0.9-0.4,0.9-0.8c0,0,0,0,0,0v-16.8
								c0.7,0.2,1.3,0.5,2.1,1c1,0.6,2.4,1.4,4.7,1.4c0.5,0,0.8-0.4,0.8-0.8v-6.8c0-0.5-0.4-0.8-0.8-0.8c-1.7,0-2.3-0.5-3.2-1.1
								C75.7,987.8,74.4,987,72,987C72.1,987,72,987,72,987z M62.3,988.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7
								c-0.9,0-1.7-0.7-1.7-1.7C60.6,989.5,61.4,988.7,62.3,988.7z M51.7,988.8v5.1c-1.3,0.2-2.2,0.8-3,1.3c-0.8,0.5-1.7,0.9-2.9,1v-5.1
								c1.6-0.2,2.5-0.8,3.3-1.3C49.8,989.3,50.5,988.9,51.7,988.8z M72.9,988.8c1.3,0.1,1.9,0.5,2.6,1.1c0.8,0.5,1.7,1.2,3.3,1.3v5.1
								c-1.3-0.1-2.2-0.5-2.9-1c-0.8-0.5-1.7-1.1-3-1.3V988.8z M62.3,994.7c-3.4,0-6.3,2.1-6.7,5.1c-0.8,0.2-1.3,0.8-1.3,1.6v1.7
								c0,0.9,0.7,1.7,1.6,1.7l1.4,7.8c0.1,0.4,0.4,0.7,0.8,0.7h5.1c0.5,0,0.9-0.4,0.9-0.8c0-0.5-0.4-0.9-0.8-0.9c0,0,0,0,0,0h-4.4
								l-1.2-6.8H67l-1.3,7.5c-0.1,0.5,0.2,0.9,0.6,1c0.5,0.1,0.9-0.2,1-0.6c0,0,0,0,0-0.1l1.4-7.8c0.9,0,1.6-0.8,1.6-1.7v-1.7
								c0-0.8-0.6-1.5-1.3-1.6C68.7,996.8,65.7,994.7,62.3,994.7z M62.3,996.4c2.6,0,4.6,1.5,5,3.4h-10C57.8,997.9,59.8,996.4,62.3,996.4
								z M56,1001.5h12.7v1.7H56V1001.5z"/>
						</g>
					</g>
					<g className="groupsvg" id="Gobierno">
						<polygon className="st0" points="129.5,48.7 97.4,48.7 81.4,76.4 97.4,104.2 129.5,104.2 145.5,76.4 	"/>
						<g>
							<g>
								<path className="st1" d="M119.9,89.1H97.1l3.8-22.7l0.4,0c1.8,0,4.8,0,5.3,0l0.8-0.4l-2.8,16.7h4.5l0.9-5.4h6.5l-0.9,5.5h5.5
									L119.9,89.1z M98.2,88.1h20.8l0.7-4.4h-5.5l0.9-5.5h-4.4l-0.9,5.4h-6.5l2.7-16.3c-0.7,0-2,0-4.5,0L98.2,88.1z"/>
							</g>
							<g>
								<path className="st1" d="M119.7,84.1l2.8-16.7h-4.5l-0.9,5.4h-6.5l0.9-5.5h-5.5l1.1-6.4h22.9l-3.8,22.7l-0.4,0c-1.8,0-4.8,0-5.3,0
									L119.7,84.1z M122,82.7c0.8,0,1.9,0,3.4,0l3.4-20.7h-20.8l-0.7,4.4h5.5l-0.9,5.5h4.4l0.9-5.4h6.5L121,82.7
									C121.2,82.7,121.6,82.7,122,82.7z"/>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Publica_x5F_Estatal">
						<polygon className="st0" points="180.6,76.6 148.5,76.6 132.5,104.4 148.5,132.2 180.6,132.2 196.6,104.4 	"/>
						<g>
							<g>
								<path className="st1" d="M181,118.6h-32.8V116H181V118.6z M149.2,117.6H180V117h-30.8V117.6z M178.2,115.9H151v-2.6h27.2V115.9z
									 M152,114.9h25.2v-0.6H152V114.9z M175.8,113.1h-5.4v-12.5h5.4V113.1z M171.5,112.1h3.4v-10.5h-3.4V112.1z M167.5,113.1h-5.4
									v-12.5h5.4V113.1z M163.1,112.1h3.4v-10.5h-3.4V112.1z M158.8,113.1h-5.4v-12.5h5.4V113.1z M154.4,112.1h3.4v-10.5h-3.4V112.1z
									 M178.2,100.4H151v-2.6h27.2V100.4z M152,99.4h25.2v-0.6H152V99.4z M181,97.6h-32.8v-2.4l16.4-8.2l16.4,8.2V97.6z M149.2,96.6
									H180v-0.8l-15.4-7.7l-15.4,7.7V96.6z"/>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Finanzas">
						<polygon className="st0" points="130.5,106.9 98.4,106.9 82.4,134.6 98.4,162.4 130.5,162.4 146.5,134.6 	"/>
						<g>
							<g>
								<path className="st1" d="M114.4,151.1c-1.2,0-2-0.8-2-1.9v-1.6c-2.1-0.3-3.6-0.8-4.8-1.8c-1.9-1.5-2.9-3.8-2.9-6.9
									c0-0.7,0.2-1.2,0.6-1.6c0.6-0.8,2-0.9,2.9,0c0.4,0.3,0.7,0.9,0.7,1.6c0,1.6,0.4,2.8,1.4,3.6c0.5,0.4,1.2,0.8,2.2,1v-8.3
									c-2.2-0.6-3.8-1.3-4.9-2.3c-1.5-1.2-2.2-2.9-2.2-5c0-2.3,0.8-4.3,2.5-5.7c1.3-1.1,2.8-1.7,4.6-1.9v-0.9c0-1.2,0.8-1.9,2-1.9
									s2,0.8,2,1.9v0.9c1.8,0.2,3.3,0.9,4.5,1.9c1.5,1.3,2.2,3,2.2,5.1c0,0.7-0.2,1.3-0.7,1.7c-0.4,0.4-0.9,0.6-1.5,0.6
									c-0.6,0-1-0.2-1.5-0.6c-0.4-0.4-0.7-1-0.7-1.6c0-1.1-0.4-1.9-1.2-2.5c-0.3-0.3-0.8-0.4-1.3-0.6v7.7c2.4,0.7,4.1,1.5,5.2,2.4
									c1.6,1.2,2.4,3,2.4,5.2c0,2.3-0.9,4.3-2.6,5.7c-1.4,1.1-3,1.9-5,2.2v1.5C116.4,150.3,115.6,151.1,114.4,151.1z M113.5,147.7v1.5
									c0,0.6,0.3,0.9,1,0.9s1-0.3,1-0.9v-1.4l-0.7,0l0,0l-0.1,0l-0.5,0l0.1-0.1L113.5,147.7z M112.8,145.7l1.7,1.8l1.7-1.8l-1.7-1.5
									L112.8,145.7z M116.4,145.9v0.7c1.7-0.3,3.1-0.9,4.3-1.9c1.5-1.3,2.3-3,2.3-5c0-1.9-0.7-3.3-2-4.4c-1-0.8-2.5-1.5-4.6-2.2v0.8
									l0.1,0v1.4c1.4,0.4,2.4,0.9,3,1.4c0.8,0.7,1.2,1.8,1.2,3.3c0,1.5-0.6,2.6-1.8,3.5c-0.7,0.5-1.5,0.9-2.4,1.1L116.4,145.9
									L116.4,145.9z M106.8,137.7c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.9c0,2.8,0.8,4.8,2.5,6.1c1,0.8,2.4,1.3,4.2,1.5v-1.2
									l0.1,0.1v-0.9c-1.2-0.2-2.2-0.6-2.8-1.2c-1.2-1-1.8-2.5-1.7-4.4c0-0.3-0.1-0.6-0.3-0.9C107.5,137.9,107.2,137.7,106.8,137.7z
									 M113.5,143.7c0.1,0,0.1,0,0.2,0l1,0.1l0.6-0.1c0.1,0,0.1,0,0.2,0v-7.7c-0.1,0-0.2-0.1-0.3-0.1l-1-0.2l0.2-0.1l-0.8-0.2V143.7z
									 M116.4,136.4v7.2c0.7-0.2,1.3-0.5,1.8-0.9c1-0.7,1.4-1.5,1.4-2.7c0-1.2-0.3-2-0.9-2.5C118.5,137.3,117.9,136.8,116.4,136.4z
									 M112.8,133.3l1.8,2.2l1.6-1.3l-1.6-2.2L112.8,133.3z M112.5,121.4c-1.5,0.2-2.9,0.8-4,1.7c-1.5,1.2-2.2,2.8-2.2,4.9
									c0,1.7,0.6,3.2,1.9,4.2c1,0.8,2.4,1.5,4.3,2v-2.1c-1.3-0.4-2.2-0.9-2.7-1.5c-0.7-0.8-1.1-1.7-1.1-2.9c0-1.4,0.5-2.5,1.7-3.3
									c0.6-0.5,1.3-0.8,2.1-1V121.4z M114.8,131.7l0.5,0.1v-7.6c0,0-0.1,0-0.1,0l-1.2-0.1l0,0l-0.4,0c-0.1,0-0.1,0-0.2,0v7.1
									c0.1,0,0.2,0.1,0.4,0.1L114.8,131.7L114.8,131.7z M112.5,124.4c-0.6,0.2-1.1,0.4-1.6,0.7c-0.8,0.6-1.2,1.4-1.2,2.5
									c0,0.9,0.3,1.6,0.8,2.2c0.4,0.4,1.1,0.8,2,1.1V124.4z M116.4,123.4c0.7,0.1,1.4,0.4,1.9,0.8c1,0.7,1.5,1.8,1.6,3.2
									c0,0.4,0.1,0.7,0.4,0.9c0.3,0.3,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.4-0.6,0.4-1c0-1.8-0.6-3.3-1.8-4.4
									c-1-0.9-2.3-1.5-3.9-1.7v1.2l-0.1-0.1V123.4z M112.8,122.3l1.7,1.5l1.7-1.5l-1.7-1.7L112.8,122.3z M114.8,120.3l0.6,0
									c0,0,0,0,0,0v-0.8c0-0.6-0.3-0.9-1-0.9s-1,0.3-1,0.9v0.8c0,0,0,0,0,0l0.6,0l0,0l0.4,0L114.8,120.3L114.8,120.3z"/>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Desarrollo_x5F_Social">
						<polygon className="st0" points="79.3,136 47.2,136 31.2,163.7 47.2,191.5 79.3,191.5 95.3,163.7 	"/>
						<g>
							<g>
								<g>
									<path className="st1" d="M63.2,162.8c-3.4,0-6.1-2.8-6.1-6.1c0-3.3,2.8-6.1,6.1-6.1c3.4,0,6.1,2.8,6.1,6.1
										C69.4,160.1,66.6,162.8,63.2,162.8z M63.2,151.8c-2.7,0-4.8,2.2-4.8,4.8c0,2.7,2.2,4.8,4.8,4.8s4.8-2.2,4.8-4.8
										S65.9,151.8,63.2,151.8z"/>
								</g>
								<g>
									<path className="st1" d="M74.2,175.5H52.2c-0.4,0-0.6-0.3-0.6-0.6c0-6.4,5.2-11.6,11.6-11.6s11.6,5.2,11.6,11.6
										C74.8,175.2,74.6,175.5,74.2,175.5z M52.9,174.2h20.7c-0.3-5.4-4.8-9.7-10.3-9.7C57.7,164.5,53.2,168.8,52.9,174.2z"/>
								</g>
								<g>
									<path className="st1" d="M71,162.8c-0.8,0-1.5-0.1-2.2-0.4c-0.3-0.1-0.5-0.5-0.4-0.8c0.1-0.3,0.5-0.5,0.8-0.4
										c0.5,0.2,1.1,0.3,1.7,0.3c2.7,0,4.8-2.2,4.8-4.8c0-2.7-2.2-4.8-4.8-4.8c-0.6,0-1.2,0.1-1.7,0.3c-0.3,0.1-0.7,0-0.8-0.4
										c-0.1-0.4,0-0.7,0.4-0.8c0.7-0.3,1.4-0.4,2.2-0.4c3.4,0,6.1,2.8,6.1,6.1C77.1,160.1,74.3,162.8,71,162.8z"/>
								</g>
								<g>
									<path className="st1" d="M81.9,175.9h-5.1c-0.4,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h4.4c-0.1-2.6-1.2-5-3-6.8
										c-1.9-1.9-4.5-2.9-7.3-2.9h-0.5c-0.4,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6H71c3.2,0,6.1,1.2,8.2,3.2c2.2,2.1,3.4,5.1,3.4,8.4
										C82.5,175.7,82.3,175.9,81.9,175.9z"/>
								</g>
								<g>
									<path className="st1" d="M55.8,162.8c-3.4,0-6.1-2.8-6.1-6.1c0-3.3,2.8-6.1,6.1-6.1c0.8,0,1.5,0.1,2.2,0.4c0.3,0.1,0.5,0.5,0.4,0.8
										c-0.1,0.3-0.5,0.5-0.8,0.4c-0.5-0.2-1.1-0.3-1.7-0.3c-2.7,0-4.8,2.2-4.8,4.8c0,2.7,2.2,4.8,4.8,4.8c0.6,0,1.2-0.1,1.7-0.3
										c0.3-0.1,0.7,0,0.8,0.4c0.1,0.3,0,0.7-0.4,0.8C57.3,162.7,56.5,162.8,55.8,162.8z"/>
								</g>
								<g>
									<path className="st1" d="M50.4,175.9h-5.7c-0.4,0-0.6-0.3-0.6-0.6c0-3.2,1.2-6.2,3.4-8.4c2.1-2.1,5.1-3.2,8.2-3.2h0.5
										c0.4,0,0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6h-0.5c-2.8,0-5.4,1-7.3,2.9c-1.8,1.7-2.8,4.1-3,6.8h5c0.4,0,0.6,0.3,0.6,0.6
										S50.8,175.9,50.4,175.9z"/>
								</g>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Obras_x5F_Publicas">
						<polygon className="st0" points="130.5,165.2 98.4,165.2 82.4,192.9 98.4,220.7 130.5,220.7 146.5,192.9 	"/>
						<g>
							<g>
								<path className="st1" d="M101.2,203.7c-1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9C103.1,204.5,102.3,203.7,101.2,203.7z
									 M101.2,206.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6C101.8,205.9,101.6,206.2,101.2,206.2L101.2,206.2z"
									/>
								<path className="st1" d="M118,205.6c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9s-0.8-1.9-1.9-1.9C118.8,203.7,118,204.5,118,205.6z
									 M120.5,205.6c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6C120.2,204.9,120.5,205.2,120.5,205.6z"/>
								<path className="st1" d="M105.6,190.7c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4v-0.6c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6
									v0.6c0,0.2,0.1,0.3,0.2,0.4C105.2,190.6,105.4,190.7,105.6,190.7L105.6,190.7z"/>
								<path className="st1" d="M105.6,193.8c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4v-0.6c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6
									v0.6c0,0.2,0.1,0.3,0.2,0.4C105.2,193.7,105.4,193.8,105.6,193.8L105.6,193.8z"/>
								<path className="st1" d="M133.8,185.7c-0.1,0-0.2,0-0.2,0h-9.2c-0.2,0-0.3,0.1-0.4,0.2l-5.3,5.3c-0.8-0.4-1.6-0.6-2.4-0.6v-2.4
									c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1l-3.8-8.6l0,0l-0.9-2.1c-0.2-0.4-0.6-0.7-1.1-0.7h-12
									c-0.7,0-1.2,0.6-1.2,1.2v16.8c0,1.4,1.1,2.5,2.5,2.5h0.6v5c-1.7,0-3.1,1.4-3.1,3.1v1.2c0,1.7,1.4,3.1,3.1,3.1h21.1
									c1.7,0,3.1-1.4,3.1-3.1v-1.2c0-1.5-1-2.7-2.5-3v-5.7c0-0.8-0.2-1.7-0.6-2.4l4.4-4.4h4.8v10.4l-5.5,8.5c-0.1,0.2-0.1,0.4,0,0.6
									c0.1,0.2,0.3,0.3,0.5,0.3h5.6c1.7,0,3.1-1.4,3.1-3.1v-19.9C134.1,186.1,134,185.8,133.8,185.7z M132.9,199.4h-1.2v-10.3l1.2-1.2
									V199.4z M120.4,195.6h-4.3v-3.7C118.3,191.9,120.1,193.5,120.4,195.6z M120.5,201.8h-6.8v-5h6.8V201.8z M107.4,198.7h5v1.2h-5
									V198.7z M112.4,197.5h-5v-0.6h5V197.5z M107.4,201.2h5v0.6h-5V201.2z M114.6,187.6h-7.1v-7.5h3.8L114.6,187.6z M98.1,180.1h5.6
									l0,7.5h-5.6V180.1z M98.1,194.4v-5.6h5.6c0.7,0,1.2-0.6,1.2-1.2v-7.5c0-0.7-0.6-1.2-1.2-1.2h-5.6v-1.2h12l0.6,1.2h-3.3
									c-0.7,0-1.2,0.6-1.2,1.2v7.5c0,0.7,0.6,1.2,1.2,1.2h7.5l0,2.5l0,0v4.3h-8.7c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6h-5.6
									C98.7,195.6,98.1,195.1,98.1,194.4z M101.2,196.9h5v5h-5V196.9z M123,204.9v1.2c0,1-0.8,1.9-1.9,1.9H100c-1,0-1.9-0.8-1.9-1.9
									v-1.2c0-1,0.8-1.9,1.9-1.9h21.1C122.1,203.1,123,203.9,123,204.9z M119.6,191.9l4.5-4.5l0.6,1.2l-4.2,4.2
									C120.2,192.4,119.9,192.1,119.6,191.9L119.6,191.9z M125.8,188.2l-0.6-1.2h6.8l-1.2,1.2H125.8z M132.9,206.2c0,1-0.8,1.9-1.9,1.9
									h-4.5l4.8-7.5h1.5V206.2z"/>
								<path className="st1" d="M100,183.2c0.2,0,0.3-0.1,0.4-0.2l0.6-0.6c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.9,0l-0.6,0.6
									c-0.2,0.2-0.2,0.4-0.1,0.7C99.5,183.1,99.7,183.2,100,183.2z"/>
								<path className="st1" d="M101.4,183.4l-1.9,1.9c-0.2,0.2-0.2,0.4-0.2,0.6c0.1,0.2,0.2,0.4,0.4,0.4s0.4,0,0.6-0.2l1.9-1.9
									c0.2-0.2,0.2-0.6,0-0.9C102,183.1,101.6,183.1,101.4,183.4L101.4,183.4z"/>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Desarrollo_x5F_Economico">
						<polygon className="st0" points="180.6,194.1 148.5,194.1 132.5,221.9 148.5,249.6 180.6,249.6 196.6,221.9 	"/>
						<g>
							<path className="st1" d="M150.6,212.8c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0.2c-1.1,1.8-2,3.7-2.4,5.8c0,0.1,0,0.2,0.1,0.3
								c0.1,0.1,0.2,0.2,0.3,0.2c1.6,0.2,2.8,1.5,2.8,3.2c0,1.6-1.2,3-2.8,3.2c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
								c0.4,2.1,1.2,4,2.4,5.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1c1.3-1,3.1-0.9,4.2,0.2c1.2,1.2,1.3,3,0.2,4.2
								c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.2,0.2,0.3c1.8,1.1,3.7,2,5.8,2.4c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3
								c0.2-1.6,1.5-2.8,3.2-2.8s3,1.2,3.2,2.8c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c2.1-0.4,4-1.2,5.8-2.4
								c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2-0.1-0.3c-1-1.3-0.9-3.1,0.2-4.2c1.2-1.2,3-1.3,4.2-0.2c0.1,0.1,0.2,0.1,0.3,0.1
								c0.1,0,0.2-0.1,0.3-0.2c1.1-1.8,2-3.7,2.4-5.8c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-1.6-0.2-2.8-1.5-2.8-3.2
								c0-1.6,1.2-3,2.8-3.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c-0.4-2.1-1.2-4-2.4-5.8c-0.1-0.1-0.2-0.2-0.3-0.2
								c-0.1,0-0.2,0-0.3,0.1c-1.3,1-3.1,0.9-4.2-0.2c-1.2-1.2-1.3-3-0.2-4.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.3
								c-1.8-1.1-3.7-2-5.8-2.4c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,1.6-1.5,2.8-3.2,2.8s-3-1.2-3.2-2.8
								c0-0.1-0.1-0.2-0.2-0.3s-0.2-0.1-0.3-0.1c-2.1,0.4-4,1.2-5.8,2.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.2,0.1,0.3
								c1,1.3,0.9,3.1-0.2,4.2C153.7,213.7,151.9,213.8,150.6,212.8z M156,208.1c1.5-0.9,3-1.5,4.7-1.9c0.4,1.8,2,3.1,3.9,3.1
								c1.9,0,3.5-1.3,3.9-3.1c1.7,0.4,3.2,1.1,4.7,1.9c-1,1.6-0.8,3.7,0.6,5c1.3,1.3,3.4,1.6,5,0.6c0.9,1.5,1.5,3,1.9,4.7
								c-1.8,0.4-3.1,2-3.1,3.9c0,1.9,1.3,3.5,3.1,3.9c-0.4,1.7-1.1,3.2-1.9,4.7c-1.6-1-3.7-0.8-5,0.6c-1.3,1.3-1.6,3.4-0.6,5
								c-1.5,0.9-3,1.5-4.7,1.9c-0.4-1.8-2-3.1-3.9-3.1c-1.9,0-3.5,1.3-3.9,3.1c-1.7-0.4-3.2-1.1-4.7-1.9c1-1.6,0.8-3.7-0.6-5
								c-1.3-1.3-3.4-1.6-5-0.6c-0.9-1.5-1.5-3-1.9-4.7c1.8-0.4,3.1-2,3.1-3.9c0-1.9-1.3-3.5-3.1-3.9c0.4-1.7,1.1-3.2,1.9-4.7
								c1.6,1,3.7,0.8,5-0.6C156.8,211.8,157,209.7,156,208.1z"/>
							<path className="st1" d="M164.6,222.7L164.6,222.7c1,0,1.7,0.7,1.7,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
								c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4c0,1.2,0.9,2.2,2,2.4v0.5c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-0.5
								c1.2-0.2,2.1-1.2,2.1-2.5c0-1.4-1.1-2.5-2.5-2.5h-0.1c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
								c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4c0-1.2-0.9-2.2-2.1-2.4v-0.5c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v0.5
								c-1.2,0.2-2.1,1.2-2.1,2.4C162.1,221.6,163.2,222.7,164.6,222.7z"/>
							<path className="st1" d="M164.7,230.2c4.3,0,7.8-3.5,7.8-7.8c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8
								C156.8,226.6,160.3,230.2,164.7,230.2z M164.7,215.3c3.9,0,7,3.1,7,7c0,3.9-3.1,7-7,7c-3.9,0-7-3.1-7-7
								C157.7,218.5,160.8,215.3,164.7,215.3z"/>
						</g>
					</g>
					<g className="groupsvg" id="Medio_x5F_Ambientee">
						<polygon className="st0" points="130.5,223.3 98.4,223.3 82.4,251.1 98.4,278.9 130.5,278.9 146.5,251.1 	"/>
						<g>
							<path className="st1" d="M96.5,268c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l-0.1-12c0-0.2,0.1-0.3,0.2-0.4l7.9-4.4
								c0.2-0.1,0.4,0,0.6,0.1l5.3,7.9c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.3L96.7,268C96.7,268,96.6,268,96.5,268z M96.9,255.8
								l0.1,11l12.1-7.8l-4.8-7.2L96.9,255.8z"/>
							<path className="st1" d="M116.3,254.2c-0.1,0-0.1,0-0.2,0l-3.6-0.3c-0.2,0-0.4-0.2-0.4-0.5c0-0.2,0.2-0.4,0.5-0.4l3.6,0.3
								c0.5,0,0.9-0.3,1-0.8c0,0,0,0,0,0c0.1-0.5-0.2-0.9-0.7-1c0,0-0.1,0-0.1,0l-5.7-0.6c-1.7-0.2-3.4,0.2-4.9,1.1l-0.5,0.3
								c-0.2,0.1-0.5,0.1-0.6-0.1c-0.1-0.2-0.1-0.5,0.1-0.6c0,0,0,0,0.1,0l0.5-0.3c1.6-1,3.5-1.4,5.4-1.2l5.7,0.6c1,0.1,1.7,1,1.5,2
								C117.9,253.6,117.1,254.2,116.3,254.2z"/>
							<path className="st1" d="M119.4,254.2c-0.2,0-0.4-0.1-0.5-0.4s0.1-0.4,0.4-0.5l0,0l7.6-1.6c0.2,0,0.5,0.1,0.5,0.3
								c0,0.2-0.1,0.5-0.3,0.5L119.4,254.2C119.4,254.2,119.4,254.2,119.4,254.2z"/>
							<path className="st1" d="M115.8,259.8c-0.9,0-1.8-0.1-2.7-0.3l-4.3-1.1c-0.2-0.1-0.4-0.3-0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.3l4.3,1.1
								c2.1,0.5,4.4,0.4,6.4-0.5l7.7-3.3c0.6-0.3,0.9-0.9,0.6-1.5c-0.2-0.5-0.7-0.8-1.2-0.7c-0.2,0-0.4-0.2-0.5-0.4
								c0-0.2,0.2-0.4,0.4-0.5c0.9-0.1,1.8,0.5,2.1,1.4c0.3,1-0.2,2.1-1.1,2.5l-7.7,3.3C118.8,259.5,117.3,259.8,115.8,259.8z"/>
							<path className="st1" d="M126.1,251.5c-0.2,0-0.3-0.1-0.4-0.3c-1-2.4-4.3-4.1-8.1-4.1c-2.6,0-5.2,0.9-6.7,2.3
								c-0.1,0.2-0.4,0.2-0.6,0.1c-0.2-0.1-0.2-0.4-0.1-0.6c0,0,0.1-0.1,0.1-0.1c1.8-1.6,4.4-2.5,7.3-2.5c4.1,0,7.7,1.9,8.9,4.6
								c0.1,0.2,0,0.4-0.2,0.5C126.2,251.5,126.1,251.5,126.1,251.5z"/>
							<path className="st1" d="M98.2,257.8c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.1-0.3,0.3-0.4l6.1-3.5c0.2-0.1,0.5-0.1,0.6,0.1
								c0.1,0.2,0.1,0.5-0.1,0.6c0,0,0,0,0,0l-6.1,3.5C98.4,257.7,98.3,257.8,98.2,257.8z"/>
							<path className="st1" d="M118.8,247.2c-0.2,0-0.4-0.2-0.4-0.4V241c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v5.8
								C119.2,247,119,247.2,118.8,247.2z"/>
							<path className="st1" d="M120.3,241.1c-0.1,0-0.1,0-0.2-0.1c-0.8-0.5-1.4-1.3-1.7-2.3c-0.2-1,0.1-2,0.8-2.8c1.2-1.6,3.1-2,5.6-1.1
								c1.2,0.4,2.2,1,3.2,1.7c0.2,0.1,0.2,0.4,0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-5.1,0.3-7.2,3.6
								C120.6,241,120.5,241.1,120.3,241.1z M122.5,235.2c-1,0-2,0.4-2.6,1.2c-0.5,0.6-0.8,1.4-0.6,2.1c0.1,0.6,0.5,1.1,1,1.5
								c1.7-2.4,4.7-3.2,6.4-3.5C125.6,236,124,235.2,122.5,235.2z"/>
							<path className="st1" d="M122.4,241.9c-0.9,0-1.7-0.4-2.4-1c-0.2-0.1-0.2-0.4-0.1-0.6s0.4-0.2,0.6-0.1c0,0,0,0,0,0
								c1,0.9,1.8,0.9,2.4,0.7c0.7-0.2,1.3-0.8,1.6-1.5c0.9-2.7,3.1-3.1,3.2-3.2c0.2,0,0.5,0.1,0.5,0.4c0,0.2-0.1,0.5-0.4,0.5
								c-0.1,0-1.8,0.4-2.6,2.6c-0.3,1-1.1,1.7-2.1,2C123,241.9,122.7,241.9,122.4,241.9z"/>
							<path className="st1" d="M118.8,242.9c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.4-0.2-0.6c0.5-1.2,2.1-2.6,2.1-2.7c0.2-0.2,0.4-0.2,0.6,0
								c0.2,0.2,0.2,0.4,0,0.6c0,0-1.5,1.4-1.9,2.4C119.1,242.8,119,242.9,118.8,242.9z"/>
							<path className="st1" d="M116.8,242.7c-0.1,0-0.2,0-0.3-0.1c-2.8-2.7-7.8-1.9-7.8-1.9c-0.2,0-0.5-0.1-0.5-0.4c0-0.1,0-0.2,0.1-0.3
								c0.8-0.9,1.7-1.7,2.8-2.4c2.2-1.4,4.2-1.4,5.7-0.2c0.8,0.6,1.4,1.5,1.4,2.6c0,1-0.4,1.9-1.1,2.6
								C117,242.7,116.9,242.7,116.8,242.7z M110.6,239.8c1.7,0,4.3,0.3,6.2,2c0.4-0.5,0.6-1,0.6-1.6c-0.1-0.8-0.5-1.5-1.1-1.9
								c-2.2-1.8-5.1,0.3-6.5,1.6C110,239.8,110.3,239.8,110.6,239.8L110.6,239.8z"/>
							<path className="st1" d="M114.4,244.2h-0.1c-1,0-2-0.6-2.6-1.5c-1.2-2-3-1.9-3.1-1.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
								c0.1,0,2.4-0.1,3.8,2.3c0.4,0.6,1.1,1,1.9,1.1c0.6,0,1.4-0.2,2.1-1.2c0.1-0.2,0.4-0.2,0.6-0.1c0.2,0.1,0.2,0.4,0.1,0.6l0,0
								C116.5,243.5,115.5,244.1,114.4,244.2z"/>
							<path className="st1" d="M118.7,244.2c-0.1,0-0.3-0.1-0.3-0.2c-0.7-0.9-2.4-1.9-2.4-1.9c-0.2-0.1-0.3-0.4-0.2-0.6
								c0.1-0.2,0.4-0.3,0.6-0.2c0.1,0,1.9,1.1,2.7,2.1c0.1,0.2,0.1,0.5-0.1,0.6C118.9,244.2,118.8,244.2,118.7,244.2L118.7,244.2z"/>
						</g>
					</g>
					<g className="groupsvg" id="Desarrollo_x5F_Agropecuario">
						<polygon className="st0" points="79.3,252.5 47.2,252.5 31.2,280.2 47.2,308 79.3,308 95.3,280.2 	"/>
						<g>
							<path className="st1" d="M68,287.8L68,287.8c0.2-1.3,0.8-3.2,2.3-4.4c1.9-1.6,4.9-1.9,7.5-0.8l0,0c0.1,0,0.1,0,0.1,0s0.1,0,0.1,0l0,0
								l0,0h0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l0.1-0.1l0,0c0-0.1,0-0.1,0-0.1
								s0-0.1,0-0.1l0,0c-0.1-1.7-0.7-3.1-1.6-4.1c-0.9-0.9-1.9-1.2-2.8-1.4c-1.3-0.3-2.6-0.4-3.8-0.5v-6c0-0.3-0.2-0.5-0.5-0.5
								s-0.5,0.2-0.5,0.5v6.1c-1,0-2.1,0-3.1,0.1l-0.2-0.5l0,0c0-0.1-0.1-0.2-0.1-0.3l-2.7-8.6h1c0.3,0,0.5-0.2,0.5-0.5
								c0-0.3-0.2-0.5-0.5-0.5H49.8c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h1.1v9.3h-4.1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5
								H57c0.2,0.4,0.4,0.8,0.6,1.2c0.8,1.5,1.8,2.9,3.1,4.1v5.1c0,0.3,0.2,0.5,0.5,0.5h6.3l0,0l0,0c0.1,0,0.2,0,0.2-0.1
								c0,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1l0.1-0.1c0-0.1,0.1-0.1,0.1-0.1C68,287.8,68,287.8,68,287.8z M69.7,277.2
								c1.2,0.1,2.7,0.2,4.1,0.5c0.8,0.2,1.6,0.4,2.3,1.1c0.8,0.7,1.1,1.7,1.2,2.6c-2.8-0.9-5.7-0.5-7.8,1.2c-1.6,1.3-2.3,3.1-2.6,4.6
								h-1.1v-9.8l0,0C67.3,277.2,68.6,277.2,69.7,277.2z M52,266.9h3.7v9.3H52V266.9z M58.5,278c-0.3-0.5-0.5-1-0.7-1.4
								c-0.1-0.2-0.3-0.3-0.5-0.3h-0.6v-9.3h5.4l2.8,9v11.2h-3.3v-4.8c0-0.2-0.1-0.3-0.2-0.4C60.3,280.8,59.3,279.5,58.5,278z"/>
							<path className="st1" d="M66.7,290.4H60c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h6.7c0.3,0,0.5-0.2,0.5-0.5S66.9,290.4,66.7,290.4z"/>
							<path className="st1" d="M74.8,283.9c-2.8,0-5,2.3-5,5c0,2.8,2.3,5,5,5c2.8,0,5-2.3,5-5C79.8,286.2,77.5,283.9,74.8,283.9z
								 M74.8,292.9c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C78.8,291.1,77,292.9,74.8,292.9z"/>
							<path className="st1" d="M51.1,279.4c-3.8,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S54.9,279.4,51.1,279.4z M51.1,292.4c-3.3,0-6-2.7-6-6
								c0-3.3,2.7-6,6-6c3.3,0,6,2.7,6,6C57.1,289.7,54.3,292.4,51.1,292.4z"/>
							<circle className="st1" cx="74.8" cy="288.9" r="1"/>
							<circle className="st1" cx="51.1" cy="286.4" r="1"/>
						</g>
					</g>
					<g className="groupsvg" id="Turismo">
						<g>
							<polygon className="st0" points="130.5,281.7 98.4,281.7 82.4,309.4 98.4,337.2 130.5,337.2 146.5,309.4 		"/>
							<g>
								<g>
									<path className="st1" d="M128,322.6h-27.4c-1.8,0-3.3-1.5-3.3-3.3v-17c0-1.8,1.5-3.3,3.3-3.3H128c1.8,0,3.3,1.5,3.3,3.3v17
										C131.2,321.2,129.8,322.6,128,322.6z M100.6,300.5c-1.1,0-2,0.9-2,2v17c0,1.1,0.9,2,2,2H128c1.1,0,2-0.9,2-2v-17
										c0-1.1-0.9-2-2-2H100.6z"/>
								</g>
								<g>
									<path className="st1" d="M118.8,300.5c-0.4,0-0.7-0.3-0.7-0.7v-5.2c0-0.4-0.3-0.7-0.7-0.7H111c-0.4,0-0.7,0.3-0.7,0.7v5.2
										c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-5.2c0-1.1,0.9-2,2-2h6.5c1.1,0,2,0.9,2,2v5.2
										C119.5,300.2,119.2,300.5,118.8,300.5z"/>
								</g>
								<g>
									<path className="st1" d="M118.8,297.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H111c-0.4,0-0.7,0.3-0.7,0.7
										c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-1.1,0.9-2,2-2h6.5c1.1,0,2,0.9,2,2C119.5,297.6,119.2,297.9,118.8,297.9z"/>
								</g>
								<g>
									<path className="st1" d="M107.1,322.6h-2.6c-0.4,0-0.7-0.3-0.7-0.7v-22.2c0-0.4,0.3-0.7,0.7-0.7h2.6c0.4,0,0.7,0.3,0.7,0.7V322
										C107.8,322.3,107.5,322.6,107.1,322.6z M105.1,321.3h1.3v-20.9h-1.3V321.3z"/>
								</g>
								<g>
									<path className="st1" d="M124.1,322.6h-2.6c-0.4,0-0.7-0.3-0.7-0.7v-22.2c0-0.4,0.3-0.7,0.7-0.7h2.6c0.4,0,0.7,0.3,0.7,0.7V322
										C124.7,322.3,124.4,322.6,124.1,322.6z M122.1,321.3h1.3v-20.9h-1.3V321.3z"/>
								</g>
								<g>
									<path className="st1" d="M104.5,316.1h-3.9c-0.4,0-0.7-0.3-0.7-0.7v-3.9c0-0.4,0.3-0.7,0.7-0.7h3.9c0.4,0,0.7,0.3,0.7,0.7v3.9
										C105.1,315.8,104.8,316.1,104.5,316.1z M101.2,314.8h2.6v-2.6h-2.6V314.8z"/>
								</g>
								<g>
									<path className="st1" d="M111,320h-3.9c-0.4,0-0.7-0.3-0.7-0.7v-2.6c0-0.4,0.3-0.7,0.7-0.7h3.9c0.4,0,0.7,0.3,0.7,0.7v2.6
										C111.7,319.7,111.4,320,111,320z M107.8,318.7h2.6v-1.3h-2.6V318.7z"/>
								</g>
								<g>
									<path className="st1" d="M112.1,309.6h-4.8c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.2-0.1-0.4,0-0.6l2.4-5c0.2-0.5,1-0.5,1.2,0l2.4,5
										c0.1,0.2,0.1,0.4,0,0.6C112.5,309.5,112.3,309.6,112.1,309.6z M108.3,308.3h2.7l-1.4-2.8L108.3,308.3z"/>
								</g>
								<g>
									<path className="st1" d="M119.5,318.2c-1.4,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6S120.9,318.2,119.5,318.2z
										 M119.5,314.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3C120.8,314.9,120.2,314.3,119.5,314.3z"/>
								</g>
								<g>
									<path className="st1" d="M124.3,312c-0.2,0-0.3-0.1-0.5-0.2l-0.2-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-6.9c0-0.2,0.1-0.3,0.2-0.5l0.2-0.2
										c0.3-0.3,0.7-0.3,0.9,0l3.7,3.7c0.3,0.3,0.3,0.7,0,0.9l-3.7,3.7C124.6,311.9,124.4,312,124.3,312z M124.7,305.3v4.7l2.3-2.3
										L124.7,305.3z"/>
								</g>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Contraloria">
						<polygon className="st0" points="180.6,310.6 148.5,310.6 132.5,338.4 148.5,366.1 180.6,366.1 196.6,338.4 	"/>
						<g>
							<path className="st1" d="M174.7,337.5c-1.3,1.3-3,2-4.8,2c-1.8,0-3.6-0.7-4.8-2l-0.3-0.3l-0.8,0.8l0.3,0.3c1.5,1.5,3.5,2.3,5.7,2.3
								c2.1,0,4.2-0.8,5.7-2.3l0.3-0.3l-0.8-0.8L174.7,337.5z"/>
							<path className="st1" d="M177.6,325c-2.1-2.1-4.8-3.2-7.7-3.2c-2.9,0-5.7,1.1-7.7,3.2c-2.1,2.1-3.2,4.8-3.2,7.7c0,2.7,1,5.3,2.8,7.3
								l-0.9,0.9l-0.8-0.8l-12,12l2.4,2.4l12-12l-0.8-0.8l0.9-0.9c2,1.8,4.6,2.8,7.3,2.8c2.9,0,5.7-1.1,7.7-3.2c2.1-2.1,3.2-4.8,3.2-7.7
								C180.8,329.8,179.7,327,177.6,325z M150.5,352.9l-0.8-0.8l10.3-10.3l0.8,0.8L150.5,352.9z M176.8,339.6c-1.8,1.8-4.3,2.9-6.9,2.9
								s-5.1-1-6.9-2.9c-1.8-1.8-2.9-4.3-2.9-6.9c0-2.6,1-5.1,2.9-6.9c1.8-1.8,4.3-2.9,6.9-2.9c2.6,0,5.1,1,6.9,2.9
								c1.8,1.8,2.9,4.3,2.9,6.9C179.6,335.3,178.6,337.8,176.8,339.6z"/>
						</g>
					</g>
					<g className="groupsvg" id="Educacion">
						<polygon className="st0" points="130.5,339.8 98.4,339.8 82.4,367.6 98.4,395.4 130.5,395.4 146.5,367.6 	"/>
						<g>
							<path className="st1" d="M125.2,356.7L125.2,356.7c0-0.1-0.1-0.2-0.1-0.2l0,0c-0.1,0-0.1-0.1-0.2-0.1l-11.5-5.4
								c-0.2-0.1-0.4-0.1-0.6,0l-11.5,5.4c-0.3,0.2-0.5,0.6-0.3,0.9c0.1,0.1,0.2,0.3,0.3,0.3l3,1.4v6.1c0,0.3,0.1,0.5,0.4,0.6l8.1,4.1
								c0.2,0.1,0.4,0.1,0.6,0l7.4-3.4c0.2-0.1,0.4-0.3,0.4-0.6v-6.4l2.7-1.3v2.3h-0.7c-0.4,0-0.7,0.3-0.7,0.7v4.1c0,0.4,0.3,0.7,0.7,0.7
								h2.7c0.4,0,0.7-0.3,0.7-0.7V361c0-0.4-0.3-0.7-0.7-0.7h-0.7v-3.4C125.3,356.8,125.2,356.8,125.2,356.7z M119.9,365.3l-6.8,3.1
								l-7.4-3.7v-5l6.8,3.2v3.6h1.4v-3.6l6.1-2.9V365.3z M111.7,355.6v2.7h1.4v-0.7h8.5l-8.5,4l-9.9-4.7l9.9-4.7l8.5,4h-8.5v-0.7H111.7z
								 M125.3,364.4h-1.4v-2.7h1.4V364.4z"/>
							<rect x="119.3" y="370.7" className="st1" width="1.4" height="1.4"/>
							<rect x="105.5" y="369.8" className="st1" width="1.4" height="1.4"/>
							<g>
								<path className="st1" d="M112.9,377.3c-0.1,0-0.1,0-0.2,0l-3.5-0.3c-0.2,0-0.4-0.2-0.4-0.5c0-0.2,0.2-0.4,0.5-0.4l3.5,0.3
									c0.5,0,0.9-0.3,1-0.8c0,0,0,0,0,0c0.1-0.5-0.2-0.9-0.7-0.9c0,0-0.1,0-0.1,0l-5.5-0.6c-1.7-0.2-3.3,0.2-4.7,1.1l-0.5,0.3
									c-0.2,0.1-0.4,0.1-0.6-0.1c-0.1-0.2-0.1-0.4,0.1-0.6c0,0,0,0,0.1,0l0.5-0.3c1.6-0.9,3.4-1.4,5.3-1.2l5.5,0.6
									c0.9,0.1,1.6,1,1.5,1.9C114.4,376.6,113.7,377.3,112.9,377.3z"/>
								<path className="st1" d="M115.9,377.3c-0.2,0-0.4-0.1-0.5-0.4s0.1-0.4,0.4-0.5l0,0l7.3-1.6c0.2,0,0.5,0.1,0.5,0.3s-0.1,0.5-0.3,0.5
									L115.9,377.3C115.9,377.3,115.9,377.3,115.9,377.3z"/>
								<path className="st1" d="M112.5,382.7c-0.9,0-1.7-0.1-2.6-0.3l-4.2-1.1c-0.2-0.1-0.4-0.3-0.3-0.5s0.3-0.4,0.5-0.3l4.2,1.1
									c2.1,0.5,4.3,0.4,6.2-0.5l7.4-3.2c0.6-0.2,0.8-0.9,0.6-1.5c-0.2-0.5-0.7-0.7-1.1-0.7c-0.2,0-0.4-0.2-0.4-0.4
									c0-0.2,0.1-0.4,0.4-0.4c0.9-0.1,1.7,0.5,2,1.3c0.3,1-0.1,2-1.1,2.4l-7.4,3.2C115.3,382.4,113.9,382.7,112.5,382.7z"/>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Salud">
						<polygon className="st0" points="79.3,369 47.2,369 31.2,396.7 47.2,424.5 79.3,424.5 95.3,396.7 	"/>
						<g>
							<path className="st1" d="M73.8,383c-0.3-0.8-1-1.3-1.9-1.3c-1.1,0-2.1,0.9-2.1,2.1c0,1.1,0.9,2.1,2.1,2.1c0.8,0,1.5-0.5,1.9-1.2
								c0.5,0.1,0.9,0.3,1.1,0.6c0.7,0.7,0.6,1.9,0.6,1.9l0,4.2c0,5-4,9-9,9s-9-4-9-9v-4.2c0-0.2,0.1-2.2,1.9-2.6c0.3,0.7,1,1.2,1.9,1.2
								c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-0.9,0-1.6,0.5-1.9,1.3c-2.7,0.4-3.5,2.9-3.5,4.3v4.2c0,5.1,3.6,9.3,8.3,10.4v1.1
								c0,1.1,0.7,2,1.7,2.3v0c0,3.9-3.2,7.1-7.1,7.1c-3.9,0-7.1-3.2-7.1-7.1v-3.7c1.9-0.4,3.3-2,3.3-4c0-2.3-1.8-4.1-4.1-4.1
								c-2.3,0-4.1,1.8-4.1,4.1c0,2,1.4,3.7,3.3,4v3.7c0,4.8,3.9,8.7,8.7,8.7c4.8,0,8.7-3.9,8.7-8.7v0c1-0.3,1.7-1.2,1.7-2.3v-1.2
								c4.6-1.2,8-5.4,8-10.3v-4.1c0-0.3,0.1-2-1-3.1C75.5,383.5,74.8,383.1,73.8,383z M71.9,384.2c-0.2,0-0.4-0.2-0.4-0.4
								c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4C72.3,384,72.1,384.2,71.9,384.2z M61.2,383.3c0.2,0,0.4,0.2,0.4,0.4
								c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4C60.7,383.5,60.9,383.3,61.2,383.3z M48.3,397.4c0-1.4,1.1-2.5,2.5-2.5
								c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5C49.4,399.9,48.3,398.8,48.3,397.4z M67.4,402.9c0,0.5-0.4,0.8-0.8,0.8
								c-0.5,0-0.8-0.4-0.8-0.8V402c0.2,0,0.4,0,0.7,0c0.3,0,0.7,0,1-0.1V402.9z"/>
							<path className="st1" d="M52.3,397.4c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5S52.3,398.3,52.3,397.4z"/>
						</g>
					</g>
					<g className="groupsvg" id="Seguridad_x5F_Publica">
						<polygon className="st0" points="130.5,398.2 98.4,398.2 82.4,425.9 98.4,453.7 130.5,453.7 146.5,425.9 	"/>
						<g>
							<path className="st1" d="M114.3,426.5c-1.2,0-2.2,1-2.2,2.2c0,0.5,0.2,1.1,0.6,1.5v3h3.3v-3c0.4-0.4,0.6-0.9,0.6-1.5
								C116.5,427.5,115.5,426.5,114.3,426.5z M115,429.5l-0.2,0.2v2.3h-1.1v-2.3l-0.2-0.2c-0.2-0.2-0.4-0.5-0.4-0.8
								c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C115.4,429,115.3,429.3,115,429.5z"/>
							<path className="st1" d="M113.7,414.8v1.7c-2.5,0.3-4.4,2.4-4.4,5v2.9c-0.6,0.2-1.1,0.8-1.1,1.6v7.8c0,0.9,0.7,1.7,1.7,1.7h3.9v3.9
								h1.1v-3.9h3.9c0.9,0,1.7-0.7,1.7-1.7v-7.8c0-0.7-0.5-1.3-1.1-1.6v-2.9c0-2.6-1.9-4.7-4.4-5v-1.7H113.7z M119.3,433.7
								c0,0.3-0.2,0.6-0.6,0.6h-8.9c-0.3,0-0.6-0.2-0.6-0.6v-7.8c0-0.3,0.2-0.6,0.6-0.6h8.9c0.3,0,0.6,0.2,0.6,0.6V433.7z M115.9,424.3
								h-3.3v-2.8c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7V424.3z M118.2,421.5v2.8H117v-2.8c0-1.5-1.2-2.8-2.8-2.8
								s-2.8,1.2-2.8,2.8v2.8h-1.1v-2.8c0-2.1,1.7-3.9,3.9-3.9S118.2,419.3,118.2,421.5z"/>
							<path className="st1" d="M130.9,414.8c-10.2,0-16.2-5.3-16.3-5.4l-0.4-0.3l-0.4,0.3c-0.1,0.1-6.1,5.4-16.3,5.4h-0.6v0.6
								c0,14.5,8.4,28.3,17.2,28.3s17.2-13.7,17.2-28.3v-0.6H130.9z M114.3,442.6c-8,0-15.9-13.1-16.1-26.6c9-0.2,14.6-4.2,16.1-5.4
								c1.5,1.2,7.1,5.2,16.1,5.4C130.1,429.5,122.3,442.6,114.3,442.6z"/>
							<path className="st1" d="M114,412.2c-2,1.4-6.3,3.9-12.5,4.6l0.1,1.1c6.1-0.8,10.5-3.1,12.7-4.6c2.2,1.5,6.6,3.9,12.7,4.6l0.1-1.1
								c-6.2-0.8-10.5-3.2-12.5-4.6l-0.3-0.2L114,412.2z"/>
							<path className="st1" d="M114.3,440.4c-3,0-6.4-2.7-9.1-7.1c-2.8-4.6-4.5-10.3-4.8-16.2l-1.1,0.1c0.3,6,2,12,4.9,16.7
								c2.9,4.8,6.7,7.7,10,7.7s7.1-2.9,10-7.7c2.9-4.7,4.6-10.7,4.9-16.7l-1.1-0.1c-0.3,5.9-2,11.6-4.8,16.2
								C120.6,437.7,117.2,440.4,114.3,440.4z"/>
						</g>
					</g>
					<g className="groupsvg" id="Trabajo">
						<polygon className="st0" points="180.6,427.1 148.5,427.1 132.5,454.9 148.5,482.6 180.6,482.6 196.6,454.9 	"/>
						<g>
							<path className="st1" d="M179.4,461v-16.7c0-1.6-1.3-2.9-2.9-2.9h-24.2c-1.6,0-2.9,1.3-2.9,2.9V461h-2.3v2.9c0,1.6,1.3,2.9,2.9,2.9
								h28.8c1.6,0,2.9-1.3,2.9-2.9V461H179.4z M150.6,444.3c0-1,0.7-1.7,1.7-1.7h24.2c1,0,1.7,0.7,1.7,1.7V461h-1.2v-17.3h-25.4V461
								h-1.2V444.3z M162.1,462.2h4.6v0.6c0,0.3-0.2,0.6-0.6,0.6h-3.5c-0.3,0-0.6-0.2-0.6-0.6V462.2z M152.9,461v-16.1H176V461H152.9z
								 M180.6,463.9c0,1-0.7,1.7-1.7,1.7H150c-1,0-1.7-0.7-1.7-1.7v-1.7h1.2H161v0.6c0,1,0.7,1.7,1.7,1.7h3.5c1,0,1.7-0.7,1.7-1.7v-0.6
								h11.5h1.2V463.9z"/>
							<path className="st1" d="M167.5,446h-6.2l1.4,4.1l-1.2,8.4l2.9,1.4l2.9-1.4l-1.2-8.4L167.5,446z M165.9,447.2l-0.7,2.3h-1.5l-0.7-2.3
								H165.9z M164.4,458.7l-1.7-0.9l1-7.1h1.3l1,7.1L164.4,458.7z"/>
						</g>
					</g>
					<g className="groupsvg" id="Movilidad">
						<polygon className="st0" points="130.5,456.3 98.4,456.3 82.4,484.1 98.4,511.9 130.5,511.9 146.5,484.1 	"/>
						<g transform="translate(0,-1020.3622)">
							<path className="st1" d="M122.9,1489.1H106c-1.6,0-2.9,0.9-3.6,2.3h-3.2c-0.9,0-1.7,0.8-1.7,1.7v4.5c0,0.9,0.8,1.7,1.7,1.7
								s1.7-0.8,1.7-1.7v-4.5c0-0.2,0-0.4-0.1-0.6h1.3c0,0.1,0,0.3,0,0.4c0,0,0,0.1,0,0.1v11.8c0,0,0,0,0,0v6.8h-0.6
								c-0.3,0-0.6,0.3-0.6,0.6v2.3c0,0.3,0.3,0.6,0.6,0.6h1.7v3.4c0,0.6,0.5,1.1,1.1,1.1h2.3c0.6,0,1.1-0.5,1.1-1.1v-3.4h2.3v0.6
								c0,0.3,0.3,0.6,0.6,0.6h7.9c0.3,0,0.6-0.3,0.6-0.6v-0.6h2.3v3.4c0,0.6,0.5,1.1,1.1,1.1h2.3c0.6,0,1.1-0.5,1.1-1.1v-3.4h1.7
								c0.3,0,0.6-0.3,0.6-0.6v-2.3c0-0.3-0.3-0.6-0.6-0.6h-0.6v-6.8v-11.8c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5h1.3
								c-0.1,0.2-0.1,0.4-0.1,0.6v4.5c0,0.9,0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7v-4.5c0-0.9-0.8-1.7-1.7-1.7h-3.2
								C125.8,1490,124.5,1489.1,122.9,1489.1L122.9,1489.1z M125.5,1492.5h-22.1c0.3-1.3,1.2-2.3,2.6-2.3h16.9
								C124.3,1490.2,125.2,1491.2,125.5,1492.5z M130.3,1493.1v4.5c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6v-4.5
								c0-0.3,0.2-0.6,0.6-0.6C130,1492.5,130.3,1492.7,130.3,1493.1z M99.8,1493.1v4.5c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6-0.2-0.6-0.6
								v-4.5c0-0.3,0.2-0.6,0.6-0.6C99.6,1492.5,99.8,1492.7,99.8,1493.1z M125.8,1503c-0.3-0.2-0.7-0.3-1.1-0.3v-1.7
								c0-0.3-0.3-0.6-0.6-0.6h-5.6c-0.3,0-0.6,0.3-0.6,0.6v1.7h-13.5c-0.4,0-0.8,0.1-1.1,0.3v-9.3h22.6V1503z M123.5,1502.6H119v-1.1
								h4.5V1502.6z M124.6,1503.8c0.5,0,0.9,0.3,1,0.7c0.1,0.1,0.1,0.3,0.1,0.4c0,0,0,0,0,0v6.8h-5.6c-0.8,0-0.8,1.1,0,1.1h6.8v1.1h-2.3
								h-2.3H119v-0.6c0-0.3-0.3-0.6-0.6-0.6h-7.9c-0.3,0-0.6,0.3-0.6,0.6v0.6h-7.9v-1.1h6.8c0.8,0,0.8-1.1,0-1.1h-5.6v-6.8
								c0-0.6,0.5-1.1,1.1-1.1h19.7L124.6,1503.8L124.6,1503.8z M120.7,1507.2c-0.3,0-0.6,0.3-0.6,0.6v2.3c0,0.3,0.3,0.6,0.6,0.6h3.4
								c0.3,0,0.6-0.3,0.6-0.6v-2.3c0-0.3-0.3-0.6-0.6-0.6H120.7z M118.4,1507.2h-7.9c0,0,0,0,0,0c-0.3,0-0.6,0.3-0.6,0.6
								c0,0.3,0.3,0.6,0.6,0.6h7.9c0.3,0,0.6-0.2,0.6-0.5C119,1507.4,118.8,1507.2,118.4,1507.2C118.5,1507.1,118.4,1507.1,118.4,1507.2z
								 M104.9,1507.2c-0.3,0-0.6,0.3-0.6,0.6v2.3c0,0.3,0.3,0.6,0.6,0.6h3.4c0.3,0,0.6-0.3,0.6-0.6v-2.3c0-0.3-0.3-0.6-0.6-0.6H104.9z
								 M123.5,1509.4h-2.3v-1.1h2.3V1509.4z M107.7,1509.4h-2.3v-1.1h2.3V1509.4z M118.4,1509.4h-7.9c0,0,0,0,0,0
								c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h7.9c0.3,0,0.6-0.2,0.6-0.5C119,1509.7,118.8,1509.4,118.4,1509.4
								C118.5,1509.4,118.4,1509.4,118.4,1509.4z M117.9,1515h-6.8v-1.1h6.8V1515z M124.6,1518.4h-2.3v-3.4h2.3V1518.4z M106.6,1518.4
								h-2.3v-3.4h2.3V1518.4z"/>
						</g>
					</g>
					<g className="groupsvg" id="Cultura">
						<polygon className="st0" points="79.3,485.4 47.2,485.4 31.2,513.2 47.2,541 79.3,541 95.3,513.2 	"/>
						<g>
							<g>
								<path className="st1" d="M74.1,507.3c-0.2,0-0.3-0.1-0.5-0.2c0,0-3.1-3.5-9.6-3.7c-6.3-0.2-10.5,3.1-10.7,3.3
									c-0.1,0.1-0.2,0.2-0.4,0.2c-0.5,0-0.6-0.3-0.6-0.6h0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2l-0.4-5.3c0-0.1,0-0.1,0-0.2
									c0-0.2,1-4.9,12.7-5c5-0.1,8.4,0.9,10,2.9c1.2,1.5,1,3,0.9,3.2l-0.9,5.1c0,0.2-0.2,0.4-0.4,0.5C74.2,507.3,74.1,507.3,74.1,507.3
									z M52.9,506.3L52.9,506.3L52.9,506.3z M63.4,502.2c0.2,0,0.4,0,0.7,0c5.1,0.2,8.2,2.2,9.6,3.3l0.7-4c0,0,0,0,0,0
									c0,0,0.2-1.1-0.7-2.2c-0.9-1.1-3.2-2.5-8.7-2.5c-0.1,0-0.2,0-0.4,0c-9.8,0.1-11.3,3.5-11.5,4c0.1,1.4,0.2,3.2,0.3,4.4
									C55,504.1,58.6,502.2,63.4,502.2z"/>
							</g>
							<g>
								<path className="st1" d="M52.3,510.1c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.2-0.5-0.4c-0.4-1.6,0.3-3.2,0.4-3.3c0.1-0.3,0.5-0.4,0.8-0.3
									c0.3,0.1,0.4,0.5,0.3,0.8c0,0-0.1,0.3-0.2,0.7c1.3-1.4,3.9-3,10.8-2.8c5.7,0.1,8.5,1.5,9.9,2.7c0-0.2-0.1-0.4-0.2-0.6
									c-0.1-0.3,0.1-0.7,0.4-0.8c0.3-0.1,0.7,0.1,0.8,0.4c0.6,1.7,0.3,3,0.3,3c-0.1,0.3-0.3,0.5-0.6,0.5c0,0,0,0,0,0
									c-0.3,0-0.5-0.2-0.6-0.5c0,0-1-3.4-10-3.6c-8.3-0.2-9.8,2.1-10.7,3.4c-0.1,0.2-0.2,0.3-0.3,0.5C52.6,510,52.5,510.1,52.3,510.1z"
									/>
							</g>
							<g>
								<path className="st1" d="M74.4,515.6c-0.2,0-0.4-0.1-0.5-0.3c0,0-2.9-3.8-10.5-3.8c0,0-0.1,0-0.1,0c-7.8,0-10.6,3.2-10.6,3.3
									c-0.2,0.2-0.4,0.3-0.6,0.2c-0.2-0.1-0.4-0.3-0.4-0.5l-0.2-3.5c0-0.1,0-0.2,0.1-0.3l0.5-0.9l0-0.6c0-0.3,0.3-0.6,0.6-0.6
									c0.3,0,0.6,0.3,0.6,0.6l0,0.7c0,0.1,0,0.2-0.1,0.3l-0.5,0.9l0.1,2.1c1.5-1.1,4.7-2.8,10.6-2.8c5.7,0,9,1.9,10.5,3.2v-4
									c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v5.5c0,0.3-0.2,0.5-0.4,0.6C74.5,515.5,74.5,515.6,74.4,515.6z"/>
							</g>
							<g>
								<path className="st1" d="M53,528.8c-2,0-3.3-0.6-3.4-0.7c-0.2-0.1-0.3-0.3-0.3-0.5v-13.1c0-0.3,0.3-0.6,0.6-0.6h3
									c0.3,0,0.6,0.3,0.6,0.6s-0.3,0.6-0.6,0.6h-2.4v12.1c0.6,0.2,2,0.6,3.8,0.3c-0.2-1.2-0.7-4-0.7-6.4c0-0.3,0.3-0.6,0.6-0.6
									s0.6,0.3,0.6,0.6c0,2.9,0.8,6.7,0.8,6.7c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3C54.3,528.7,53.6,528.8,53,528.8z"/>
							</g>
							<g>
								<path className="st1" d="M73.5,529.3c-0.6,0-1.3-0.1-2.1-0.2c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0.8-3.8,0.8-6.7
									c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6c0,2.4-0.5,5.1-0.7,6.4c1.8,0.3,3.2-0.1,3.8-0.3v-12.1h-2.4c-0.3,0-0.6-0.3-0.6-0.6
									s0.3-0.6,0.6-0.6h3c0.3,0,0.6,0.3,0.6,0.6v13.1c0,0.2-0.1,0.4-0.3,0.5C76.8,528.7,75.5,529.3,73.5,529.3z"/>
							</g>
							<g>
								<path className="st1" d="M73.7,516.7c-0.2,0-0.4-0.1-0.5-0.3c0,0-1.8-2.9-9.4-3c-7.7-0.1-10.1,3-10.2,3.1c-0.2,0.2-0.4,0.3-0.7,0.2
									c-0.2-0.1-0.4-0.3-0.4-0.6V514c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v0.7c1.4-1.1,4.5-2.6,10.1-2.5c5.3,0.1,8.1,1.5,9.4,2.5
									l0-0.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6l-0.1,1.9c0,0.3-0.2,0.5-0.5,0.6C73.8,516.7,73.7,516.7,73.7,516.7z"/>
							</g>
							<g>
								<path className="st1" d="M73.1,522.7h-0.6c-0.3,0-0.6-0.2-0.6-0.6c0,0-0.2-3.7-1.1-5.4c-0.2-0.3-0.8-1-3.4-1.4
									c-1.7-0.3-3.3-0.4-4-0.3c-0.2,0-0.5,0-0.9,0c-1.7,0.1-4.5,0.2-6.1,1.2c-1.4,0.9-1.5,4-1.4,5.1c0,0.2,0,0.3-0.2,0.5
									c-0.1,0.1-0.3,0.2-0.4,0.2h-0.7c-0.3,0-0.6-0.3-0.6-0.6l0-5.9c0-0.3,0.3-0.6,0.6-0.6h0c0.3,0,0.6,0.3,0.6,0.6l0,1.7
									c0.3-0.8,0.8-1.5,1.5-2c1.9-1.2,4.9-1.3,6.7-1.4c0.3,0,0.6,0,0.8,0c1.4-0.1,7.4,0.3,8.5,2.4c0.3,0.5,0.5,1.1,0.7,1.8v-2.7
									c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v6.7C73.7,522.5,73.4,522.7,73.1,522.7z"/>
							</g>
							<g>
								<path className="st1" d="M59.5,520.2c-1.2,0-2.7-0.7-2.9-0.8c-0.2-0.1-0.3-0.3-0.3-0.5c0-1.1,0.7-3.4,3.3-3.9c0.8-0.2,1.5,0,2.1,0.4
									c1.2,0.8,1.5,2.5,1.5,2.5c0,0.2,0,0.4-0.2,0.5c-0.2,0.2-1.8,1.7-3,1.8C59.8,520.2,59.6,520.2,59.5,520.2z M57.5,518.4
									c0.7,0.3,1.7,0.6,2.2,0.5c0.6-0.1,1.5-0.7,2.1-1.3c-0.1-0.4-0.4-1.1-0.9-1.4c-0.3-0.2-0.7-0.3-1.1-0.2
									C58.1,516.4,57.6,517.8,57.5,518.4z"/>
							</g>
							<g>
								<path className="st1" d="M59.6,519.2C59.6,519.2,59.6,519.2,59.6,519.2c-0.8,0-1.8-0.7-2-0.9c-0.2-0.1-0.3-0.4-0.2-0.6
									c0.1-0.7,0.8-2,2.5-2.1c0.5,0,1,0.2,1.3,0.5c0.6,0.6,0.7,1.7,0.6,1.8c0,0.2-0.1,0.4-0.3,0.5C61.4,518.6,60.4,519.2,59.6,519.2z
									 M58.7,517.6c0.3,0.2,0.7,0.4,0.9,0.4c0.2,0,0.7-0.2,1-0.4c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.1-0.2-0.2-0.4-0.1
									C59.2,516.9,58.9,517.3,58.7,517.6z M58,517.9L58,517.9L58,517.9z"/>
							</g>
							<g>
								<path className="st1" d="M67.5,520.2c-0.1,0-0.2,0-0.3,0c-1.2-0.2-2.8-1.7-3-1.8c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0.3-1.7,1.5-2.5
									c0.6-0.4,1.3-0.5,2.1-0.4c2.6,0.5,3.3,2.8,3.3,3.9c0,0.2-0.1,0.4-0.3,0.5C70.2,519.4,68.7,520.2,67.5,520.2z M65.2,517.7
									c0.6,0.5,1.5,1.2,2.1,1.3c0.5,0.1,1.5-0.2,2.2-0.5c-0.1-0.7-0.6-2-2.3-2.4c-0.4-0.1-0.8,0-1.1,0.2
									C65.6,516.6,65.3,517.3,65.2,517.7z M70.1,518.8L70.1,518.8L70.1,518.8z M70.1,518.8L70.1,518.8L70.1,518.8z"/>
							</g>
							<g>
								<path className="st1" d="M67.4,519.2c-0.8,0-1.8-0.6-2-0.7c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.1,0-1.1,0.6-1.8c0.3-0.3,0.8-0.5,1.3-0.5
									c1.7,0,2.4,1.4,2.5,2.1c0,0.2,0,0.5-0.2,0.6C69.2,518.5,68.2,519.2,67.4,519.2C67.4,519.2,67.4,519.2,67.4,519.2z M66.3,517.6
									c0.4,0.2,0.8,0.4,1,0.4c0.2,0,0.6-0.2,0.9-0.4c-0.2-0.3-0.5-0.7-1.2-0.7c-0.3,0-0.4,0.1-0.4,0.2
									C66.4,517.2,66.4,517.4,66.3,517.6z"/>
							</g>
							<g>
								<path className="st1" d="M63.6,523.2c-0.5,0-0.8-0.2-1-0.4c-0.1-0.1-0.1-0.1-0.3-0.2c0,0-0.1,0-0.1,0.1c-0.2,0.1-0.9,0.5-1.5-0.3
									c-0.2-0.3-0.8-1,2-5.3c0.2-0.3,0.6-0.4,0.8-0.2c0.3,0.2,0.4,0.6,0.2,0.8c-1,1.6-1.9,3.2-2,3.9c0.2-0.1,0.6-0.3,1-0.1
									c0.3,0.1,0.5,0.3,0.6,0.4c0.1,0.1,0.1,0.1,0.4,0.1c0.3,0,0.6,0.2,0.6,0.6c0,0.3-0.2,0.6-0.6,0.6
									C63.7,523.2,63.7,523.2,63.6,523.2z M61.6,521.9L61.6,521.9L61.6,521.9z"/>
							</g>
							<g>
								<path className="st1" d="M63.3,525.9c-0.1,0-0.1,0-0.2,0c-1.4-0.1-2.5-0.8-2.5-0.9c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5
									c0,0,1.1-0.9,2.7-0.9c1.7-0.1,2.7,1.2,2.7,1.3c0.1,0.1,0.2,0.3,0.1,0.5c0,0.2-0.2,0.3-0.3,0.4C65.8,525.3,64.6,525.9,63.3,525.9z
									 M62.4,524.5c0.2,0.1,0.5,0.1,0.8,0.1c0.4,0,0.8,0,1.1-0.1c-0.3-0.1-0.6-0.2-1-0.2C63,524.4,62.6,524.4,62.4,524.5z M61,524.5
									L61,524.5L61,524.5z"/>
							</g>
							<g>
								<path className="st1" d="M62.3,529.9c-5.7,0-7.5-1.3-7.7-1.5c-0.3-0.2-0.3-0.6-0.1-0.8c0.2-0.3,0.6-0.3,0.8-0.1c0,0,1.9,1.3,7.8,1.2
									c5.6-0.1,7.5-0.9,7.9-1.1c0.2-0.5,0.6-2.1,0.9-5.5c0-0.3,0.3-0.6,0.7-0.6c0.3,0,0.6,0.3,0.6,0.7c-0.3,3.7-0.8,5.7-1.1,6.1
									c-0.4,0.6-3,1.5-8.8,1.6C62.9,529.9,62.6,529.9,62.3,529.9z"/>
							</g>
							<g>
								<path className="st1" d="M54.9,505.5c-0.3,0-0.6-0.2-0.6-0.6l-0.3-6.2c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6l0.3,6.2
									C55.6,505.2,55.3,505.5,54.9,505.5C55,505.5,55,505.5,54.9,505.5z"/>
							</g>
							<g>
								<path className="st1" d="M57.1,504.1c-0.3,0-0.6-0.2-0.6-0.6l-0.3-6.2c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6l0.3,6.2
									C57.7,503.8,57.5,504.1,57.1,504.1C57.1,504.1,57.1,504.1,57.1,504.1z"/>
							</g>
							<g>
								<path className="st1" d="M59.8,503.6c-0.3,0-0.6-0.2-0.6-0.6l-0.3-6.2c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6l0.3,6.2
									C60.4,503.3,60.1,503.6,59.8,503.6C59.8,503.6,59.8,503.6,59.8,503.6z"/>
							</g>
							<g>
								<path className="st1" d="M62.7,503.1c-0.3,0-0.6-0.2-0.6-0.6l-0.3-6.2c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6l0.3,6.2
									C63.3,502.8,63,503.1,62.7,503.1C62.7,503.1,62.7,503.1,62.7,503.1z"/>
							</g>
							<g>
								<path className="st1" d="M65.3,503.1c-0.3,0-0.6-0.2-0.6-0.6l-0.3-6.2c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6l0.3,6.2
									C66,502.8,65.7,503.1,65.3,503.1C65.4,503.1,65.4,503.1,65.3,503.1z"/>
							</g>
							<g>
								<path className="st1" d="M68.2,503.4c-0.3,0-0.6-0.3-0.6-0.6l-0.1-6.2c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0,0c0.3,0,0.6,0.3,0.6,0.6
									l0.1,6.2C68.9,503.1,68.6,503.4,68.2,503.4C68.2,503.4,68.2,503.4,68.2,503.4z"/>
							</g>
							<g>
								<path className="st1" d="M70.7,504.3c-0.3,0-0.6-0.3-0.6-0.6l-0.1-6.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6l0.1,6.5
									C71.3,504.1,71,504.3,70.7,504.3C70.7,504.3,70.7,504.3,70.7,504.3z"/>
							</g>
							<g>
								<path className="st1" d="M73.1,506.3C73.1,506.3,73.1,506.3,73.1,506.3c-0.3,0-0.6-0.3-0.6-0.6l0.1-7.7c0-0.3,0.3-0.6,0.6-0.6
									c0.3,0,0.6,0.3,0.6,0.6l-0.1,7.7C73.7,506,73.4,506.3,73.1,506.3z"/>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Planeacion">
						<polygon className="st0" points="130.5,514.7 98.4,514.7 82.4,542.4 98.4,570.2 130.5,570.2 146.5,542.4 	"/>
						<g>
							<g>
								<path className="st1" d="M103.4,547c0.2,0.4,0.4,0.8,0.6,1.2l-1.8,1.4l-3.5,2.6c-0.7-1.1-1.2-2.2-1.7-3.3l1.3-0.4
									C100,548,101.7,547.5,103.4,547 M103.8,545.9c0,0-0.1,0-0.2,0c-2.5,0.7-5,1.4-7.5,2.1c-0.1,0-0.3,0.1-0.4,0.1
									c0.6,2,1.5,3.8,2.7,5.4c2.3-1.7,4.5-3.5,6.7-5.1c-0.4-0.8-0.8-1.6-1.1-2.4C104,546,103.9,545.9,103.8,545.9L103.8,545.9z"/>
								<path className="st1" d="M95.8,542.1l5.2,0.5l1.4,0.1c0,0.4,0.1,0.8,0.1,1.3c-1.7,0.5-3.5,1-5.2,1.5l-1.2,0.3
									C95.9,544.5,95.8,543.3,95.8,542.1 M94.8,541c-0.1,2.1,0,4,0.5,6c0.1,0,0.1,0,0.1,0c2.6-0.8,5.3-1.5,7.9-2.3
									c0.2-0.1,0.2-0.2,0.2-0.3c-0.1-0.8-0.1-1.6-0.2-2.4c0-0.1,0-0.2,0-0.3C100.5,541.5,97.7,541.2,94.8,541L94.8,541z"/>
								<path className="st1" d="M109.6,553.3l0.7,0.3l0.5,0.2l-0.7,4l-0.5,2.5c-1.2-0.3-2.3-0.8-3.5-1.3L109.6,553.3 M109.1,552
									c-1.5,2.4-3,4.8-4.5,7.2c1.8,1,3.6,1.7,5.7,2.2c0.5-2.8,1.1-5.6,1.6-8.3C111,552.7,110.1,552.4,109.1,552L109.1,552z"/>
								<path className="st1" d="M115.2,554.2c0.3,1.7,0.6,3.4,0.9,5l0.3,1.5c-0.7,0.1-1.3,0.1-2,0.1c-0.6,0-1.1,0-1.7-0.1l0.4-2
									c0.3-1.5,0.6-3,0.9-4.5c0,0,0,0,0,0C114.4,554.3,114.7,554.3,115.2,554.2 M115.8,553.2C115.8,553.2,115.7,553.2,115.8,553.2
									c-0.6,0-1.2,0-1.8,0c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.3,0.3c-0.4,2.4-0.9,4.8-1.4,7.2c-0.1,0.3-0.1,0.6-0.2,1
									c1,0.1,1.9,0.2,2.9,0.2c1,0,2.1-0.1,3.1-0.3c0-0.1,0-0.2,0-0.2c-0.5-2.6-1-5.3-1.5-7.9C116,553.3,115.9,553.2,115.8,553.2
									L115.8,553.2z"/>
								<path className="st1" d="M97.1,535.3l2.9,1.4l3.1,1.5c-0.1,0.4-0.2,0.8-0.4,1.2c-1.7-0.2-3.5-0.3-5.2-0.5l-1.4-0.1
									C96.3,537.7,96.7,536.5,97.1,535.3 M96.6,534c-0.9,1.9-1.4,3.8-1.7,5.8c0.2,0,0.3,0,0.5,0.1c2.6,0.2,5.2,0.5,7.8,0.7
									c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.2-0.2c0.2-0.8,0.4-1.5,0.7-2.3c0-0.1,0.1-0.1,0.1-0.2C101.7,536.5,99.2,535.2,96.6,534L96.6,534z
									"/>
								<path className="st1" d="M112.9,523.7c0,2.2,0,4.4,0,6.7c-0.4,0.1-0.8,0.1-1.2,0.2c-0.6-1.6-1.2-3.2-1.8-4.7l-0.6-1.5
									c0.2,0,0.3-0.1,0.5-0.1C110.8,524,111.8,523.8,112.9,523.7 M113.6,522.7C113.6,522.7,113.6,522.7,113.6,522.7
									c-1.4,0.1-2.8,0.3-4.1,0.6c-0.5,0.1-1,0.3-1.5,0.4c0,0.1,0,0.1,0,0.2c1,2.5,2,5.1,3,7.6c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0
									c0.8-0.2,1.5-0.3,2.3-0.4c0.2,0,0.3-0.1,0.3-0.3c0-2.6,0-5.3,0-7.9C113.9,522.8,113.8,522.7,113.6,522.7L113.6,522.7z"/>
								<path className="st1" d="M122.6,525.6c1.1,0.5,2.1,1.2,3.1,1.9l-4.2,4.6l-0.3,0.3l0,0c-0.3-0.2-0.7-0.4-1-0.6
									c0.6-1.6,1.2-3.2,1.8-4.8L122.6,525.6 M122.1,524.3c0,0.1-0.1,0.1-0.1,0.1c-1,2.6-2,5.1-3,7.7c-0.1,0.2,0,0.2,0.1,0.3
									c0.7,0.4,1.4,0.9,2.1,1.3c0.1,0,0.1,0.1,0.2,0.1c1.9-2.1,3.8-4.2,5.8-6.3C125.6,526.1,123.9,525.1,122.1,524.3L122.1,524.3z"/>
								<path className="st1" d="M105.9,550.8c0.3,0.3,0.6,0.5,0.9,0.8l0,0l-1.5,2.5l-2,3.2c-1-0.7-1.9-1.5-2.7-2.4l0.7-0.6l1.1-0.8
									C103.6,552.5,104.8,551.6,105.9,550.8 M106,549.5c-0.1,0-0.1,0-0.2,0.1c-1.7,1.3-3.3,2.5-5,3.8c-0.5,0.4-1,0.8-1.6,1.2
									c1.3,1.6,2.7,2.9,4.4,4c1.5-2.4,3-4.8,4.5-7.3c-0.1-0.1-0.2-0.1-0.2-0.2c-0.6-0.5-1.2-1-1.7-1.6C106.1,549.6,106,549.5,106,549.5
									L106,549.5z"/>
								<path className="st1" d="M100.8,529.6l2.2,2.5l2.2,2.4l0,0c-0.2,0.3-0.5,0.6-0.7,0.9c-1.5-0.8-3.1-1.5-4.6-2.3l-1.3-0.7
									C99.3,531.4,100,530.5,100.8,529.6 M100.9,528.1c-1.5,1.4-2.7,3-3.7,4.7c0.1,0.1,0.2,0.1,0.2,0.1c2.4,1.2,4.8,2.4,7.2,3.6
									c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.2-0.2c0.5-0.6,1-1.3,1.5-1.9c0-0.1,0.1-0.1,0.1-0.2C104.7,532.3,102.8,530.2,100.9,528.1
									L100.9,528.1z"/>
								<path className="st1" d="M119.4,553.1l1.4,2.2l2.1,3.5c-0.9,0.5-2.4,1.1-3.4,1.4l-0.5-2.5l-0.2-1.2c-0.2-0.9-0.4-1.9-0.5-2.8
									C118.7,553.5,119,553.3,119.4,553.1L119.4,553.1 M119.8,551.9c-0.1,0.1-0.2,0.1-0.3,0.1c-0.7,0.3-1.4,0.6-2.1,0.8
									c-0.2,0.1-0.3,0.2-0.2,0.4c0.3,1.5,0.6,3,0.9,4.6c0.2,1.2,0.4,2.3,0.7,3.5c1,0,4.8-1.5,5.5-2.2
									C122.8,556.7,121.3,554.3,119.8,551.9L119.8,551.9z"/>
								<path className="st1" d="M123,550.6l2.1,1.5l3.2,2.4c-0.7,0.7-1.8,1.8-2.7,2.5l-1.4-2.3l-2.1-3.3C122.4,551.1,122.7,550.9,123,550.6
									L123,550.6 M123,549.3c-0.1,0-0.1,0.1-0.1,0.1c-0.6,0.6-1.2,1.1-1.8,1.7c-0.2,0.1-0.1,0.2,0,0.4c1.4,2.3,2.8,4.6,4.2,6.8
									c0,0.1,0.1,0.1,0.1,0.2c1.1-0.5,3.9-3.2,4.4-4.1C127.5,552.7,125.2,551,123,549.3L123,549.3z"/>
								<path className="st1" d="M128.1,529.7c0.8,0.9,1.5,1.8,2.2,2.9l-5.4,2.7l-0.5,0.3c-0.2-0.3-0.5-0.6-0.7-1l2.3-2.5L128.1,529.7
									 M128.1,528.3c-1.9,2.1-3.9,4.2-5.7,6.3c0.5,0.7,1,1.3,1.5,2c0.1,0.2,0.2,0.3,0.3,0.3c0.1,0,0.1,0,0.3-0.1
									c2.1-1.1,4.3-2.1,6.4-3.2c0.3-0.1,0.6-0.3,0.9-0.5C130.7,531.2,129.5,529.7,128.1,528.3L128.1,528.3z"/>
								<path className="st1" d="M131.7,535.5c0.5,1.1,0.8,2.3,1,3.5c-0.1,0-0.3,0-0.4,0l-3.8,0.4l-1.8,0.2l-0.4,0c-0.1,0-0.2,0-0.3,0
									c-0.1-0.4-0.2-0.8-0.3-1.2L131.7,535.5 M132.2,534.1c-2.6,1.3-5.1,2.6-7.6,3.8c0.2,0.9,0.4,1.8,0.7,2.6c0,0.1,0.1,0.1,0.2,0.1
									c0,0,0,0,0,0c0.4,0,0.9-0.1,1.3-0.1c1.9-0.2,3.7-0.4,5.6-0.5c0.5,0,0.9-0.1,1.4-0.1C133.6,537.8,133,536,132.2,534.1L132.2,534.1
									z"/>
								<path className="st1" d="M116.1,523.8c1.2,0.1,2.4,0.3,3.5,0.7l-2.4,6.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1v-4.6V523.8
									 M115.1,522.7c0,0.2,0,0.4,0,0.6c0,2.5,0,5.1,0,7.6c0,0.4,0,0.4,0.4,0.4c0.3,0,0.7,0.1,1,0.1c0.5,0.1,0.9,0.2,1.4,0.3
									c1-2.7,2-5.3,3.1-8C119,523.1,117.1,522.8,115.1,522.7L115.1,522.7z"/>
								<path className="st1" d="M133,542.2c0,1.2-0.1,2.4-0.3,3.6l-2.2-0.6l-4.1-1.2c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.3,0-0.4
									c1.1-0.1,2.2-0.2,3.3-0.3l1.1-0.1l0.7-0.1C132,542.2,132.5,542.2,133,542.2 M133.8,541.1c-0.1,0-0.2,0-0.3,0
									c-0.9,0.1-1.8,0.2-2.7,0.3c-1.7,0.2-3.4,0.3-5.1,0.5c-0.2,0-0.2,0.1-0.2,0.2c0,0.3,0,0.7,0,1c0,0.5-0.1,1-0.2,1.5
									c2.7,0.8,5.4,1.6,8.2,2.4c0-0.1,0.1-0.2,0.1-0.3c0.4-1.7,0.6-3.4,0.5-5.2C134,541.2,134,541.1,133.8,541.1L133.8,541.1z"/>
								<path className="st1" d="M106.3,525.5l2.4,6.2l-1,0.6l-4.5-4.9C104.2,526.7,105.2,526.1,106.3,525.5 M106.8,524.2
									c-1.9,0.8-3.5,1.8-5.1,3.1c1.9,2.1,3.9,4.2,5.7,6.3c0.8-0.5,1.6-1,2.4-1.5C108.9,529.5,107.9,526.9,106.8,524.2L106.8,524.2z"/>
								<path className="st1" d="M125.5,547c1.7,0.5,3.3,1,5,1.4l1.3,0.4c-0.4,1.1-0.9,2.2-1.5,3.1l-5.3-4c0.1-0.2,0.2-0.4,0.3-0.6
									C125.3,547.3,125.4,547.2,125.5,547 M125,545.9c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.4-0.4,0.8-0.7,1.3
									c2.3,1.7,4.5,3.4,6.8,5.1c0,0,0.1,0,0.1,0c1.1-1.5,1.9-3.2,2.5-5c0-0.1,0-0.2-0.1-0.2c-0.2-0.1-0.5-0.1-0.7-0.2
									c-2.3-0.7-4.7-1.3-7-2C125.1,545.9,125,545.9,125,545.9L125,545.9z"/>
							</g>
						</g>
					</g>
					<g className="groupsvg" id="Oficialia_x5F_Mayor">
						<polygon className="st0" points="180.6,542.9 148.5,542.9 132.5,570.6 148.5,598.4 180.6,598.4 196.6,570.6 	"/>
						<g>
							<path className="st1" d="M159.1,587.4C159.1,587.4,159.1,587.4,159.1,587.4C159.2,587.4,159.2,587.4,159.1,587.4
								c2.1,0.9,4.3,1.3,6.6,1.3c2.3,0,4.6-0.5,6.6-1.3c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c6.1-2.6,10.4-8.7,10.4-15.8
								c0-3.8-1.2-7.4-3.5-10.5c-0.3-0.4-0.8-0.4-1.2-0.2c-0.4,0.3-0.4,0.8-0.2,1.2c2.1,2.7,3.2,6,3.2,9.4c0,6.1-3.5,11.4-8.7,13.9
								l-4.7-15l1.1-3.5l4.3,2.4c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.4-0.3,0.5-0.6l3.1-12.3c0-0.2,0-0.4,0-0.5l-1.2-2.8
								c-0.1-0.3-0.4-0.4-0.6-0.5c0,0-0.1,0-0.1,0c0,0,0,0,0,0h-19.8c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.3,0-0.5,0.2-0.6,0.5l-1.2,2.8
								c-0.1,0.2-0.1,0.4,0,0.5l3.1,12.3c0.1,0.3,0.2,0.5,0.5,0.6c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1l4.4-2.4l1.1,3.5l-4.6,15
								c-5.2-2.5-8.7-7.8-8.7-14c0-3.4,1.1-6.7,3.2-9.4c0.3-0.4,0.2-0.9-0.2-1.2c-0.4-0.3-0.9-0.2-1.2,0.2c-2.3,3-3.5,6.6-3.5,10.5
								C148.5,578.6,152.9,584.8,159.1,587.4z M173.2,567.3l-5.8-3.2l5.8-6.6c0,0,0,0,0,0l2.1-2.4l0.6,1.3L173.2,567.3z M165.7,563.4
								l-5-5.7h10L165.7,563.4z M173.7,554.4l-1.5,1.7h-13l-1.5-1.7H173.7z M155.4,556.4l0.6-1.3l8,9l-5.8,3.2L155.4,556.4z M165.7,565.1
								l1.8,1l-1.1,3.4H165l-1.1-3.4L165.7,565.1z M165,571.3h1.4l4.6,14.8c-1.7,0.6-3.5,0.9-5.3,0.9c-1.8,0-3.6-0.3-5.2-0.9L165,571.3z"
								/>
							<path className="st1" d="M173.4,576v-1.9h2.8c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-3.7c-0.5,0-0.8,0.4-0.8,0.8v3.3
								c0,0.3,0.2,0.6,0.4,0.7l3,1.6c0.1,0.1,0.3,0.1,0.4,0.1s0.3,0,0.4-0.1l3-1.6c0.3-0.1,0.4-0.4,0.4-0.7v-3.3c0-0.5-0.4-0.8-0.8-0.8
								s-0.8,0.4-0.8,0.8v2.8l-2.2,1.2L173.4,576z"/>
						</g>
					</g>
				</svg>
			</div>	
		);
	}
};

const mapStateToProps = ({ stateGlobal }) => {
  return {
  	stateGlobal: stateGlobal
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStateGlobal: (data) => {
      return dispatch(Globals.actions.setStateGlobal(data));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cluster);

