import React, { Component } from 'react';
import Banner from './Banner';
import { GobernadorIndex } from '../index/gobernador';
import { GabineteIndex } from '../index/gabinete';
import Cluster from '../Components/Cluster';
import { connect } from 'react-redux';
import Globals from '../globals';
import {Link} from 'react-router-dom';
import GabineteDetalle from './GabineteDetalle';
import { InformeIndex } from '../index/informe';
import { 
	LineChart, 
	Line, 
	XAxis, 
	YAxis, 
	CartesianGrid, 
	Tooltip, 
	Legend, 
	BarChart, 
	Bar, 
	RadialBarChart, 
	RadialBar,
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	ComposedChart,
	Area,
	AreaChart,
	ResponsiveContainer,
	PieChart,
	Pie
} from 'recharts';
import Chart1 from './Chart1';
import NotaComunicacion from './NotaComunicacion';
const IndexCluster = [GobernadorIndex.gobernador, ...GabineteIndex.gabinete.servidores];

class Inicio extends Component {
	state = {
		selected: IndexCluster[0],
		search:""
	};


	componentWillReceiveProps(nextProps){
		console.log("HONEY CLUSTER", nextProps.stateGlobal.clusterSelector);
		this.setState({ selected: IndexCluster[nextProps.stateGlobal.clusterSelector] });
	}

	_handleKeyUp = (e) =>{
		if (e.keyCode == 13) {
			if(document.getElementById("searchgobierno").value.length>0){
				window.scrollTo(0, 3550)
				let text = "&q="+document.getElementById("searchgobierno").value;
				this.setState({search:text});
				document.getElementById("cluster").style.display = "block";

			}	
		}
	};
	render(){
		const { selected, search } = this.state;
		let urlver = "";
		if(selected.titulo === "GOBERNADOR DEL ESTADO DE HIDALGO"){
			urlver = "/gobernador";
		}else{
			urlver = "/GabineteDetalle/"+selected.nombre;
		}
		return (
		<div id="contentglobal"> 	
			<div id="maini">
				<section className="wellcome" style={{	backgroundImage: "url("+ selected.imgwellcome +")"}}>
					<div className="searchView">
						<div className="targetinfor">
							<h1>{ selected.nombre }</h1>
							<h6>{ selected.titulo }</h6>
							<p>{ ((selected.semblanza[0]).substr(0,150)+'...') }</p>
							<a href={urlver} style={{color:"white"}}>Ver más</a>
							<br/>
							<div className="redes">
								<button className="icons" onClick={()=>window.open(selected.facebook)}><i className="fab fa-facebook-square"></i></button>
								<button className="icons" onClick={()=>window.open(selected.twitter)}><i className="fab fa-twitter"/></button>	
							</div>
							<button className="redbtn" onClick={()=>window.scrollTo(0, 700)}>INDICADORES</button>
						</div>
					</div>
					<Cluster/>
				</section>
				<article className="indicadores container-fluid">
					<div className="row">
						<div className="col-12 col-sm-12 col-xs-12">
							<div className="titulo">
								<center>
									<h1>INDICADORES</h1>
									<h6>{ selected.titulo }</h6>
									<div 
										style={{ 
											backgroundImage: "url("+(selected.icon).split("_b.").join(".")+")",
											backgroundSize: 'auto 50px',
											backgroundPosition: "center center",
											backgroundRepeat: "no-repeat",
											width: "100%",
											height: "70px",
											marginTop:"-12px"
										}}
									></div>
								</center>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-2"></div>
						<div className="col-8">
							<div className="row indi">
								<div className=" col-lg-4 col-md-8 col-sm-12 col-xs-12">
									<div className="row">
										<div className="col-12 cube foto" style={{backgroundImage: "url("+selected.imagen+")"}}>
											<div className="info-gobernador">
												<h6>{ selected.nombre }</h6>
												<p >{ selected.titulo }</p>
											</div>
										</div>											
										<div className="col-12 cube smalldesc">
											<div style={{ 
												backgroundImage: "url("+selected.icon+")",
												backgroundSize: 'auto 50px',
    											backgroundPosition: "center center",
    											backgroundRepeat: "no-repeat",
    											width: "100%",
    											height: "70px",
    											marginTop:"-20px"}}
											></div>
											<h6>Gobierno del Estado Hidalgo</h6>
											<p style={{textAlign: 'justify'}}>{ ((selected.semblanza[0]).substr(0,150)+'...') }</p>
										</div>
									</div>
								</div>
								<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 indicadoresflex" style={{ width: '100%', minHeight: "700px", display: 'flex', flexWrap: "wrap",padding:0,backgroundColor:"#e4ecf1"}}>
								  {this._renderflex()}
								</div>
							</div>
						</div>
						<div className="col-2"></div>
					</div>
				</article>
				<article>
					<NotaComunicacion/>
				</article>
				<article id="cluster" style={{display:"none"}}>
					<center><button style={{fontSize:"55px",outline:"none"}}onClick={()=>window.scrollTo(0, 0)}><i className="fas fa-angle-up"></i></button></center>
					<iframe style={{border:"none",height:"1024px"}} id="idIframe" src={"https://search.hidalgo.gob.mx/modules?index=gobierno"+search} width="100%" onLoad={this._iframeLoaded}/>
				</article>
				<footer className="comunity">

				</footer>
            </div>
        </div>
		);
	}
	_renderflex = () => {
		const { selected } = this.state;
		const colors = ["#e4ecf1","#919496","#aeb6bc","gray","#aeb6bc","#919496"];
		if(selected.indicadores){
			return selected.indicadores.map(({titulo,data},i)=>{
				return(
					<div className="chart" style={{flex: 1, backgroundColor:colors[i], width: "33.333%",height:"50%", display:"flex",flexWrap: 'wrap',justifyContent:"center",alignItems: 'center'}}>
						<div style={{ padding: '7%',paddingBottom:"0", fontWeight: 'bold'}}>
							<center>
								<div style={{fontSize: '0.8em'}}>{titulo}</div>
							</center>
						</div>
						<Chart1 selected={data}/>
					</div>
				);
			});
		}

		if(selected.titulo === "GOBERNADOR DEL ESTADO DE HIDALGO"){
			return InformeIndex.informe.actual.informes.map(({numInforme,imgInformes,url,download,text,fecha},i)=>{
				if(text != "PROXIMAMENTE"){
					return(
						<div className="chart" style={{flex: 1, backgroundColor:colors[i], width: "33.33333%",display:"flex",justifyContent:"center",alignItems: 'center'}}>
							<div>
								<div className="boxInforme" style={{backgroundImage:"url("+`${imgInformes}`+")",backgroundSize:'cover',backgroundPosition:'center',position: 'relative',padding:'0'}}>
						          <div className="SitioInforme">
						            <div style={{heigt:"100%",padding:'10px',display: 'flex',justifyContent: 'center',color:"white"}}>
						              {numInforme}
						            </div>
						          </div>
						        </div>
						        <a href={download} target="_blank" style={{textDecoration:"none"}}><button className="download"></button></a>
						    </div>
						</div>
					);
				}
				return(
		          <div className="chart" style={{flex: 1, backgroundColor:colors[i], width: "33.33333%",display:"flex",justifyContent:"center",alignItems: 'center'}}>
		            <div className="boxInforme proximamente" style={{color:'white',padding:'0'}}>
	              	<div>
	                  	<p>{fecha}</p>
	                  	<p>{text}</p>
	              	</div>
	            	</div>
		          </div>
		        );
			});
		}
		
	};
};

const mapStateToProps = ({ stateGlobal }) => {
  return {
  	stateGlobal: stateGlobal
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStateGlobal: (data) => {
      return dispatch(Globals.actions.setStateGlobal(data));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inicio);