import React, {Component} from 'react';

class Emergencias extends Component{
	
	render(){
		return(
			<div id="contentglobal" className={"container Emergencias"}>
				<h1 className="titulo">TELÉFONOS DE EMERGENCIAS</h1>
				<hr/>
				<div className="imagen">
				</div>
				<h3>RECUERDA HACER BUEN USO DE ESTAS LÍNEAS TELEFÓNICAS.</h3>
				<h5>Cualquier situación de abuso o broma puede costar la vida a las personas que realmente lo necesitan.</h5>
				<div className="encabezado">
					Seguridad
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/cseguridad.png"/>
						</div>
						<div className="name">
							<p>Denuncia Anónima</p>
						</div>
						<div className="telefono">
							<p>089</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/barandilla.png"/>
						</div>
						<div className="name">
							<p>Barandilla</p>
						</div>
						<div className="telefono">
							<p>71 73242</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/ministerio.png"/>
						</div>
						<div className="name">
							<p>Ministerio</p>
						</div>
						<div className="telefono">
							<p>71 79063</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/cseguridad.png"/>
						</div>
						<div className="name">
							<p>Coordinación de seguridad</p>
						</div>
						<div className="telefono">
							<p>71 33200</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/procuratel.png"/>
						</div>
						<div className="name">
							<p>Procuratel</p>
						</div>
						<div className="telefono">
							<p>01 800 9121314</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/cartera.png"/>
						</div>
						<div className="name">
							<p>Cartera de investigación</p>
						</div>
						<div className="telefono">
							<p>71 11880 - 71 11849</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/angeles.png"/>
						</div>
						<div className="name">
							<p>Angeles verdes</p>
						</div>
						<div className="telefono">
							<p>078</p>
						</div>
					</div>
				</div>
				<div className="encabezado col-sm-12">
					Salud, Protección y Defensa
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/sidatel.png"/>
						</div>
						<div className="name">
							<p>Sidatel</p>
						</div>
						<div className="telefono">
							<p>01 800 5578344</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/derechos.png"/>
						</div>
						<div className="name">
							<p>Derechos Humanos</p>
						</div>
						<div className="telefono">
							<p>71 81696</p>
						</div>
					</div>
				</div>
				
				<div className="encabezado">
					Atención Ciudadana
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-2 target">
						<div className="logo">
							<img src="https://cdn.hidalgo.gob.mx/gobierno/images/emergencias/ciudadana.png"/>
						</div>
						<div className="name">
							<p>Atención Ciudadana</p>
						</div>
						<div className="telefono">
							<p>070</p>
						</div>
					</div>
				</div>
				<div className="acercade">Acerca del Catálogo Nacional de Incidentes de Emergencia</div>
				<p>Con la finalidad de salvaguardar la integridad y el patrimonio de las y los mexicanos, el Gobierno de la República se ha dado a la tarea de homologar y estandarizar los servicios de atención de llamadas de emergencia, para que las autoridades de los tres órdenes de gobierno los brinden con calidad y eficacia en todo el territorio nacional.</p>
				<p>La idea es contribuir a brindar una mejor atención a la ciudadanía ante situaciones de riesgo, ya sean derivadas de fenómenos naturales, de salud o de inseguridad.</p>
				<p>Así, el Centro Nacional de Información elaboró el Catálogo Nacional de Incidentes de Emergencia, derivado del Acuerdo del Consejo Nacional de Seguridad Pública, en su Trigésima Octava Sesión Ordinaria, para ser utilizado en todos los Centros de Atención de Llamadas de Emergencia a nivel nacional; y propuso un nuevo modelo en la atención de las llamadas de emergencia.</p>
				<p>Este modelo se basa en la estandarización de sus diversos componentes y, en la forma cómo se recaba, se registra y se reporta la información derivada de las llamadas que realiza la ciudadanía para reportar una emergencia.</p>
				<center>
					<div className="btnx">
						<a className="boton" target="_blank" href="https://www.hidalgo.gob.mx/Catalogo-nacional_Incidentes_Emergencia_2015-modificado.pdf">Consulta Catálogo de emergencia</a>
					</div>
				</center>
			</div>
		);
	}
}

export default Emergencias;