import React,{Component} from 'react';
import { GabineteIndex } from '../index/gabinete';
import {Link} from 'react-router-dom';
import GabineteDetalle from './GabineteDetalle'; 

class Gabinete extends Component { 
  
  render(){
    return (
      <div className="Gabinete">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>{GabineteIndex.gabinete.titulo}</h1>
              <div style={{textAlign:"justify"}}>
                {GabineteIndex.gabinete.introduccion}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row cards-gabinete">
            {this._renderGabinete()}
          </div>
        </div>
      </div>
      );
  }
  _renderGabinete = () => {
    return GabineteIndex.gabinete.servidores.map(({secretaria,nombre,imagen,landscape,imagenGabinete,facebook,twitter,web,youtube,grado,semblanza,skills},i)=>{
      var url = "/GabineteDetalle/"+nombre;
      if(nombre == "-"){
        url = "https://gobierno.hidalgo.gob.mx/"
      } 
      return(
        <div className="col-12 col-sm-4 col-md-3">
          <div className="card">
            <a href={url}>
              <div className="card-img-top" style={{backgroundImage: "url("+imagen+")"}}/>
              <div className="card-body">
                <div className="img-titutlar" style={{backgroundImage: "url("+imagenGabinete+")"}}/>
                <h5>{nombre}</h5>
                <p className="secretario">{secretaria}</p>
                {/*<div className="grado">{grado}</div>*/}
              </div>
            </a>
            {/*<div className="botones">
              <button className="fb" onClick={()=>window.open(facebook)}><i class="fab fa-facebook-square"></i></button>
              <button className="twitter" onClick={()=>window.open(twitter)}><i class="fab fa-twitter"></i></button>
              {nombre === "Alejandro Enciso Arellano"? null:<button className="web" onClick={()=>window.open(web)}><i class="fas fa-globe"></i></button>}
            </div>*/}
          </div>   
        </div>   
      );
    });
  }
}

export default Gabinete;