import React, { Component } from "react";
import GoogleMap from "react-google-map";
import GoogleMapLoader from "react-google-maps-loader";

const MY_API_KEY = "AIzaSyBdEKChUsUVis6fHoyjE7JGUFThRecHG6k";

const Marker = ({ location, nombre, description }) => {
    return {
        title: nombre,
        position: { lat: location[0], lng: location[1] },
        onLoaded: (googleMaps, map, marker) => {
            //marker.setAnimation(googleMaps.Animation.BOUNCE);
            const infoWindow = new googleMaps.InfoWindow({
                content: '<div style="width: 120px"><label><b>'+nombre+'</b></label><p>'+description+'</p></div>'
            });

            googleMaps.event.addListener(marker, "click", () => {
                infoWindow.open(map, marker);
            });
        }
    };
};

class MapComp extends Component{

    render(){
        const { googleMaps, selected, onChange } = this.props;
        let aditianalProps = {
            coordinates:[],
            center:{lat: 20.498527, lng: -99.4826972}
        };
        if(selected && selected.locations){
            const { locations } = selected;
            aditianalProps = {
                coordinates:locations.map((element) => Marker(element)),
                center:{lat: locations[0].location[0], lng: locations[0].location[1]}
            };
        }

        if(googleMaps){
            return (
                <div style={{height:400, width: "100%"}}>
                    <GoogleMap
                        {...aditianalProps}
                        googleMaps={googleMaps}
                        zoom={10}
                        onLoaded={(googleMaps, map) => {
                            map.setMapTypeId("roadmap");

                            googleMaps.event.addListener(map, "click", (event) => {
                                if(onChange){
                                    const latitude = event.latLng.lat();
                                    const longitude = event.latLng.lng();
                                    map.panTo(new googleMaps.LatLng(latitude,longitude));
                                    onChange([latitude,longitude]);
                                }
                            });
                        }}
                    />
                </div>
            );
        }

        return (
            <div id="map" style={{width:100,height:400}}/>
        );
    }
}

class Loader extends Component{

    render(){
        return (
            <GoogleMapLoader
                style={{width:100,height:400}}
                params={{
                    key: MY_API_KEY,
                    libraries: "places"
                }}
                render={(googleMaps) => {
                    return <MapComp googleMaps={googleMaps} {...this.props}/>;
                }}
            />
        );
    }
}

export default Loader;