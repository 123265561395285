import React,{Component} from 'react';
import { DependenciasIndex } from '../index/dependencias';
import Map from "./Map";

class Dependencias extends Component { 
  
  render(){
    return (
      <div className="Dependencias container-fluid">
        <div className="row">
          <div className="col-12">
            <center> 
              <h1>{DependenciasIndex.dependencias.titulo}</h1>
            </center>
          </div>
          <div className="introduccion col-12">
            {DependenciasIndex.dependencias.introduccion}
          </div>
          <div className="Detalle col-12">
            <div className="secretarias row">
              {this._renderDetalle()}
            </div>
          </div>
        </div>
        <div className="areasApoyo row" style={{paddingTop:"5%"}}>
          <div className="col-12">
            <center>
              <h1>{DependenciasIndex.apoyo.titulo}</h1>
            </center>
          </div>
        </div>
        <div className="Detalle row">
            {this._renderDetalleArea()}
        </div>
      </div>
      );
  }
  _renderDetalle = () =>{
    return DependenciasIndex.dependencias.secretarias.map(({nombre,img,facebook,twitter,youtube,url,direccion,cp,tel,msg,icon},i)=>{
      return(
        <div className="sec col-md-6 col-sm-12 col-xs-12">
          <a href={url}>
            {
              img.length > 0 ? <img className="logo-secretaria" src={img}/> : <h5>{nombre}</h5>
            }
          </a>
          <div className="secretariasdetalle">
            <div>{direccion}</div>
            <div>{cp}</div>
            <div style={{paddingBottom:"5px"}}>{tel}</div>
          </div>
          <div className="botones">
            <button className="fb" onClick={()=>window.open(facebook)}><i className="fab fa-facebook-square"></i></button>
            {
             twitter.length >0 ? <button className="twitter" onClick={()=>window.open(twitter)}><i className="fab fa-twitter"></i></button> : null
            }
            <button className="msg" onClick={()=>window.open("/Escribele")} ><i className="fas fa-comment"></i></button>
          </div>
        </div>
      );
    });
  };

  _renderDetalleArea = () =>{
    return DependenciasIndex.apoyo.areas.map(({nombre,img,facebook,twitter,youtube,url,direccion,cp,tel,msg,icon},i)=>{
      return(
        <div className="detalleArea col-md-6 col-sm-12 col-xs-12" style={{float:'left',minHeight:"250px",position:"relative"}}>
            <img className="logo-secretaria" src={img}/>
            <div className="areasdetalle">
              <div>{direccion}</div>
              <div>{cp}</div>
              <div style={{paddingBottom:"20px"}}>{tel}</div>
              <a href={url} style={{color:"rgba(9,72,102,1)",paddingTop:"20px",fontWeight:'bold'}}>Ver más</a>
            </div>
            <div className="botones">
              <button className="fb" onClick={()=>window.open(facebook)}><i className="fab fa-facebook-square"></i></button>
              <button className="twitter" onClick={()=>window.open(twitter)}><i className="fab fa-twitter"></i></button>
              <button className="msg" onClick={()=>window.open("/Escribele")} ><i className="fas fa-comment"></i></button>
            </div>
        </div>
      );
    });
  };
}

export default Dependencias;